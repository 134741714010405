import axios, { AxiosRequestConfig } from 'axios';

export interface ApiService {
  get: <T>(url: string, config?: AxiosRequestConfig) => Promise<T>;
  post: <T>(url: string, body: any, config?: AxiosRequestConfig) => Promise<T>;
  patch: <T>(url: string, body: any, config?: AxiosRequestConfig) => Promise<T>;
  put: <T>(url: string, body: any, config?: AxiosRequestConfig) => Promise<T>;
  delete: <T>(url: string, config?: AxiosRequestConfig) => Promise<T>;
}

export const createBaseApi = (baseUrl: string) => {
  const baseApiUrl = `${baseUrl}`;

  const baseApi: ApiService = {
    get: async <T>(url: string, config?: AxiosRequestConfig): Promise<T> => {
      const authToken = localStorage.getItem('token');
      const headers: { Authorization?: string } = {};

      if (authToken) {
        headers.Authorization = `Bearer ${authToken}`;
      }

      return axios.get(`${baseApiUrl}${url}`, {
        ...config,
        headers,
      });
    },
    post: async <T>(
      url: string,
      body: any,
      config?: AxiosRequestConfig,
    ): Promise<T> => {
      const authToken = localStorage.getItem('token');
      const headers: { Authorization?: string } = {};

      if (authToken) {
        headers.Authorization = `Bearer ${authToken}`;
      }

      return axios.post(`${baseApiUrl}${url}`, body, {
        ...config,
        headers,
      });
    },
    patch: async <T>(
      url: string,
      body: any,
      config?: AxiosRequestConfig,
    ): Promise<T> => {
      const authToken = localStorage.getItem('token');
      const headers: { Authorization?: string } = {};

      if (authToken) {
        headers.Authorization = `Bearer ${authToken}`;
      }

      return axios.patch(`${baseApiUrl}${url}`, body, {
        ...config,
        headers,
      });
    },
    put: async <T>(
      url: string,
      body: any,
      config?: AxiosRequestConfig,
    ): Promise<T> => {
      const authToken = localStorage.getItem('token');
      const headers: { Authorization?: string } = {};

      if (authToken) {
        headers.Authorization = `Bearer ${authToken}`;
      }

      return axios.put(`${baseApiUrl}${url}`, body, {
        ...config,
        headers,
      });
    },
    delete: async <T>(url: string, config?: AxiosRequestConfig): Promise<T> => {
      const authToken = localStorage.getItem('token');
      const headers: { Authorization?: string } = {};

      if (authToken) {
        headers.Authorization = `Bearer ${authToken}`;
      }

      return axios.delete(`${baseApiUrl}${url}`, {
        ...config,
        headers,
      });
    },
  };

  return baseApi;
};
