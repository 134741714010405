import styled from '@emotion/styled';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { pxToRem } from 'casper-ui-kit';
import { ApiData, PromotionStatus } from 'src/api/types';
import { withSkeletonLoading } from 'src/components/loading';
import {
  CardWrapper,
  PromotionDataLabel,
  PromotionDataValue,
  PromotionHeading,
} from '../cards.styled';

export interface ParticipatingBusinessCardProps {
  readonly business: ApiData.BusinessUser;
  readonly isLoading: boolean;
}

export const ParticipatingBusinessCard: React.FC<
  ParticipatingBusinessCardProps
> = ({ business, isLoading }) => {
  const { t } = useTranslation();

  const { businessName, businessDescription, businessLogo, promotions, _id } =
    business;

  const livePromotions = (promotions as ApiData.BusinessPromotion[]).filter(
    promotion => promotion.status === PromotionStatus.Active,
  );

  const amountTotalLivePromotions = livePromotions.length;

  const amountOfUnclaimedRewards = livePromotions.reduce(
    (currentTotalUnclaimed, promotion) => {
      const { rewards, rewardQuantity } = promotion;
      const rewardsLeft = rewardQuantity - rewards.length;

      return currentTotalUnclaimed + rewardsLeft;
    },
    0,
  );

  return (
    <CardWrapper>
      {withSkeletonLoading(
        <PromotionTitle>
          <PromotionImage src={businessLogo} alt="promotion" />
          <PromotionHeading>{businessName}</PromotionHeading>
        </PromotionTitle>,
        isLoading,
        { height: '3rem', width: '60%' },
      )}

      <BusinessDescription>
        {withSkeletonLoading(businessDescription, isLoading, { width: '80%' })}
      </BusinessDescription>

      <PromotionDataWrapper>
        <div>
          <PromotionDataValue>
            {withSkeletonLoading(amountTotalLivePromotions, isLoading)}
          </PromotionDataValue>
          <PromotionDataLabel>
            {withSkeletonLoading(t('Number of Live Promotions'), isLoading)}
          </PromotionDataLabel>
        </div>
        <div>
          <PromotionDataValue>
            {withSkeletonLoading(amountOfUnclaimedRewards, isLoading)}
          </PromotionDataValue>
          <PromotionDataLabel>
            {withSkeletonLoading(
              t('Rewards Available to be Claimed'),
              isLoading,
            )}
          </PromotionDataLabel>
        </div>
      </PromotionDataWrapper>
    </CardWrapper>
  );
};

const BusinessDescription = styled.p`
  margin-bottom: 1rem;
  font-size: ${pxToRem(14)};
`;

const PromotionDataWrapper = styled.div`
  display: grid;
  grid-template-columns: ${pxToRem(90)} ${pxToRem(90)};
  column-gap: 2rem;
`;

const PromotionImage = styled.img`
  border-radius: 50%;
  height: ${pxToRem(40)};
  width: ${pxToRem(40)};
  margin-right: 1rem;
`;

const PromotionTitle = styled.div`
  display: flex;
`;
