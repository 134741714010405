import { RootState } from '../store';

export const getEndUser = (state: RootState) => {
  return state.endUser.endUser;
};

export const getParticipatingBusinessLoading = (state: RootState) => {
  return state.endUser.businessesFetchLoadingStatus;
};

export const getParticipatingBusinesses = (state: RootState) => {
  return state.endUser.participatingBusinesses;
};

export const getRedeemedEndUserRewards = (state: RootState) => {
  return state.endUser.redeemedEndUserReward;
};

export const getRedeemableEndUserRewards = (state: RootState) => {
  return state.endUser.redeemableEndUserReward;
};

export const getEndUserRedeemedRewardsLoadingStatus = (state: RootState) => {
  return state.endUser.redeemedRewardLoadingStatus;
};

export const getEndUserRedeemableRewardsLoadingStatus = (state: RootState) => {
  return state.endUser.redeemableRewardLoadingStatus;
};
