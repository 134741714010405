import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { MockPilotBusinesses } from 'src/mock-data/mock-pilot-businesses';

export interface OnboardingAuthenticationState {
  pilotBusinesses: MockPilotBusinesses[];
  landingPageForm: {
    businessInstagramHandle?: string;
    isPilotBusiness: boolean;
  };
  signUpFailureForm: {
    businessName: string;
    businessEmail: string;
  };
}

const initialState: OnboardingAuthenticationState = {
  pilotBusinesses: [],
  landingPageForm: {
    businessInstagramHandle: '',
    isPilotBusiness: false,
  },
  signUpFailureForm: {
    businessName: '',
    businessEmail: '',
  },
};

export const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    setBusinessLogin(state, action: PayloadAction<boolean>) {
      state.landingPageForm.isPilotBusiness = action.payload;
    },
    setBusinessName(state, action: PayloadAction<string>) {
      state.signUpFailureForm.businessName = action.payload;
    },
    setBusinessEmail(state, action: PayloadAction<string>) {
      state.signUpFailureForm.businessEmail = action.payload;
    },
  },
});

export const { setBusinessLogin, setBusinessName, setBusinessEmail } =
  onboardingSlice.actions;
