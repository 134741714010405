import React from 'react';
import { ApiData } from 'src/api/types';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { withSkeletonLoading } from 'src/components/loading';
import {
  PromotionDescription,
  PromotionHashtag,
} from 'src/components/cards/LivePromotionCard/LivePromotionCard';
import { CardHeading, CardWrapper } from './ParticipatingBusinessesCard';
import {
  CardData,
  CardDataWrapper,
  CardDescriptionWrapper,
  CardImage,
  CardImageWrapper,
  CardLabel,
  CardTopSectionWrapper,
  DataWrapper,
} from './cards.styled';

export interface LivePromotionsCardProps {
  readonly businessPromotion: ApiData.BusinessPromotion;
  readonly isLoading: boolean;
}

export const LivePromotionsCard: React.FC<LivePromotionsCardProps> = ({
  businessPromotion,
  isLoading,
}) => {
  const {
    promotionName,
    promotionCaption,
    promotionHashtag,
    promotionImage,
    rewardExpiry,
    rewardQuantity,
    rewards,
    pointsForEngagement,
    pointsForRedemption,
  } = businessPromotion;

  const { t } = useTranslation();

  const remainingQuantity = rewardQuantity - rewards.length;
  const promotionExpiry = format(new Date(rewardExpiry), 'dd-MM-yyyy');

  return (
    <CardWrapper width={455}>
      <CardHeading>{withSkeletonLoading(promotionName, isLoading)}</CardHeading>

      <CardTopSectionWrapper>
        <CardImageWrapper>
          {withSkeletonLoading(
            <CardImage src={promotionImage} alt="promotion" />,
            isLoading,
            { height: '10.3125rem', width: '10.3125rem' },
          )}
        </CardImageWrapper>
        <CardDescriptionWrapper>
          <PromotionDescription>
            {withSkeletonLoading(promotionCaption, isLoading, {
              height: '2rem',
            })}
          </PromotionDescription>
          <PromotionHashtag>
            <p>
              {withSkeletonLoading(t('Promotion hashtag:'), isLoading, {
                height: '1rem',
              })}
            </p>
            <p>{withSkeletonLoading(`#${promotionHashtag}`, isLoading)}</p>
          </PromotionHashtag>
        </CardDescriptionWrapper>
      </CardTopSectionWrapper>

      <CardDataWrapper>
        <DataWrapper>
          <CardData>{withSkeletonLoading(promotionExpiry, isLoading)}</CardData>
          <CardLabel>
            {withSkeletonLoading(t('Promotion Ends'), isLoading)}
          </CardLabel>
        </DataWrapper>
        <DataWrapper>
          <CardData>
            {withSkeletonLoading(remainingQuantity, isLoading)}
          </CardData>
          <CardLabel>
            {withSkeletonLoading(t('Remaining Quantity'), isLoading)}
          </CardLabel>
        </DataWrapper>
        <DataWrapper>
          <CardData>
            {withSkeletonLoading(pointsForEngagement, isLoading)}
          </CardData>
          <CardLabel>
            {withSkeletonLoading(t('Points per Engagement'), isLoading)}
          </CardLabel>
        </DataWrapper>
        <DataWrapper>
          <CardData>
            {withSkeletonLoading(pointsForRedemption, isLoading)}
          </CardData>
          <CardLabel>
            {withSkeletonLoading(t('Points Required'), isLoading)}
          </CardLabel>
        </DataWrapper>
      </CardDataWrapper>
    </CardWrapper>
  );
};
