import React, { useEffect, useMemo, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { LogoContainer, defaultTheme, pxToRem } from 'casper-ui-kit';
import { NAVBAR_BREAKPOINT } from 'src/constants';
import { NavItem } from 'src/components/types';
import { logoutJwtLocalStorage } from 'src/utils';
import styled from '@emotion/styled';
import { setFacebookAuthResponse, useAppDispatch } from 'src/store';
import { toastStore } from 'src/store/toast';
import { t } from 'i18next';
import { clRewardsTheme } from 'src/styled-theme';
import { NavbarItemLinkButton, NavButton } from '../../../buttons';
import {
  NavComponentsContainer,
  Nav,
  DesktopNav,
  DesktopNavItemsContainer,
  MobileNav,
  MobileNavItemsContainer,
  MobileNavItemLink,
  NavIcon,
  DesktopNavContainer,
  StyledAccountLogo,
  MobileNavActionItem,
} from './Navbar.styled';
import { LogoMenu } from '../../Logos/DefaultHeaderLogo';

interface NavbarProps {
  readonly isOpened: boolean;
  readonly openNav: () => void;
  readonly closeNav: () => void;
  readonly windowWidth: number;
  readonly navItems: NavItem[];
  readonly userType: 'end-user' | 'business-user';
}

const endUserMyAccountDropdownItems = [
  {
    title: `${t('Logout')}`,
    path: null,
    key: 'logout',
  },
];

const businessUserAccountDropdownItems = [
  {
    title: `${t('Logout')}`,
    path: null,
    key: 'logout',
  },
  {
    title: `${t('Edit Profile')}`,
    path: 'business-user/edit-profile',
    key: 'edit-profile',
  },
];

export const Navbar: React.FC<NavbarProps> = ({
  isOpened,
  navItems,
  openNav,
  closeNav,
  windowWidth,
  userType,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isMyAccountMenuOpen, setIsMyAccountMenuOpen] = useState(false);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const mobileNavMenuHandler = () => (isOpened ? closeNav() : openNav());

  useEffect(() => {
    if (windowWidth > NAVBAR_BREAKPOINT) {
      closeNav();
    }

    const escKeyHandler = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        event.preventDefault();
        if (isOpened) {
          closeNav();
        }
      }
    };

    document.addEventListener('keydown', escKeyHandler);

    return () => {
      document.removeEventListener('keydown', escKeyHandler);
    };
  }, [isOpened, windowWidth, closeNav]);

  const { pathname } = useLocation();

  const selectedRoute = useMemo(
    () => (pathname === '/' ? 'home' : pathname.slice(1)),
    [pathname],
  );

  const handleLogout = () => {
    setIsLoading(true);

    window.FB.logout(res => {
      if (res.status !== 'connected') {
        logoutJwtLocalStorage();

        dispatch(setFacebookAuthResponse(res));

        navigate(`/${userType}/login`);

        toastStore.setSuccessToast('Logged out successfully');
      } else {
        toastStore.setErrorToast('Could not logout via facebook.');
      }

      setIsLoading(false);
    });
  };

  return (
    <Nav data-testid="navigation">
      <NavComponentsContainer>
        <NavButton type="button" onClick={mobileNavMenuHandler}>
          <NavIcon
            icon={isOpened ? 'CloseMenuIcon' : 'OpenMenuIcon'}
            stroke={isOpened ? '#000' : '#fff'}
          />
        </NavButton>
        {isOpened && (
          <MobileNav>
            {/* TODO: */}
            <MobileNavItemsContainer>
              {navItems.map(({ path, title, key, action }) => {
                return (
                  <li data-cy="mobile-link" key={key}>
                    {path === null ? (
                      <MobileNavActionItem
                        onClick={() => {
                          if (key === 'my-account' || key === 'logout') {
                            handleLogout();
                          } else {
                            action?.();
                          }
                          closeNav();
                        }}
                        disabled={isLoading}>
                        {t(title)}
                      </MobileNavActionItem>
                    ) : (
                      <MobileNavItemLink
                        to={path}
                        onClick={() => {
                          closeNav();
                        }}>
                        {t(title)}
                      </MobileNavItemLink>
                    )}
                  </li>
                );
              })}
            </MobileNavItemsContainer>
          </MobileNav>
        )}
        <DesktopNavContainer>
          <DesktopNav data-cy="desktop-nav">
            <DesktopNavItemsContainer>
              {navItems.map(({ path, title, key, mobileOnly }) => {
                if (mobileOnly) {
                  return null;
                }

                if (key === 'my-account') {
                  return (
                    <NavbarItemLinkButton
                      key={key}
                      isRouteSelected={selectedRoute === 'account'}>
                      <LogoContainer>
                        <LogoMenu
                          aria-label="my account"
                          onClick={() => setIsMyAccountMenuOpen(prev => !prev)}>
                          <StyledAccountLogo />
                          <MyAccountDropdown isMenuOpen={isMyAccountMenuOpen}>
                            {(userType === 'end-user'
                              ? endUserMyAccountDropdownItems
                              : businessUserAccountDropdownItems
                            ).map(({ path, key, title }) => {
                              return (
                                <MyAccountDropdownMenuItem
                                  key={key}
                                  onClick={
                                    !path ? handleLogout : () => navigate(path)
                                  }>
                                  {t(title)}
                                </MyAccountDropdownMenuItem>
                              );
                            })}
                          </MyAccountDropdown>
                        </LogoMenu>
                      </LogoContainer>
                    </NavbarItemLinkButton>
                  );
                }

                return (
                  <Link key={key} to={path ?? ''}>
                    <NavbarItemLinkButton isRouteSelected={path === pathname}>
                      {t(title)}
                    </NavbarItemLinkButton>
                  </Link>
                );
              })}
            </DesktopNavItemsContainer>
          </DesktopNav>
        </DesktopNavContainer>
      </NavComponentsContainer>
    </Nav>
  );
};

const MyAccountDropdown = styled.ul<{ isMenuOpen: boolean }>`
  position: absolute;
  display: ${({ isMenuOpen }) => (isMenuOpen ? 'block' : 'none')};
  top: 2.5rem;
  right: 0;
`;

const MyAccountDropdownMenuItem = styled.li`
  font-size: ${pxToRem(16)};
  font-weight: ${defaultTheme.typography.fontWeights.medium};
  text-align: left;
  background-color: #fff;
  padding: 0.5rem 0.75rem;
  color: ${clRewardsTheme.textColor.black};

  :hover {
    color: ${clRewardsTheme.textColor.userSelectionAlert};
  }
`;
