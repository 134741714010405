import {
  ApiData,
  CreateBusinessPromotionRequest,
  PromotionStatus,
} from 'src/api/types';
import {
  Draft,
  PayloadAction,
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit';
import { middlewareServiceApi } from 'src/api';
import { AxiosError } from 'axios';
import {
  CreateBusinessPromotion1Form,
  CreateBusinessPromotion2Form,
  CreateBusinessPromotion3Form,
} from 'src/components/create-promotion/steps/create-promotion-form-types';
import { Loading } from '../loading.type';

export interface BusinessPromotionState {
  status: Loading;
  error: AxiosError | null;
  businessPromotions: ApiData.BusinessPromotion[] | null;
  businessPromotion: ApiData.BusinessPromotion | null;
  businessPromotionUnguarded: ApiData.BusinessPromotionUnguarded | null;
  fetchMyPromotionsForBusinessDashboardLoadingStatus: Loading;
  fetchPromotionToManageLoadingStatus: Loading;
  updatePromotionLoadingStatus: Loading;
  myPromotionsForBusinessDashboard: ApiData.BusinessPromotion[] | null;
  promotionToManage: ApiData.BusinessPromotion | null;
  promotionStatus: PromotionStatus | null;
  updatedPromotionStatus: PromotionStatus | null;
  createBusinessPromotionFormData: {
    page1FormData?: CreateBusinessPromotion1Form;
    page2FormData?: CreateBusinessPromotion2Form;
    page3FormData?: CreateBusinessPromotion3Form;
  };
}

const initialState: BusinessPromotionState = {
  status: Loading.Idle,
  error: null,
  businessPromotions: [],
  businessPromotion: null,
  businessPromotionUnguarded: null,
  fetchMyPromotionsForBusinessDashboardLoadingStatus: Loading.Idle,
  fetchPromotionToManageLoadingStatus: Loading.Idle,
  updatePromotionLoadingStatus: Loading.Idle,
  myPromotionsForBusinessDashboard: null,
  promotionToManage: null,
  promotionStatus: null,
  updatedPromotionStatus: null,
  createBusinessPromotionFormData: {},
};

export const createBusinessPromotion = createAsyncThunk(
  'promotion/createBusinessPromotion',
  async (args: CreateBusinessPromotionRequest, { rejectWithValue }) => {
    try {
      const promotion =
        await middlewareServiceApi.businessPromotion.createBusinessPromotion(
          args,
        );

      return promotion;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const fetchBusinessPromotions = createAsyncThunk(
  'businessPromotion/fetchBusinessPromotions',
  async (
    {
      search,
      filters,
      active,
    }: {
      search?: { promotionName?: string };
      filters?: { myPromotions?: boolean };
      active?: boolean;
    },
    { rejectWithValue },
  ) => {
    try {
      const businessPromotions =
        await middlewareServiceApi.businessPromotion.fetchBusinessPromotions(
          search,
          filters,
          active,
        );

      return businessPromotions;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const fetchBusinessPromotion = createAsyncThunk(
  'businessPromotion/fetchBusinessPromotion',
  async (id: string, { rejectWithValue }) => {
    try {
      const businessPromotion =
        await middlewareServiceApi.businessPromotion.fetchBusinessPromotion(id);

      return businessPromotion;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const fetchBusinessPromotionByEncryptedId = createAsyncThunk(
  'businessPromotion/fetchBusinessPromotionByEncryptedId',
  async (id: string, { rejectWithValue }) => {
    try {
      const businessPromotion =
        await middlewareServiceApi.businessPromotion.fetchBusinessPromotionByEncryptedId(
          id,
        );

      return businessPromotion;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const fetchBusinessPromotionUnguarded = createAsyncThunk(
  'businessPromotion/fetchBusinessPromotionUnguarded',
  async (id: string, { rejectWithValue }) => {
    try {
      const businessPromotion =
        await middlewareServiceApi.businessPromotion.fetchBusinessPromotionUnguarded(
          id,
        );

      return businessPromotion;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const fetchMyPromotionsForBusinessDashboard = createAsyncThunk(
  'business-promotion',
  async (_, { rejectWithValue }) => {
    try {
      const myPromotions =
        await middlewareServiceApi.businessPromotion.fetchMyPromotionsForBusinessDashboard();
      return myPromotions;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const fetchPromotionToManage = createAsyncThunk(
  'business-promotion/fetchPromotionToManage',
  async (promotionId: string, { rejectWithValue }) => {
    try {
      const promotionToManage =
        await middlewareServiceApi.businessPromotion.fetchPromotionToManage(
          promotionId,
        );

      return promotionToManage;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const updatePromotionStatus = createAsyncThunk(
  'business-promotion/patchPromotionStatus',
  async (
    args: { promotionId: string; updatedStatus: PromotionStatus },
    { rejectWithValue },
  ) => {
    try {
      const updatedStatus =
        await middlewareServiceApi.businessPromotion.updatePromotionStatus(
          args,
        );

      return updatedStatus;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const businessPromotionSlice = createSlice({
  name: 'businessPromotion',
  initialState,
  reducers: {
    clearBusinessPromotions: state => {
      state.businessPromotions = null;
    },
    resetBusinessPromotionsLoadingStatus: state => {
      state.status = Loading.Idle;
    },
    setCreateBusinessPromotionFormData: <
      TCreatePromotionFormData extends BusinessPromotionState['createBusinessPromotionFormData'],
    >(
      state: Draft<BusinessPromotionState>,
      action: PayloadAction<TCreatePromotionFormData>,
    ) => {
      state.createBusinessPromotionFormData = {
        ...state.createBusinessPromotionFormData,
        ...action.payload,
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchBusinessPromotions.pending, state => {
        state.status = Loading.Pending;
      })
      .addCase(fetchBusinessPromotions.fulfilled, (state, { payload }) => {
        state.status = Loading.Complete;
        state.businessPromotions = payload;
      })
      .addCase(fetchBusinessPromotions.rejected, (state, { payload }) => {
        state.error = payload as AxiosError;
        state.status = Loading.Failed;
      })
      .addCase(fetchBusinessPromotion.pending, state => {
        state.status = Loading.Pending;
      })
      .addCase(fetchBusinessPromotion.fulfilled, (state, { payload }) => {
        state.status = Loading.Complete;
        state.businessPromotion = payload;
      })
      .addCase(fetchBusinessPromotion.rejected, (state, { payload }) => {
        state.error = payload as AxiosError;
        state.status = Loading.Failed;
      })
      .addCase(fetchBusinessPromotionUnguarded.pending, state => {
        state.status = Loading.Pending;
      })
      .addCase(
        fetchBusinessPromotionUnguarded.fulfilled,
        (state, { payload }) => {
          state.status = Loading.Complete;
          state.businessPromotionUnguarded = payload;
        },
      )
      .addCase(fetchBusinessPromotionUnguarded.rejected, state => {
        state.status = Loading.Failed;
      })
      .addCase(fetchBusinessPromotionByEncryptedId.pending, state => {
        state.status = Loading.Pending;
      })
      .addCase(
        fetchBusinessPromotionByEncryptedId.fulfilled,
        (state, { payload }) => {
          state.status = Loading.Complete;
          state.businessPromotion = payload;
        },
      )
      .addCase(fetchBusinessPromotionByEncryptedId.rejected, state => {
        state.status = Loading.Failed;
      })
      .addCase(fetchMyPromotionsForBusinessDashboard.pending, state => {
        state.status = Loading.Pending;
      })
      .addCase(
        fetchMyPromotionsForBusinessDashboard.fulfilled,
        (state, { payload }) => {
          state.status = Loading.Complete;
          state.myPromotionsForBusinessDashboard = payload;
        },
      )
      .addCase(
        fetchMyPromotionsForBusinessDashboard.rejected,
        (state, { payload }) => {
          state.error = payload as AxiosError;
          state.status = Loading.Failed;
        },
      )
      .addCase(fetchPromotionToManage.pending, state => {
        state.fetchPromotionToManageLoadingStatus = Loading.Pending;
      })
      .addCase(fetchPromotionToManage.fulfilled, (state, { payload }) => {
        state.fetchPromotionToManageLoadingStatus = Loading.Complete;
        state.promotionToManage = payload;
        state.promotionStatus = state.promotionToManage.status;
      })
      .addCase(fetchPromotionToManage.rejected, (state, { payload }) => {
        state.error = payload as AxiosError;
        state.fetchMyPromotionsForBusinessDashboardLoadingStatus =
          Loading.Failed;
      })
      .addCase(updatePromotionStatus.pending, state => {
        state.updatePromotionLoadingStatus = Loading.Pending;
      })
      .addCase(updatePromotionStatus.fulfilled, (state, { payload }) => {
        state.updatePromotionLoadingStatus = Loading.Complete;
        state.updatedPromotionStatus = payload;
        state.promotionStatus = state.updatedPromotionStatus;
      })
      .addCase(updatePromotionStatus.rejected, (state, { payload }) => {
        state.error = payload as AxiosError;
        state.updatePromotionLoadingStatus = Loading.Failed;
      })
      .addCase(createBusinessPromotion.pending, state => {
        state.status = Loading.Pending;
      })
      .addCase(createBusinessPromotion.fulfilled, (state, { payload }) => {
        state.status = Loading.Complete;
        state.businessPromotion = payload;
      })
      .addCase(createBusinessPromotion.rejected, (state, { payload }) => {
        state.error = payload as AxiosError;
        state.status = Loading.Failed;
      });
  },
});

export const {
  clearBusinessPromotions,
  resetBusinessPromotionsLoadingStatus,
  setCreateBusinessPromotionFormData,
} = businessPromotionSlice.actions;
