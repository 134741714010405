import styled from '@emotion/styled';
import { pxToRem } from 'casper-ui-kit';

export const PromotionHeading = styled.h1`
  font-size: ${pxToRem(27)};
  font-weight: 400;
  margin-bottom: 1rem;
`;

export const PromotionDataValue = styled.p`
  font-weight: 500;
  font-size: ${pxToRem(18)};
`;

export const PromotionDataLabel = styled.p`
  font-size: ${pxToRem(12)};
`;

export const CardWrapper = styled.div`
  padding: 1rem 1rem 1.5rem;
  border: ${pxToRem(2)} solid ${props => props.theme.inputs.border};
  border-radius: ${pxToRem(5)};
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-bottom: 2rem;
`;

export const ImageWrapper = styled.div`
  width: ${pxToRem(121)};
`;
