import React from 'react';
import styled from '@emotion/styled';
import { defaultTheme, pxToRem } from 'casper-ui-kit';
import { t } from 'i18next';
import { PageWrapper } from '../../components/layout/PageWrapper';

export const RedeemCustomerReward: React.FC = () => {
  return (
    <PageWrapper>
      <RedeemCustomerRewardWrapper>
        <Qr1Heading>{t('Redeem Customer Reward')}</Qr1Heading>
        <Qr1SubHeading>
          {t(
            // eslint-disable-next-line quotes
            "To redeem the customer's reward, scan their QR code using your device's camera.",
          )}
        </Qr1SubHeading>
        <ScanQrButton htmlFor="camera" data-testid="scan-qr-button">
          {t('Scan QR code')}
        </ScanQrButton>
        <QrCodeInput
          type="file"
          id="camera"
          accept="image/*"
          capture="environment"
        />
      </RedeemCustomerRewardWrapper>
    </PageWrapper>
  );
};

const RedeemCustomerRewardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: ${defaultTheme.breakpoints.xxs}) {
    align-items: center;
  }
`;

const Qr1Heading = styled.h1`
  font-size: ${pxToRem(28)};
  font-weight: 400;
  line-height: 1.35;
`;

const Qr1SubHeading = styled.h3`
  font-weight: 400;
  font-size: 1rem;
  margin: ${pxToRem(25)} 0 ${pxToRem(40)} 0;
`;

const ScanQrButton = styled.label`
  color: ${props => props.theme.inputs.textColor};
  font-size: ${pxToRem(18)};
  font-weight: 500;
  padding: ${pxToRem(11)} ${pxToRem(16)};
  text-align: center;
  cursor: pointer;
  background-color: ${props => props.theme.buttons.bgColor.casperGreen};
`;

const QrCodeInput = styled.input`
  display: none;
`;
