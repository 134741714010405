import { AxiosResponse } from 'axios';
import { ApiService } from 'src/api/base-api';

import { ApiData } from 'src/api/types';

export class EndUserPromotionEngagementRoutes {
  private readonly prefix: string = '/end-user-promotion-engagement';

  constructor(private readonly api: ApiService) {}

  async createEndUserPromotionEngagement(body: {
    businessPromotionId: string;
    promotionPostId: string;
    engagementType: string;
    pointsCollected: number;
  }) {
    type Response = AxiosResponse<ApiData.EndUserPromotionEngagement>;

    const response = await this.api.post<Response>(`${this.prefix}`, body);

    if (response.status !== 201) throw new Error(response.statusText);

    const { data } = response;

    return data;
  }
}
