/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import { ConfigError } from './config-error';

export interface AppConfig {
  isProduction: boolean;
  qrCodeLink: string;
  baseApiUrl: string;
  fbAppIdBusinessUser: string;
  fbAppIdEndUser: string;
  fbAppBusinessUserConfigId: string;
  fbGraphApiVersion: string;
  googAnalyticsId: string;
}

/* eslint-disable prefer-destructuring */
const ENV = (window as any)?.ENV || {};
Object.keys(ENV).forEach(key => {
  ENV[key] = ENV[key].indexOf('<!--') !== -1 ? null : ENV[key];
});

export const loadConfig: () => AppConfig = () => {
  const {
    NODE_ENV,
    REACT_APP_QR_CODE_LINK: reactAppQrCodeLink,
    REACT_APP_BASE_API_URL: reactAppBaseApiUrl,
    REACT_APP_FB_APP_ID_BUSINESS_USER: fbAppIdBusinessUser,
    REACT_APP_FB_APP_ID_END_USER: fbAppIdEndUser,
    REACT_APP_FB_APP_BUSINESS_USER_CONFIG_ID: fbAppBusinessUserConfigId,
    REACT_APP_FB_GRAPH_API_VERSION: reactFbGraphApiVersion,
    REACT_APP_GOOG_ANALYTICS_ID: reactGoogAnalyticsId,
  } = process.env;

  const isProduction = NODE_ENV === 'production';

  if (!fbAppIdBusinessUser || !fbAppIdEndUser || !fbAppBusinessUserConfigId) {
    throw new ConfigError('Missing fb app/config settings');
  }

  if (!reactAppQrCodeLink) {
    throw new ConfigError('Missing QR Code Link');
  }

  if (!reactAppBaseApiUrl) {
    throw new ConfigError('Missing Base API Url');
  }

  if (!reactFbGraphApiVersion) {
    throw new ConfigError('Missing FB Graph API Version');
  }

  if (!reactGoogAnalyticsId) {
    throw new ConfigError('Missing Google Analytics ID');
  }

  const qrCodeLink = reactAppQrCodeLink;

  const baseApiUrl = reactAppBaseApiUrl;

  const fbGraphApiVersion = reactFbGraphApiVersion;

  const googAnalyticsId = reactGoogAnalyticsId;

  return {
    isProduction,
    qrCodeLink,
    baseApiUrl,
    fbAppIdBusinessUser,
    fbAppIdEndUser,
    fbAppBusinessUserConfigId,
    fbGraphApiVersion,
    googAnalyticsId,
  };
};
