import React, { useEffect, useMemo } from 'react';
import { Button, HeadingType, defaultTheme, pxToRem } from 'casper-ui-kit';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import { PageWrapper } from 'src/components/layout/PageWrapper';
import {
  Loading,
  fetchBusinessPromotionByEncryptedId,
  fetchEndUserPromotionRewardByEncryptedId,
  getBusinessPromotion,
  getBusinessPromotionLoadingStatus,
  getEndUserPromotionReward,
  getEndUserPromotionRewardLoadingStatus,
  getEndUserPromotionRewardRedeemLoadingStatus,
  redeemEndUserPromotionReward,
  resetEndUserPromotionRewardRedeemLoadingStatus,
  useAppDispatch,
  useAppSelector,
} from 'src/store';
import { withSkeletonLoading } from 'src/components/loading';
import { clRewardsTheme } from 'src/styled-theme';
import { ApiData } from 'src/api/types';
import { ButtonWithLoading } from 'src/components/buttons/ButtonWithLoading';
import { format } from 'date-fns';
import { parseSearchParams } from 'src/utils';
import { MobilePageTitle } from './end-user/SharedStyles';
import { PageError } from './PageError';

export const RewardDetails: React.FC = () => {
  const { t } = useTranslation();

  const businessPromotion = useAppSelector(getBusinessPromotion);
  const endUserPromotionReward = useAppSelector(getEndUserPromotionReward);

  const businessPromotionLoadingStatus = useAppSelector(
    getBusinessPromotionLoadingStatus,
  );
  const endUserPromotionRewardLoadingStatus = useAppSelector(
    getEndUserPromotionRewardLoadingStatus,
  );
  const endUserPromotionRewardRedeemLoadingStatus = useAppSelector(
    getEndUserPromotionRewardRedeemLoadingStatus,
  );

  const dispatch = useAppDispatch();
  const { search: searchParamsString } = useLocation();
  const navigate = useNavigate();

  const promotionId = useMemo(() => {
    const searchParams = new URLSearchParams(searchParamsString);

    return parseSearchParams(searchParams.get('promotionId'));
  }, [searchParamsString]);

  const rewardId = useMemo(() => {
    const searchParams = new URLSearchParams(searchParamsString);

    return parseSearchParams(searchParams.get('rewardId'));
  }, [searchParamsString]);

  useEffect(() => {
    if (promotionId && !businessPromotion) {
      dispatch(fetchBusinessPromotionByEncryptedId(promotionId));
    }
  }, [dispatch, promotionId, businessPromotion]);

  useEffect(() => {
    if (rewardId && !endUserPromotionReward) {
      dispatch(fetchEndUserPromotionRewardByEncryptedId(rewardId));
    }
  }, [dispatch, rewardId, endUserPromotionReward]);

  useEffect(() => {
    return () => {
      dispatch(resetEndUserPromotionRewardRedeemLoadingStatus());
    };
  }, [dispatch]);

  const onRedeemReward = async () => {
    if (endUserPromotionReward) {
      const { meta } = await dispatch(
        redeemEndUserPromotionReward(endUserPromotionReward._id),
      );

      if (meta.requestStatus === 'fulfilled') {
        navigate('/business-user/reward-details-success');
      } else {
        navigate('/business-user/page-error', {
          state: { displayNavigateBack: true },
        });
      }
    } else {
      throw new Error('No access to end user promotion reward.');
    }
  };

  const isBusinessPromotionLoading =
    businessPromotionLoadingStatus !== Loading.Complete;
  const isEndUserPromotionRewardLoading =
    endUserPromotionRewardLoadingStatus !== Loading.Complete;

  if (
    (!businessPromotion || !endUserPromotionReward) &&
    !isBusinessPromotionLoading &&
    !isEndUserPromotionRewardLoading
  ) {
    return <PageError displayNavigateBack />;
  }

  const remainingQuantity =
    (businessPromotion?.rewardQuantity ?? 0) -
    (businessPromotion?.rewards.length ?? 0);

  const isEndUserPromotionRewardRedeeming =
    endUserPromotionRewardRedeemLoadingStatus === Loading.Pending ||
    endUserPromotionRewardRedeemLoadingStatus === Loading.Failed;

  return (
    <PageWrapper>
      <MobilePageTitle type={HeadingType.H2}>
        {t('Reward Details')}
      </MobilePageTitle>

      <PromotionDetailsCard>
        <p>
          {withSkeletonLoading(
            (businessPromotion?.businessUserId as ApiData.BusinessUser)
              ?.businessName,
            isBusinessPromotionLoading,
            { height: '1.25rem', width: '50%' },
          )}
        </p>
        <p>
          {withSkeletonLoading(
            businessPromotion?.promotionName,
            isBusinessPromotionLoading,
            {
              height: '1.25rem',
              width: '60%',
            },
          )}
        </p>
        <p>
          {withSkeletonLoading(
            endUserPromotionReward?.createdAt
              ? `${t('Claimed ')}${format(
                  new Date(endUserPromotionReward?.createdAt),
                  'MM/dd/yyyy',
                )}`
              : '',
            isEndUserPromotionRewardLoading,
            { height: '1.25rem', width: '50%' },
          )}
        </p>
        <p>
          {withSkeletonLoading(
            businessPromotion?.rewardExpiry
              ? `${t('Expires ')}
          ${format(new Date(businessPromotion?.rewardExpiry), 'MM/dd/yyyy')}`
              : '',
            isBusinessPromotionLoading,
            { height: '1.25rem', width: '60%' },
          )}
        </p>
        <p>
          {withSkeletonLoading(
            <>
              {remainingQuantity}
              {` reward${remainingQuantity === 1 ? '' : 's'} remaining`}
            </>,
            isBusinessPromotionLoading,
            { height: '1.25rem', width: '50%' },
          )}
        </p>
      </PromotionDetailsCard>

      <ButtonContainer>
        <RedeemRewardButton
          type="button"
          onClick={onRedeemReward}
          isLoading={isEndUserPromotionRewardRedeeming}
          disabled={isEndUserPromotionRewardRedeeming}>
          {t('Redeem reward')}
        </RedeemRewardButton>
        <GoBackButton
          bgColor={clRewardsTheme.buttons.bgColor.white}
          type="button">
          {t('Go back')}
        </GoBackButton>
      </ButtonContainer>
    </PageWrapper>
  );
};

const ButtonContainer = styled.div`
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media only screen and (min-width: ${defaultTheme.breakpoints.md}) {
    max-width: ${pxToRem(300)};
  }
`;

const PromotionDetailsCard = styled.div`
  padding-top: 1rem;

  * {
    margin-bottom: 0.2rem;
  }
`;

const GoBackButton = styled(Button)`
  color: ${() => clRewardsTheme.buttons.textColor.black};
  font-weight: ${defaultTheme.typography.fontWeights.medium};
  font-size: ${pxToRem(18)} !important;
  width: 100%;
  height: ${pxToRem(50)};
`;

const RedeemRewardButton = styled(ButtonWithLoading)`
  text-align: center;
  color: ${() => clRewardsTheme.buttons.textColor.black};
  background-color: ${() => clRewardsTheme.buttons.bgColor.casperGreen};
  width: 100%;
  height: ${pxToRem(50)};
  font-size: ${pxToRem(18)};
  font-weight: ${defaultTheme.typography.fontWeights.medium};
`;
