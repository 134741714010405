import styled from '@emotion/styled';
import { pxToRem } from 'casper-ui-kit';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getSkeletonLoadingData } from 'src/components/loading';
import { skeletonData } from 'src/mock-data/skeleton-loading';
import {
  Loading,
  clearEndUserRedeemableRewards,
  fetchRedeemableEndUserReward,
  resetEndUserRedeemableRewardsLoadingStatus,
  useAppDispatch,
  useAppSelector,
} from 'src/store';
import {
  getEndUserRedeemableRewardsLoadingStatus,
  getRedeemableEndUserRewards,
} from 'src/store/selectors/end-user-selectors';
import { CardContainer } from '../../cards/cards.styled';
import { CardContentsWrapper } from './ParticipatingBusinessesTab';
import { RedeemableRewardCard } from './RedeemableRewardCard';

export const RedeemableRewardCards: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const endUserRedeemableRewards = useAppSelector(getRedeemableEndUserRewards);
  const endUserRedeemableRewardLoadingStatus = useAppSelector(
    getEndUserRedeemableRewardsLoadingStatus,
  );

  useEffect(() => {
    dispatch(fetchRedeemableEndUserReward());

    return () => {
      dispatch(clearEndUserRedeemableRewards());
      dispatch(resetEndUserRedeemableRewardsLoadingStatus());
    };
  }, [dispatch]);

  const isEndUserRedeemableRewardsLoading =
    endUserRedeemableRewardLoadingStatus !== Loading.Complete;

  const getReedemableRewardCardsWithSkeletonLoading = () => {
    if (
      !endUserRedeemableRewards?.length &&
      endUserRedeemableRewardLoadingStatus === Loading.Complete
    ) {
      return (
        <CardContainer>
          <NoResultsText>{t('No redeemable rewards.')}</NoResultsText>
        </CardContainer>
      );
    }

    return (
      endUserRedeemableRewards ??
      getSkeletonLoadingData(skeletonData.endUserPromotionReward)
    ).map(endUserReward => {
      const {
        _id: uniqueIdHash,
        businessPromotionId,
        createdAt,
      } = endUserReward;

      if (typeof businessPromotionId === 'string' || !businessPromotionId) {
        return null;
      }

      return (
        <RedeemableRewardCard
          key={uniqueIdHash}
          rewardQuantity={businessPromotionId.rewardQuantity}
          promotionName={businessPromotionId.promotionName}
          promotionImage={businessPromotionId.promotionImage}
          promotionCaption={businessPromotionId.promotionCaption}
          promotionHashtag={businessPromotionId.promotionHashtag}
          rewardClaimDate={createdAt}
          rewardExpiry={businessPromotionId.rewardExpiry}
          isLoading={isEndUserRedeemableRewardsLoading}
          businessPromotionId={businessPromotionId._id}
          endUserPromotionRewardId={uniqueIdHash}
        />
      );
    });
  };

  return (
    <CardContentsWrapper>
      {getReedemableRewardCardsWithSkeletonLoading()}
    </CardContentsWrapper>
  );
};

const NoResultsText = styled.p`
  font-size: ${pxToRem(18)};
`;
