import styled from '@emotion/styled';
import { defaultTheme, pxToRem } from 'casper-ui-kit';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { PageWrapper } from 'src/components/layout/PageWrapper';
import {
  Loading,
  authenticateEndUser,
  createEndUserPromotionEngagement,
  fetchBusinessPromotionUnguarded,
  getBusinessPromotionLoadingStatus,
  getBusinessPromotionUnguarded,
  getEndUserPromotionEngagementLoadingStatus,
  setFacebookAuthResponse,
  useAppDispatch,
  useAppSelector,
} from 'src/store';
import { getFacebookAuthInfo } from 'src/store/selectors/auth-selectors';
import { clRewardsTheme } from 'src/styled-theme';
import { DefaultHeaderLogo } from 'src/components/layout';
import { withSkeletonLoading } from 'src/components/loading';
import { toastStore } from 'src/store/toast';
import { ButtonWithLoading } from 'src/components/buttons/ButtonWithLoading';
import { ExtendedLoginOptions } from 'src/types/facebook';
import { getEndUser } from 'src/store/selectors/end-user-selectors';
import { Loader, PoliciesText } from 'src/components';

interface EndUserLoginPageProps {}

const logo = <DefaultHeaderLogo aria-label="home" linkTo="#" />;

export const EndUserLoginPage: React.FC<EndUserLoginPageProps> = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { search: searchParamsString } = useLocation();
  const [isLoginLoading, setIsLoadingLoading] = useState(false);

  const businessPromotionUnguarded = useAppSelector(
    getBusinessPromotionUnguarded,
  );
  const businessPromotionLoadingStatus = useAppSelector(
    getBusinessPromotionLoadingStatus,
  );
  const endUser = useAppSelector(getEndUser);
  const createEndUserPromotionEngagementLoadingStatus = useAppSelector(
    getEndUserPromotionEngagementLoadingStatus,
  );

  const facebookAuthInfo = useAppSelector(getFacebookAuthInfo);

  const [promotionId, promotionPostId] = useMemo(() => {
    const searchParams = new URLSearchParams(searchParamsString);

    const paramPromotionId = searchParams.get('promotionId');
    const paramPromotionPostId = searchParams.get('promotionPostId');

    return [paramPromotionId, paramPromotionPostId];
  }, [searchParamsString]);

  const rewardsUrlString = useMemo(
    () => `rewards?promotionId=${businessPromotionUnguarded?._id ?? ''}`,
    [businessPromotionUnguarded?._id],
  );

  useEffect(() => {
    if (promotionId) {
      dispatch(fetchBusinessPromotionUnguarded(promotionId));
    }
  }, [dispatch, searchParamsString, promotionId]);

  const { t } = useTranslation();

  const handleCreatingEndUserPromotionEngagement = async () => {
    if (businessPromotionUnguarded?._id && promotionPostId) {
      const ok = await dispatch(
        createEndUserPromotionEngagement({
          promotionPostId,
          businessPromotionId: businessPromotionUnguarded?._id,
          engagementType: 'engagement',
          pointsCollected: businessPromotionUnguarded?.pointsForEngagement ?? 0,
        }),
      );

      toastStore.processActionMetaWithToast(ok.meta.requestStatus, {
        success: 'Claimed points successfully',
        error: 'Error claiming points.',
      });

      if (
        ok.type.includes('rejected') ||
        (ok.meta as { rejectedWithValue?: boolean })?.rejectedWithValue
      ) {
        throw new Error(
          'Cannot claim points multiple times for the same reward.',
        );
      }
    } else {
      throw new Error('business promotion id not available.');
    }
  };

  const logout = async () => {
    return new Promise((resolve, reject) => {
      window.FB.logout(res => {
        if (res.status !== 'connected') {
          dispatch(setFacebookAuthResponse(res));
          resolve('Successfully logged out from facebook.');
        } else {
          reject(new Error('Could not logout from facebook.'));
        }
      });
    });
  };

  const handleLogin = async (dashboardNav?: boolean) => {
    setIsLoadingLoading(true);

    window.FB.login(
      res => {
        const facebookAuthResponse = res;

        dispatch(setFacebookAuthResponse(facebookAuthResponse));

        if (res.status === 'connected') {
          const { accessToken, userID: facebookId } =
            facebookAuthResponse.authResponse;

          (async () => {
            const { meta } = await dispatch(
              authenticateEndUser({
                facebookId,
                accessToken,
              }),
            );

            toastStore.processActionMetaWithToast(meta.requestStatus, {
              success: 'Logged in successfully',
              error: 'Error logging in.',
            });

            if (meta.requestStatus === 'rejected') {
              await logout();

              setIsLoadingLoading(false);

              return navigate('/end-user/page-error', {
                state: { unauthorizedError: true },
              });
            }

            if (!dashboardNav) {
              await handleCreatingEndUserPromotionEngagement();
            }

            navigate(
              `/end-user/${dashboardNav ? 'dashboard' : rewardsUrlString}`,
            );
          })();
        } else {
          navigate('/end-user/page-error', {
            state: { unauthorizedError: true },
          });
        }

        setIsLoadingLoading(false);
      },
      /* eslint-disable @typescript-eslint/no-unsafe-argument */
      { scope: 'public_profile,email' } as fb.LoginOptions &
        ExtendedLoginOptions,
    );
  };

  const handleClaimPoints = async () => {
    await handleCreatingEndUserPromotionEngagement();

    navigate(`/end-user/${rewardsUrlString}`);
  };

  const isBusinessPromotionUnguardedLoading =
    businessPromotionLoadingStatus !== Loading.Complete;

  const isLoggedIn = facebookAuthInfo?.status === 'connected' && endUser;

  const getClaimPointsLogin = () => {
    if (!isLoggedIn) {
      return (
        <FacebookButton
          onClick={() => handleLogin()}
          disabled={isLoginLoading}
          type="button"
          isLoading={isLoginLoading}>
          {t('Login with Facebook')}
        </FacebookButton>
      );
    }

    return (
      <StyledClaimPointsButton
        onClick={handleClaimPoints}
        disabled={isLoginLoading}
        type="button"
        isLoading={isLoginLoading}>
        {t('Claim Points')}
      </StyledClaimPointsButton>
    );
  };

  if (createEndUserPromotionEngagementLoadingStatus === Loading.Pending) {
    return (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    );
  }

  return (
    <PageWrapper>
      {promotionId ? (
        <ClaimPointsWrapper>
          <ClaimPoints>
            <ClaimPointsHeading>{t('Claim Points')}</ClaimPointsHeading>
            <ClaimPointsCard>
              <PromotionDetailsWrapper>
                <PromotionImageWrapper>
                  {withSkeletonLoading(
                    <PromotionImage
                      src={businessPromotionUnguarded?.promotionImage}
                    />,
                    isBusinessPromotionUnguardedLoading,
                    { height: '5rem' },
                  )}
                </PromotionImageWrapper>
                <PromotionTextWrapper>
                  <PromotionTitle>
                    {withSkeletonLoading(
                      businessPromotionUnguarded?.promotionName,
                      isBusinessPromotionUnguardedLoading,
                      { height: '2rem' },
                    )}
                  </PromotionTitle>
                  <PromotionBusiness>
                    {withSkeletonLoading(
                      businessPromotionUnguarded?.businessUserName,
                      isBusinessPromotionUnguardedLoading,
                    )}
                  </PromotionBusiness>
                </PromotionTextWrapper>
              </PromotionDetailsWrapper>

              <ClaimPointsButtonWrapper>
                {getClaimPointsLogin()}
              </ClaimPointsButtonWrapper>
            </ClaimPointsCard>
          </ClaimPoints>
        </ClaimPointsWrapper>
      ) : (
        <GenericLoginWrapper>
          <LogoWrapper>{logo}</LogoWrapper>
          <FacebookButton
            type="button"
            onClick={() => handleLogin(true)}
            disabled={isLoginLoading}
            isLoading={isLoginLoading}>
            Continue with Facebook
          </FacebookButton>
          <PoliciesText />
        </GenericLoginWrapper>
      )}
    </PageWrapper>
  );
};

const ClaimPointsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const ClaimPoints = styled.div`
  width: 100%;
  max-width: ${defaultTheme.breakpoints.md};
`;

const ClaimPointsHeading = styled.h2`
  font-size: ${pxToRem(28)};
  font-weight: 400;
  margin-bottom: 1rem;
`;

const ClaimPointsCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #fff;
  border: solid ${pxToRem(2)} ${props => props.theme.inputs.border};
  padding: 1.5rem;
  border-radius: ${pxToRem(6)};
`;

const PromotionDetailsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 0.75rem;
  max-width: ${pxToRem(524)};
`;

const PromotionImageWrapper = styled.div`
  width: fit-content;
  margin-right: 0.5rem;
`;

const PromotionImage = styled.img`
  width: ${pxToRem(77)};
  height: ${pxToRem(77)};
  border-radius: 0.5rem;
  margin-right: 2.5rem;
`;

const PromotionTextWrapper = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const PromotionTitle = styled.h3`
  font-size: ${pxToRem(20)};
  font-weight: 500;
`;

const PromotionBusiness = styled.h4`
  font-size: ${pxToRem(18)};
  font-weight: 400;
`;

const LogoWrapper = styled.div`
  margin-top: ${pxToRem(40)};

  * {
    fill: black;
  }
`;

const FacebookButton = styled(ButtonWithLoading)`
  text-align: center;
  height: ${pxToRem(45)};
  width: 100%;
  background-color: ${clRewardsTheme.buttons.bgColor.facebookBlue};
  color: ${clRewardsTheme.buttons.textColor.white};
  font-size: 0.9rem;
  font-weight: 500;
  border-radius: 4px;
  margin-bottom: 0.75rem;
  letter-spacing: 0.025rem;
  max-width: ${pxToRem(524)};

  @media (min-width: ${defaultTheme.breakpoints.xs}) {
    margin-top: 2rem;
    font-size: 1rem;
    height: ${pxToRem(55)};
  }
`;

const StyledClaimPointsButton = styled(FacebookButton)`
  color: ${clRewardsTheme.buttons.textColor.black};
  background-color: ${clRewardsTheme.buttons.bgColor.casperGreen};
`;

const GenericLoginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const LoaderWrapper = styled.div`
  height: 50vh;
`;

const ClaimPointsButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
