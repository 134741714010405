import { ApiData } from 'src/api/types';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { middlewareServiceApi } from 'src/api';
import { AxiosError } from 'axios';
import { Loading } from '../loading.type';
import { BusinessUserCreateProfile } from '../types';

export interface BusinessUserState {
  status: Loading;
  error: AxiosError | null;
  businessUserAuthenticationStatus: Loading;
  businessUser: ApiData.BusinessUser | null;
}

const initialState: BusinessUserState = {
  status: Loading.Idle,
  error: null,
  businessUserAuthenticationStatus: Loading.Idle,
  businessUser: null,
};

// looks up business user based on the JWT
export const fetchLoggedInBusinessUser = createAsyncThunk(
  'businessUser/fetchLoggedInBusinessUser',
  async (_, { rejectWithValue }) => {
    try {
      const businessUser =
        await middlewareServiceApi.businessUser.fetchLoggedInBusinessUser();

      return businessUser;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const fetchBusinessUserByInstagramHandle = createAsyncThunk(
  'businessUser/fetchBusinessUserByInstagramHandle',
  async (id: string, { rejectWithValue }) => {
    try {
      const businessUser =
        await middlewareServiceApi.businessUser.fetchBusinessUserByInstagramHandle(
          id,
        );

      return businessUser;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const setBusinessUserTermsAndConditions = createAsyncThunk(
  'businessUser/setBusinessUserTermsAndConditions',
  async (
    args: {
      businessUserId: string;
      termsAndConditions: null | string;
    },
    { rejectWithValue },
  ) => {
    try {
      const businessUser =
        await middlewareServiceApi.businessUser.setBusinessUserTermsAndConditions(
          args,
        );

      return businessUser;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const createBusinessUserProfile = createAsyncThunk(
  'businessUser/createBusinessUserProfile',
  async (
    args: {
      businessUserId: string;
      createProfile: BusinessUserCreateProfile;
    },
    { rejectWithValue },
  ) => {
    try {
      const businessUser =
        await middlewareServiceApi.businessUser.createProfile(args);

      return businessUser;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const authenticateBusinessUser = createAsyncThunk(
  'businessUser/authenticate',
  async (accessToken: string, { rejectWithValue }) => {
    try {
      const jwt =
        await middlewareServiceApi.businessUser.authenticateBusinessUser(
          accessToken,
        );

      return jwt;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const businessUserSlice = createSlice({
  name: 'businessUser',
  initialState,
  reducers: {
    resetLoadingStatus: state => {
      state.status = Loading.Idle;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchBusinessUserByInstagramHandle.pending, state => {
        state.status = Loading.Pending;
      })
      .addCase(
        fetchBusinessUserByInstagramHandle.fulfilled,
        (state, { payload }) => {
          state.status = Loading.Complete;
          state.businessUser = payload;
        },
      )
      .addCase(
        fetchBusinessUserByInstagramHandle.rejected,
        (state, { payload }) => {
          state.error = payload as AxiosError;
          state.status = Loading.Failed;
        },
      )
      .addCase(authenticateBusinessUser.pending, state => {
        state.businessUserAuthenticationStatus = Loading.Pending;
      })
      .addCase(authenticateBusinessUser.fulfilled, (state, { payload }) => {
        state.businessUserAuthenticationStatus = Loading.Complete;
        state.businessUser = payload;
      })
      .addCase(authenticateBusinessUser.rejected, (state, { payload }) => {
        state.error = payload as AxiosError;
        state.businessUserAuthenticationStatus = Loading.Failed;
      })
      .addCase(fetchLoggedInBusinessUser.pending, state => {
        state.status = Loading.Pending;
      })
      .addCase(fetchLoggedInBusinessUser.fulfilled, (state, { payload }) => {
        state.status = Loading.Complete;
        state.businessUser = payload;
      })
      .addCase(fetchLoggedInBusinessUser.rejected, (state, { payload }) => {
        state.error = payload as AxiosError;
        state.status = Loading.Failed;
      })
      .addCase(setBusinessUserTermsAndConditions.pending, state => {
        state.status = Loading.Pending;
      })
      .addCase(
        setBusinessUserTermsAndConditions.fulfilled,
        (state, { payload }) => {
          state.status = Loading.Complete;
          state.businessUser = payload;
        },
      )
      .addCase(
        setBusinessUserTermsAndConditions.rejected,
        (state, { payload }) => {
          state.error = payload as AxiosError;
          state.status = Loading.Failed;
        },
      )
      .addCase(createBusinessUserProfile.pending, state => {
        state.status = Loading.Pending;
      })
      .addCase(createBusinessUserProfile.fulfilled, (state, { payload }) => {
        state.status = Loading.Complete;
        state.businessUser = payload;
      })
      .addCase(createBusinessUserProfile.rejected, (state, { payload }) => {
        state.error = payload as AxiosError;
        state.status = Loading.Failed;
      });
  },
});

export const { resetLoadingStatus } = businessUserSlice.actions;
