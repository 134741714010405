import React, { Fragment, useEffect } from 'react';
import { BrowserRouter, Route, Navigate } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import useAsyncEffect from 'use-async-effect';
import './i18n';
import { ThemeProvider } from '@emotion/react';
import styled from '@emotion/styled';
// eslint-disable-next-line import/extensions
import 'react-toastify/dist/ReactToastify.css';
import { loadConfig } from 'src/utils';
import { ToastContainer } from 'react-toastify';
import useMeasure from 'react-use-measure';

import { addMinutes, compareAsc } from 'date-fns';
import 'react-loading-skeleton/dist/skeleton.css';
import {
  CreatePromotion,
  SignUpFailurePage,
  MyAccount,
  ManageProfile,
  BusinessUserLoginPage,
  ManagePromotion,
  RewardDetails,
  RewardDetailsSuccess,
  Encryption,
  EditProfile,
} from './pages';
import {
  fetchLoggedInBusinessUser,
  fetchLoggedInEndUser,
  getFacebookAuthInfo,
  setFacebookAuthResponse,
  updateBounds,
  useAppDispatch,
  useAppSelector,
} from './store';
import { SignUpPage } from './pages/SignUpPage';
import { AuthRoutes } from './components/auth';
import { Header } from './components/layout/Header/Header';
import {
  EndUserLoginPage,
  MyRewards,
  RedeemCustomerReward,
  RedeemPoints,
} from './pages/end-user';
import { clRewardsTheme } from './styled-theme';
import { Dashboard } from './pages/end-user/Dashboard';
import { TermsAndConditions } from './pages/TermsAndConditions';
import { BusinessUserDashboard } from './pages/BusinessUserDashboard';
import { DefaultNavigate } from './pages/DefaultNavigate';
import { PageError } from './pages/PageError';
import { Referrals } from './pages/end-user/Referrals';
import { Reports } from './pages/Reports';
import { getBusinessUser } from './store/selectors/business-user-selectors';
import { getEndUser } from './store/selectors/end-user-selectors';
import { ErrorBoundary } from './components';
import RouteChangeTracker from './components/analytics/RouteChangeTracker';

const { fbAppIdBusinessUser, fbAppIdEndUser, fbGraphApiVersion } = loadConfig();

const App = () => {
  const [ref, bounds] = useMeasure();

  const dispatch = useAppDispatch();

  const businessUser = useAppSelector(getBusinessUser);
  const endUser = useAppSelector(getEndUser);
  const fbAuthInfo = useAppSelector(getFacebookAuthInfo);

  useEffect(() => {
    dispatch(updateBounds(bounds));
  }, [bounds, dispatch]);

  useAsyncEffect(async () => {
    if (!fbAuthInfo?.authResponse) {
      return;
    }

    const { pathname } = window.location;

    if (pathname.includes('business-user') && !businessUser) {
      refreshBusinessUserJWT();
    }

    if (pathname.includes('end-user') && !endUser) {
      refreshEndUserJWT();
    }
  }, [fbAuthInfo, businessUser, endUser, dispatch]);

  useEffect(() => {
    const { pathname } = window.location;

    let fbAppId: string;
    if (pathname.includes('business-user')) {
      fbAppId = fbAppIdBusinessUser;
    } else if (pathname.includes('end-user')) {
      fbAppId = fbAppIdEndUser;
    } else {
      throw new Error(
        'Invalid pathname. Must include either end-user or business-user',
      );
    }

    window.fbAsyncInit = () => {
      window.FB.init({
        appId: fbAppId,
        autoLogAppEvents: true,
        xfbml: true,
        version: fbGraphApiVersion,
      });

      // TODO think about if this needs to be called more frequently
      getFBLoginStatus();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isDateWithinNextHour = (dateString: string): boolean => {
    const expiryTime = new Date(dateString);
    const oneHourFromNow = addMinutes(new Date(), 60);
    return compareAsc(oneHourFromNow, expiryTime) > 0;
  };

  const shouldRefreshJWT = () => {
    const authToken = localStorage.getItem('token');
    const authExpiry = localStorage.getItem('tokenExpiry');

    // no token in session to refresh
    if (!authToken || !authExpiry) {
      return false;
    }

    if (isDateWithinNextHour(authExpiry)) {
      return true;
    }
    return false;
  };

  // Specific implementations for business and end users
  const refreshBusinessUserJWT = () => {
    if (shouldRefreshJWT()) {
      dispatch(fetchLoggedInBusinessUser);
    }
  };

  const refreshEndUserJWT = () => {
    if (shouldRefreshJWT()) {
      dispatch(fetchLoggedInEndUser);
    }
  };

  const getFBLoginStatus = () => {
    window.FB.getLoginStatus(res => {
      const facebookAuthResponse = res;
      dispatch(setFacebookAuthResponse(facebookAuthResponse));
    });
  };

  return (
    <HelmetProvider>
      <ThemeProvider theme={clRewardsTheme}>
        <React.Suspense>
          <Helmet>
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" />
          </Helmet>
          <AppWrapper ref={ref}>
            <BrowserRouter>
              <ErrorBoundary>
                <RouteChangeTracker>
                  <>
                    <Header />
                    <AuthRoutes>
                      <Route path="business-user">
                        <Route
                          path="login"
                          element={<BusinessUserLoginPage />}
                        />
                        <Route
                          path="create-profile"
                          element={<ManageProfile />}
                        />
                        <Route path="" element={<BusinessUserDashboard />} />
                        <Route
                          path="dashboard"
                          element={<BusinessUserDashboard />}
                        />
                        <Route
                          path="create-promotion"
                          element={<CreatePromotion />}
                        />
                        <Route path="edit-profile" element={<EditProfile />} />
                        <Route
                          path="manage-promotion/:id"
                          element={<ManagePromotion />}
                        />
                        <Route
                          path="my-account"
                          element={<MyAccount userType="business-user" />}
                        />
                        <Route
                          path="reward-details"
                          element={<RewardDetails />}
                        />
                        <Route
                          path="reward-details-success"
                          element={<RewardDetailsSuccess />}
                        />
                        <Route
                          path="redeem-customer-reward"
                          element={<RedeemCustomerReward />}
                        />
                        <Route path="sign-up" element={<SignUpPage />} />
                        <Route
                          path="sign-up-failure"
                          element={<SignUpFailurePage />}
                        />
                        <Route
                          path="terms-and-conditions"
                          element={<TermsAndConditions />}
                        />

                        <Route
                          path="*"
                          element={<Navigate to="dashboard" replace />}
                        />
                        <Route path="page-error" element={<PageError />} />
                        <Route path="encryption" element={<Encryption />} />
                      </Route>
                      <Route path="end-user">
                        <Route path="dashboard" element={<Dashboard />} />
                        <Route path="login" element={<EndUserLoginPage />} />
                        <Route path="rewards" element={<MyRewards />} />
                        <Route
                          path="redeem-points"
                          element={<RedeemPoints />}
                        />
                        <Route path="referrals" element={<Referrals />} />
                        <Route path="reports" element={<Reports />} />
                        <Route
                          path="my-account"
                          element={<MyAccount userType="end-user" />}
                        />

                        <Route
                          path="*"
                          element={<Navigate to="login" replace />}
                        />
                        <Route path="page-error" element={<PageError />} />
                        <Route path="encryption" element={<Encryption />} />
                      </Route>
                      <Route path="*" element={<DefaultNavigate />} />
                    </AuthRoutes>
                  </>
                </RouteChangeTracker>
              </ErrorBoundary>
            </BrowserRouter>
            <ToastContainer />
          </AppWrapper>
        </React.Suspense>
      </ThemeProvider>
    </HelmetProvider>
  );
};

const AppWrapper = styled.div`
  background-color: ${props => props.theme.backgrounds.primary};
`;
export default App;
