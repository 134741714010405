import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import { Stepper } from 'src/components/stepper';
import { defaultTheme, pxToRem } from 'casper-ui-kit';
import { PageWrapper } from 'src/components/layout/PageWrapper';
import { useTranslation } from 'react-i18next';
import { CreatePromotionFormController } from 'src/components/create-promotion/steps/CreatePromotionFormController';

const TOTAL_STEPS = 5;

export const CreatePromotion: React.FC = () => {
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState(0);

  const alertUser = (e: BeforeUnloadEvent) => {
    e.preventDefault();
    e.returnValue = '';
  };

  useEffect(() => {
    window.addEventListener('beforeunload', alertUser);
    return () => {
      window.removeEventListener('beforeunload', alertUser);
    };
  }, []);

  return (
    <PageWrapper>
      <TitleWrapper>
        <TitleText>{t('Create Promotion')}</TitleText>
        <StepperWrapper>
          <Stepper totalSteps={TOTAL_STEPS} currentStep={currentStep} />
        </StepperWrapper>
      </TitleWrapper>

      <CreatePromotionFormController
        currentStep={currentStep}
        handleContinue={() => setCurrentStep(prev => prev + 1)}
        handleBack={() => setCurrentStep(prev => prev - 1)}
        setCurrentStep={setCurrentStep}
      />
    </PageWrapper>
  );
};

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  margin: 2rem 0;

  @media (min-width: ${defaultTheme.breakpoints.md}) {
    margin-top: 0;
  }

  @media (min-width: ${defaultTheme.breakpoints.lg}) {
    flex-wrap: nowrap;
  }
`;

const TitleText = styled.h1`
  font-size: 2.5rem;
  white-space: nowrap;
  font-weight: 400;
`;

const StepperWrapper = styled.div`
  width: 100%;

  .StepperContainer-1-2-1 {
    padding: 0;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pxToRem(20)};
  align-items: center;
  padding-top: ${pxToRem(20)};

  @media (min-width: ${defaultTheme.breakpoints.lg}) {
    flex-direction: row;
    gap: ${pxToRem(40)};
  }
`;
