import React from 'react';
import styled from '@emotion/styled';
import { Button, pxToRem, defaultTheme } from 'casper-ui-kit';
import { clRewardsTheme } from 'src/styled-theme';

export interface NavbarItemLinkButtonProps {
  readonly isRouteSelected: boolean;
  readonly children: React.ReactNode;
}

export const NavbarItemLinkButton: React.FC<NavbarItemLinkButtonProps> = ({
  children,
  isRouteSelected,
}) => (
  <NavItemWrapper>
    <DesktopNavItemLink type="button" isRouteSelected={isRouteSelected}>
      {children}
    </DesktopNavItemLink>
  </NavItemWrapper>
);

const DesktopNavItemLink = styled(Button)<{
  isRouteSelected: boolean;
}>`
  color: ${clRewardsTheme.buttons.textColor.white};
  background-color: transparent;
  padding: ${pxToRem(6)} ${pxToRem(20)};
  font-size: 1.25rem;
  font-weight: ${({ isRouteSelected }) =>
    isRouteSelected
      ? defaultTheme.typography.fontWeights.medium
      : defaultTheme.typography.fontWeights.normal};
  text-decoration: none;
  border-radius: 0;
  padding: 0;
`;

const NavItemWrapper = styled.div`
  text-align: center;
`;
