import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { MyRewardToggleFilter } from 'src/components/filters/MyRewardToggleFilter';
import {
  clearEndUserRedeemableRewards,
  clearEndUserRedeemedRewards,
  resetEndUserRedeemableRewardsLoadingStatus,
  resetEndUserRedeemedRewardsLoadingStatus,
  useAppDispatch,
} from 'src/store';
import { MyRewardsFilterOptions } from 'src/pages/end-user/Dashboard';
import {
  HeaderWrapper,
  TabHeader,
  TabWrapper,
} from './ParticipatingBusinessesTab';
import { RedeemedRewardCards } from './RedeemedRewardCards';
import { RedeemableRewardCards } from './RedeemableRewardCards';

export const RewardsTab: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [myRewardFilter, setMyRewardFilter] = useState(
    MyRewardsFilterOptions.ToBeRedeemed,
  );

  useEffect(() => {
    return () => {
      dispatch(clearEndUserRedeemedRewards());
      dispatch(clearEndUserRedeemableRewards());
      dispatch(resetEndUserRedeemableRewardsLoadingStatus());
      dispatch(resetEndUserRedeemedRewardsLoadingStatus());
    };
  }, [dispatch]);

  const renderRewardCards = () => {
    switch (myRewardFilter) {
      case MyRewardsFilterOptions.Redeemed:
        return <RedeemedRewardCards />;
      case MyRewardsFilterOptions.ToBeRedeemed:
        return <RedeemableRewardCards />;
      default:
        throw new Error('Could not properly render reward cards.');
    }
  };

  return (
    <TabWrapper>
      <HeaderWrapper>
        <TabHeader>{t('My Rewards')}</TabHeader>
        <FilterWrapper>
          <MyRewardToggleFilter
            myRewardFilter={myRewardFilter}
            setMyRewardFilter={setMyRewardFilter}
          />
        </FilterWrapper>
      </HeaderWrapper>

      {renderRewardCards()}
    </TabWrapper>
  );
};

const FilterWrapper = styled.div`
  margin-left: 2rem;
`;
