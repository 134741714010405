import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { initialize, set as setPage, pageview as pageView } from 'react-ga';
import { loadConfig } from 'src/utils';

interface RouteChangeTrackerProps {
  children: React.ReactElement;
}

const { googAnalyticsId } = loadConfig();

const RouteChangeTracker: React.FC<RouteChangeTrackerProps> = ({
  children,
}) => {
  const { pathname, search } = useLocation();

  useEffect(() => {
    initialize(googAnalyticsId);

    const trackPage = (path: string) => {
      setPage({ page: path });
      pageView(path);
    };

    trackPage(pathname + search);
  }, [pathname, search]);

  return children;
};

export default RouteChangeTracker;
