import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { GradientCapsuleIcon } from 'src/components/icons';
import { CreatePromotionEngagementLevelUiProps } from './engagement-level-ui.types';
import { InputErrorMessageWrapper } from '../../CreatePromotion.styled';
import {
  EngagementLevelWrapper,
  GradientCapsuleWrapper,
  TriangleIconsWrapper,
  EngagementLevelOptions,
  StyledRadioButton,
  EngagementLevelHeader,
  LevelDescription,
  StyledStrong,
  UsersSelectionAlert,
} from './EngagementLevelUi.styled';
import { CreateBusinessPromotion2Form } from '../../create-promotion-form-types';

const engagementLevels = [
  {
    level: `${t('Light')}`,
    interactionThreshold: `${t('1 time')}`,
    points: '100',
    pointsForRedemption: '100',
    key: 'Light',
  },
  {
    level: `${t('Moderate')}`,
    interactionThreshold: `${t('5 times')}`,
    points: '20',
    pointsForRedemption: '100',
    key: 'Moderate',
  },
  {
    level: `${t('Deep')}`,
    interactionThreshold: `${t('10 times')}`,
    points: '10',
    pointsForRedemption: '100',
    key: 'Deep',
  },
];

export const EngagementLevelUi: React.FC<
  CreatePromotionEngagementLevelUiProps
> = ({ register, setValue, engagementLevel }) => {
  const [selection, setSelection] = useState<
    CreateBusinessPromotion2Form['engagementLevel'] | undefined
  >(engagementLevel);

  useEffect(() => {
    if (selection) {
      engagementLevels.forEach(engagementLevel => {
        if (engagementLevel.level === selection) {
          setValue('pointsForEngagement', engagementLevel.points);
          setValue('pointsForRedemption', engagementLevel.pointsForRedemption);
          setValue('engagementLevel', selection);
        }
      });
    }
  }, [selection, setValue]);

  const handleSelectEngagementLevel = (
    level: CreateBusinessPromotion2Form['engagementLevel'],
  ) => {
    setSelection(level);
    engagementLevels.forEach(engagementLevel => {
      if (engagementLevel.level === selection) {
        return setValue('pointsForEngagement', engagementLevel.points);
      }
    });
  };

  return (
    <EngagementLevelWrapper>
      <InputErrorMessageWrapper>
        <GradientCapsuleWrapper>
          <GradientCapsuleIcon />
        </GradientCapsuleWrapper>
        <TriangleIconsWrapper>
          {engagementLevels.map(({ level, interactionThreshold, key }) => {
            const engagementLevelType = key.toLocaleLowerCase();

            return (
              <EngagementLevelOptions
                key={key}
                onClick={() =>
                  handleSelectEngagementLevel(
                    level as CreateBusinessPromotion2Form['engagementLevel'],
                  )
                }>
                <StyledRadioButton
                  data-testid={`${engagementLevelType}-radio-button`}
                  value={level}
                  type="radio"
                  id={key}
                  {...register('engagementLevel', {
                    required: true,
                  })}
                />
                <EngagementLevelHeader>{level}</EngagementLevelHeader>
                <LevelDescription>
                  {t('e.g. Customer interacts with your promotion')}{' '}
                  <StyledStrong>{interactionThreshold}</StyledStrong>{' '}
                  {t('to achieve rewards.')}
                </LevelDescription>
                <UsersSelectionAlert data-testid="selection-alert">
                  {selection === key ? 'YOU SELECTED' : ''}
                </UsersSelectionAlert>
              </EngagementLevelOptions>
            );
          })}
        </TriangleIconsWrapper>
      </InputErrorMessageWrapper>
    </EngagementLevelWrapper>
  );
};
