import React from 'react';
import styled from '@emotion/styled';
import { Button, ButtonType, defaultTheme, pxToRem } from 'casper-ui-kit';
import { clRewardsTheme } from 'src/styled-theme';

interface CreatePromotionButtonProps {
  type: ButtonType;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  children: React.ReactNode;
  bgColor: string;
  testId?: string;
  disabled?: boolean;
}

export const CreatePromotionButton: React.FC<CreatePromotionButtonProps> = ({
  type,
  onClick,
  children,
  bgColor,
  testId,
  disabled,
}) => (
  <StyledButton
    disabled={disabled}
    data-testid={testId}
    type={type}
    fontColor={clRewardsTheme.buttons.textColor.black}
    bgColor={bgColor}
    onClick={onClick}>
    {children}
  </StyledButton>
);

const StyledButton = styled(Button)`
  min-width: ${pxToRem(244)};
  max-width: fit-content;
  height: ${pxToRem(65)};
  font-size: ${pxToRem(24)};
  font-weight: ${defaultTheme.typography.fontWeights.medium};
`;
