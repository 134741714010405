import styled from '@emotion/styled';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { defaultTheme, pxToRem } from 'casper-ui-kit';
import { format } from 'date-fns';
import { withSkeletonLoading } from 'src/components/loading';
import {
  CardWrapper,
  PromotionDataLabel,
  PromotionDataValue,
  PromotionHeading,
  ImageWrapper,
} from '../cards.styled';

export interface RedeemedRewardCardProps {
  readonly promotionName: string;
  readonly promotionImage: string;
  readonly promotionCaption: string;
  readonly promotionHashtag: string;
  readonly pointsForRedemption: number;
  readonly rewardRedemptionDate: string | null;
  readonly rewardClaimDate: string;
  readonly isLoading: boolean;
}

export const RedeemedRewardCard: React.FC<RedeemedRewardCardProps> = ({
  promotionName,
  promotionImage,
  promotionCaption,
  promotionHashtag,
  pointsForRedemption,
  rewardRedemptionDate,
  rewardClaimDate,
  isLoading,
}) => {
  const { t } = useTranslation();

  return (
    <CardWrapper>
      <PromotionHeading>
        {withSkeletonLoading(promotionName, isLoading)}
      </PromotionHeading>
      <PromotionDataWrapper>
        <PromotionImageWrapper>
          {withSkeletonLoading(
            <ImageWrapper>
              <PromotionImage src={promotionImage} alt="promotion" />
            </ImageWrapper>,
            isLoading,
            {
              height: '6rem',
            },
          )}
        </PromotionImageWrapper>
        <PromotionDescriptionWrapper>
          {withSkeletonLoading(
            <>
              <PromotionDescription>{promotionCaption}</PromotionDescription>
              <PromotionHashtag>
                <p>{t('Promotion hashtag:')}</p>
                <p>{promotionHashtag}</p>
              </PromotionHashtag>
            </>,
            isLoading,
            { height: '3rem' },
          )}
        </PromotionDescriptionWrapper>
      </PromotionDataWrapper>
      <RewardDataWrapper>
        <div>
          <PromotionDataValue>
            {withSkeletonLoading(
              rewardRedemptionDate
                ? format(new Date(rewardRedemptionDate), 'dd-MM-yyyy')
                : '',
              isLoading,
            )}
          </PromotionDataValue>
          <PromotionDataLabel>
            {withSkeletonLoading(t('Date reward redeemed'), isLoading)}
          </PromotionDataLabel>
        </div>
        <div>
          <PromotionDataValue>
            {withSkeletonLoading(
              rewardClaimDate
                ? format(new Date(rewardClaimDate), 'dd-MM-yyyy')
                : '',
              isLoading,
            )}
          </PromotionDataValue>
          <PromotionDataLabel>
            {withSkeletonLoading(t('Date reward claimed'), isLoading)}
          </PromotionDataLabel>
        </div>
        <div>
          <PromotionDataValue>
            {withSkeletonLoading(`${pointsForRedemption} points`, isLoading)}
          </PromotionDataValue>
          <PromotionDataLabel>
            {withSkeletonLoading(t('Points exchanged for reward'), isLoading)}
          </PromotionDataLabel>
        </div>
      </RewardDataWrapper>
    </CardWrapper>
  );
};

const PromotionDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const PromotionImageWrapper = styled.div``;

const PromotionImage = styled.img`
  width: ${pxToRem(120)};
  height: ${pxToRem(120)};
`;

const RewardDataWrapper = styled.div`
  display: grid;
  row-gap: 1.5rem;

  @media only screen and (min-width: ${defaultTheme.breakpoints.sm}) {
    grid-template-columns: ${pxToRem(130)} ${pxToRem(130)};
    grid-template-rows: 4rem 4rem;
    row-gap: 1rem;
    column-gap: 2rem;
  }

  @media only screen and (min-width: ${defaultTheme.breakpoints.sm}) {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 4rem;
  }
`;

const PromotionDataWrapper = styled.div`
  display: flex;
  gap: 1.5rem;
  grid-template-columns: ${pxToRem(120)} 1fr;
  margin-bottom: 1rem;
`;

const PromotionDescription = styled.p`
  font-size: ${pxToRem(12)};
  margin-bottom: 1rem;
`;

const PromotionHashtag = styled.div`
  font-size: ${pxToRem(12)};
  margin-bottom: 1.5rem;
`;
