import styled from '@emotion/styled';
import React, { ChangeEvent, useState } from 'react';
import { PageWrapper } from 'src/components/layout/PageWrapper';
import { encryptAes } from 'src/utils';

// for dev use only
export const Encryption: React.FC = () => {
  const [secretKey, setSecretKey] = useState('');
  const [encryptedValue, setEncryptedValue] = useState('');

  if (process.env.NODE_ENV !== 'development') {
    return null;
  }

  const handleEncryption = (input: ChangeEvent<HTMLInputElement>) => {
    if (!secretKey) {
      throw new Error('Secret key not provided');
    }

    const encryptedVal = encryptAes(input.target.value, secretKey);

    setEncryptedValue(encryptedVal);
  };

  return (
    <PageWrapper>
      <InputWrapper>
        <input
          onChange={input => setSecretKey(input.target.value)}
          placeholder="secret key"
        />
        <input onChange={handleEncryption} placeholder="value" />
      </InputWrapper>
      <EncryptedValueWrapper>
        <p>{encryptedValue}</p>
      </EncryptedValueWrapper>
    </PageWrapper>
  );
};

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const EncryptedValueWrapper = styled.div`
  width: 100%;
  word-wrap: break-word;
  user-select: all;
`;
