import styled from '@emotion/styled';
import { pxToRem } from 'casper-ui-kit';
import { t } from 'i18next';
import React, { useState } from 'react';
import { Path, UseFormRegister } from 'react-hook-form';
import { ManageProfileFormValues } from 'src/pages/ManageProfile';

interface CharacterCappedTextAreaProps {
  readonly maxLength: number;
  readonly required: boolean;
  readonly fieldName: Path<ManageProfileFormValues>;
  readonly register: UseFormRegister<ManageProfileFormValues>;
  readonly defaultValue?: string;
}

export const CharacterCappedTextArea: React.FC<
  CharacterCappedTextAreaProps
> = ({ maxLength, register, fieldName, required, defaultValue }) => {
  const [charactersLeft, setCharactersLeft] = useState(1000);

  const handleTextInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const currentCount = e.target.value.length;
    setCharactersLeft(maxLength - currentCount);
  };

  return (
    <>
      <StyledTextArea
        rows={4}
        cols={62}
        maxLength={maxLength}
        placeholder="Description of your promotion"
        defaultValue={defaultValue}
        {...register(fieldName, {
          onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) =>
            handleTextInput(e),
          required,
        })}
      />
      <p>
        {charactersLeft}
        {` ${t('character')}${charactersLeft === 1 ? '' : 's'} ${t(
          'remaining',
        )}`}
      </p>
    </>
  );
};

const StyledTextArea = styled.textarea`
  font-size: ${pxToRem(18)};
  background-color: ${props => props.theme.inputs.bgColor};
  max-width: ${pxToRem(666)};
  width: 100%;
  height: ${pxToRem(126)};
  border: solid ${pxToRem(2)} ${props => props.theme.inputs.border};
  padding: 0.5rem 1rem;
  border-radius: ${pxToRem(8)};
  resize: none;
`;
