import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useDebounce } from 'use-debounce';
import {
  Loading,
  clearBusinessPromotions,
  fetchBusinessPromotions,
  getBusinessPromotionLoadingStatus,
  getBusinessPromotions,
  resetBusinessPromotionsLoadingStatus,
  useAppDispatch,
  useAppSelector,
} from 'src/store';
import { pxToRem } from 'casper-ui-kit';
import { getSkeletonLoadingData } from 'src/components/loading';
import { skeletonData } from 'src/mock-data/skeleton-loading';
import {
  InputErrorMessageWrapper,
  StyledErrorMessage,
} from '../../../pages/SharedStyles/pages.styled';
import { LivePromotionsForm } from '../mobile-view/LivePromotions';
import {
  CardContentsWrapper,
  HeaderWrapper,
  InputWrapper,
  StyledInput,
  TabHeader,
  TabWrapper,
} from './ParticipatingBusinessesTab';
import { CardContainer } from '../../cards/cards.styled';
import { LivePromotionsCard } from './LivePromotionsCard';

export const LivePromotionsTab: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const {
    register,
    formState: { errors },
    watch,
  } = useForm<LivePromotionsForm>();

  const businessPromotions = useAppSelector(getBusinessPromotions);
  const businessPromotionsLoadingStatus = useAppSelector(
    getBusinessPromotionLoadingStatus,
  );

  const promotionNameSearch = watch('promotionName') ?? '';
  const myPromotionsCheckbox = watch('myPromotionsCheckBox') ?? false;

  const [debouncedPromotionNameSearch] = useDebounce(promotionNameSearch, 500);

  useEffect(() => {
    dispatch(
      fetchBusinessPromotions({
        search: { promotionName: debouncedPromotionNameSearch },
        filters: {
          myPromotions: myPromotionsCheckbox,
        },
        active: true,
      }),
    );
  }, [dispatch, debouncedPromotionNameSearch, myPromotionsCheckbox]);

  useEffect(() => {
    return () => {
      dispatch(clearBusinessPromotions());
      dispatch(resetBusinessPromotionsLoadingStatus());
    };
  }, [dispatch]);

  const isBusinessPromotionLoading =
    businessPromotionsLoadingStatus !== Loading.Complete;

  const getPromotionsContent = () => {
    if (
      !businessPromotions?.length &&
      businessPromotionsLoadingStatus === Loading.Complete
    ) {
      return (
        <CardContainer>
          <NoResultsText>{t('No live promotions currently')}</NoResultsText>
        </CardContainer>
      );
    }

    return (
      businessPromotions ??
      getSkeletonLoadingData(skeletonData.businessPromotion, 8)
    ).map(businessPromotion => {
      const { _id: uniqueIdHash } = businessPromotion;

      return (
        <LivePromotionsCard
          key={uniqueIdHash}
          businessPromotion={businessPromotion}
          isLoading={isBusinessPromotionLoading}
        />
      );
    });
  };

  return (
    <TabWrapper>
      <HeaderWrapper>
        <TabHeader>{t('Live Promotions')}</TabHeader>
        <SearchForm>
          <InputErrorMessageWrapper>
            <InputWrapper>
              <StyledInput
                id="promotion name-name"
                placeholder="Search for promotions"
                {...register('promotionName', {
                  required: true,
                })}
              />
            </InputWrapper>
            {errors.promotionName && (
              <StyledErrorMessage>
                {t('Please enter a valid promotion name.')}
              </StyledErrorMessage>
            )}
          </InputErrorMessageWrapper>
        </SearchForm>
      </HeaderWrapper>

      <CardContentsWrapper>{getPromotionsContent()}</CardContentsWrapper>
    </TabWrapper>
  );
};

const SearchForm = styled.form`
  margin-left: 2rem;
`;

const NoResultsText = styled.p`
  font-size: ${pxToRem(18)};
`;
