import React from 'react';
import styled from '@emotion/styled';
import { Button, ButtonType, defaultTheme, pxToRem } from 'casper-ui-kit';
import { clRewardsTheme } from 'src/styled-theme';

interface StyledButtonProps {
  type: ButtonType;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  children: React.ReactNode;
  bgColor?: string;
  fontColor?: string;
  width?: string;
}

export const StyledButton: React.FC<StyledButtonProps> = ({
  type,
  onClick,
  children,
  bgColor = clRewardsTheme.buttons.bgColor.casperGreen,
  fontColor = clRewardsTheme.buttons.textColor.black,
  width,
}) => (
  <BaseStyledButton
    width={width}
    type={type}
    fontColor={fontColor}
    bgColor={bgColor}
    onClick={onClick}>
    {children}
  </BaseStyledButton>
);

const BaseStyledButton = styled(Button)`
  width: 100%;
  height: ${pxToRem(50)};
  font-size: ${pxToRem(18)};
  font-weight: ${defaultTheme.typography.fontWeights.medium};

  @media (min-width: ${defaultTheme.breakpoints.md}) {
    min-width: 75%;
    font-size: ${pxToRem(24)};
  }
`;
