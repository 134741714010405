import React, { useEffect } from 'react';
import {
  Loading,
  fetchMyPromotionsForBusinessDashboard,
  getBusinessPromotionLoadingStatus,
  getMyPromotionsForBusinessDashboard,
  useAppDispatch,
  useAppSelector,
} from 'src/store';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { defaultTheme, pxToRem } from 'casper-ui-kit';
import { NavLink } from 'react-router-dom';
import { skeletonData } from 'src/mock-data/skeleton-loading';
import {
  getSkeletonLoadingData,
  withSkeletonLoading,
} from 'src/components/loading';
import { CardContainer } from '../cards.styled';

export const BusinessUserDashboardPromotions = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const myPromotions = useAppSelector(getMyPromotionsForBusinessDashboard);
  const businessPromotionLoadingStatus = useAppSelector(
    getBusinessPromotionLoadingStatus,
  );

  useEffect(() => {
    dispatch(fetchMyPromotionsForBusinessDashboard());
  }, [dispatch]);

  if (
    !myPromotions?.length &&
    businessPromotionLoadingStatus === Loading.Complete
  ) {
    return <CardContainer>{t('No active promotions')}</CardContainer>;
  }

  const getMyPromotionsWithSkeletonLoading = () => {
    return (
      myPromotions ?? getSkeletonLoadingData(skeletonData.businessPromotion, 3)
    );
  };

  const isBusinessPromotionLoading =
    businessPromotionLoadingStatus !== Loading.Complete;

  return (
    <PromotionCardContainer>
      <BusinessUserCardWrapper>
        {getMyPromotionsWithSkeletonLoading().map(promotion => {
          const {
            _id: promotionId,
            promotionName,
            promotionCaption,
            promotionImage,
            status,
            totalPointsCollected,
            totalRewardsClaimed,
            totalRewardsRedeemed,
          } = promotion;

          return (
            <NavLink
              to={`/business-user/manage-promotion/${promotionId}`}
              key={promotionId}>
              <BusinessUserPromotionCard data-cy="promotion-card">
                <BusinessPromotionName>
                  {withSkeletonLoading(
                    promotionName,
                    isBusinessPromotionLoading,
                  )}
                </BusinessPromotionName>
                <ImageAndDescriptionWrapper>
                  {withSkeletonLoading(
                    <div>
                      <PromotionImage
                        src={promotionImage}
                        alt="promotion-image"
                      />
                    </div>,
                    isBusinessPromotionLoading,
                    { height: '10rem' },
                  )}
                  <BusinessDescription>
                    {withSkeletonLoading(
                      promotionCaption,
                      isBusinessPromotionLoading,
                      {
                        height: '7.5rem',
                      },
                    )}
                  </BusinessDescription>
                </ImageAndDescriptionWrapper>
                <PromotionDataWrapper>
                  <div>
                    <BusinessUserPromotionValues>
                      {withSkeletonLoading(status, isBusinessPromotionLoading)}
                    </BusinessUserPromotionValues>
                    <BusinessUserPromotionLabels>
                      {withSkeletonLoading(
                        t('Status'),
                        isBusinessPromotionLoading,
                      )}
                    </BusinessUserPromotionLabels>
                  </div>
                  <div>
                    <BusinessUserPromotionValues>
                      {withSkeletonLoading(
                        `${totalPointsCollected ?? 0} ${
                          totalPointsCollected === 1 ? 'Point' : 'Points'
                        }`,
                        isBusinessPromotionLoading,
                      )}
                    </BusinessUserPromotionValues>
                    <BusinessUserPromotionLabels>
                      {withSkeletonLoading(
                        t('Total Points Collected'),
                        isBusinessPromotionLoading,
                      )}
                    </BusinessUserPromotionLabels>
                  </div>
                  <div>
                    <BusinessUserPromotionValues>
                      {withSkeletonLoading(
                        `${totalRewardsClaimed ?? 0} ${
                          totalRewardsClaimed === 1 ? 'Reward' : 'Rewards'
                        }`,
                        isBusinessPromotionLoading,
                      )}
                    </BusinessUserPromotionValues>
                    <BusinessUserPromotionLabels>
                      {withSkeletonLoading(
                        t('Total Rewards Claimed'),
                        isBusinessPromotionLoading,
                      )}
                    </BusinessUserPromotionLabels>
                  </div>
                  <div>
                    <BusinessUserPromotionValues>
                      {withSkeletonLoading(
                        `${totalRewardsRedeemed ?? 0} ${
                          totalRewardsRedeemed === 1 ? 'Reward' : 'Rewards'
                        }`,
                        isBusinessPromotionLoading,
                      )}
                    </BusinessUserPromotionValues>
                    <BusinessUserPromotionLabels>
                      {withSkeletonLoading(
                        t('Total Rewards Redeemed'),
                        isBusinessPromotionLoading,
                      )}
                    </BusinessUserPromotionLabels>
                  </div>
                </PromotionDataWrapper>
              </BusinessUserPromotionCard>
            </NavLink>
          );
        })}
      </BusinessUserCardWrapper>
    </PromotionCardContainer>
  );
};

const PromotionCardContainer = styled.div`
  display: flex;
  justify-content: center;

  @media (min-width: ${defaultTheme.breakpoints.xl}) {
    justify-content: start;
  }

  * > :hover {
    text-decoration: none;
  }
`;

const BusinessUserCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;

  @media (min-width: ${defaultTheme.breakpoints.sm}) {
    max-width: ${pxToRem(460)};
  }
`;

const BusinessUserPromotionCard = styled.article`
  display: flex;
  color: ${({ theme }) => theme.textColor.black};
  flex-direction: column;
  gap: ${pxToRem(25)};
  border: ${pxToRem(2)} solid ${props => props.theme.inputs.border};

  border-radius: ${pxToRem(5)};
  padding: ${pxToRem(30)};

  :hover {
    border: ${pxToRem(2)} solid
      ${props => props.theme.textColor.userSelectionAlert};
    cursor: pointer;
  }
`;

const ImageAndDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;

  @media (min-width: ${defaultTheme.breakpoints.sm}) {
    display: grid;
    grid-template-columns: ${pxToRem(165)} 1fr;
    align-items: normal;
    gap: 1.75rem;
  }
`;

const BusinessDescription = styled.p`
  width: 100%;
  font-size: ${pxToRem(18)};
  line-height: 1.2;

  @media (min-width: ${defaultTheme.breakpoints.sm}) {
    font-size: ${pxToRem(16)};
    max-width: 30ch;
  }
`;

const PromotionDataWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;

  @media (min-width: ${defaultTheme.breakpoints.sm}) {
    display: grid;
    grid-template-columns: ${pxToRem(57)} ${pxToRem(77)} 1fr 1fr;
    column-gap: 1.5rem;
  }
`;

const PromotionImage = styled.img`
  height: auto;
  width: clamp(${pxToRem(200)}, 70%, ${pxToRem(500)});
  margin: 0 auto;

  @media (min-width: ${defaultTheme.breakpoints.sm}) {
    height: ${pxToRem(165)};
    width: ${pxToRem(165)};
  }
`;

const BusinessUserPromotionValues = styled.p`
  font-size: ${pxToRem(18)};
  font-weight: 500;
  text-transform: capitalize;
  margin-bottom: 0;

  @media (min-width: ${defaultTheme.breakpoints.sm}) {
    margin-bottom: ${pxToRem(5)};
  }
`;

const BusinessUserPromotionLabels = styled.p`
  font-size: ${pxToRem(14)};
  line-height: 1;
  margin-bottom: ${pxToRem(10)};

  @media (min-width: ${defaultTheme.breakpoints.sm}) {
    margin-bottom: 0;
  }
`;

const BusinessPromotionName = styled.h3`
  text-align: center;
  color: ${({ theme }) => theme.textColor.black};
  font-weight: 400;
  font-size: 1.75rem;

  @media only screen and (min-width: ${defaultTheme.breakpoints.sm}) {
    font-size: 1.5rem;
    text-align: left;
  }
`;
