import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { middlewareServiceApi } from 'src/api';
import { ApiData } from 'src/api/types';
import { Loading } from '../loading.type';

export interface EndUserPromotionEngagementState {
  status: Loading;
  endUserPromotionEngagement: ApiData.EndUserPromotionEngagement | null;
  error: AxiosError | null;
}

const initialState: EndUserPromotionEngagementState = {
  status: Loading.Idle,
  endUserPromotionEngagement: null,
  error: null,
};

export const createEndUserPromotionEngagement = createAsyncThunk(
  'endUserPromotionEngagement/createEndUserPromotionEngagement',
  async (
    args: {
      businessPromotionId: string;
      promotionPostId: string;
      engagementType: string;
      pointsCollected: number;
    },
    { rejectWithValue },
  ) => {
    try {
      const endUserPromotionEngagement =
        await middlewareServiceApi.endUserPromotionEngagement.createEndUserPromotionEngagement(
          args,
        );

      return endUserPromotionEngagement;
    } catch (error) {
      return rejectWithValue((error as AxiosError)?.response?.data);
    }
  },
);

export const endUserPromotionEngagementSlice = createSlice({
  name: 'endUserPromotionEngagement',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(createEndUserPromotionEngagement.pending, state => {
        state.status = Loading.Pending;
      })
      .addCase(
        createEndUserPromotionEngagement.fulfilled,
        (state, { payload }) => {
          state.status = Loading.Complete;
          state.endUserPromotionEngagement = payload;
        },
      )
      .addCase(
        createEndUserPromotionEngagement.rejected,
        (state, { payload }) => {
          state.error = payload as AxiosError;
          state.status = Loading.Failed;
        },
      );
  },
});
