export const capitalizeWords = (words: string) => {
  const splitWords = words.split(' ');

  const capitalizedWords = splitWords.map(
    word => word.charAt(0).toUpperCase() + word.slice(1),
  );

  return capitalizedWords.join(' ');
};

export const handlePromotionStatusSelectValue = (promotionStatus: string) => {
  if (promotionStatus === 'active') {
    return 'Live';
  }
  return promotionStatus.charAt(0).toUpperCase() + promotionStatus.slice(1);
};
