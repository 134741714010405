import styled from '@emotion/styled';
import { pxToRem } from 'casper-ui-kit';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ApiData, PromotionStatus } from 'src/api/types';
import { withSkeletonLoading } from 'src/components/loading';

export interface ParticipatingBusinessCardProps {
  readonly business: ApiData.BusinessUser;
  readonly isLoading: boolean;
}

export const ParticipatingBusinessesCard: React.FC<
  ParticipatingBusinessCardProps
> = ({ business, isLoading }) => {
  const { t } = useTranslation();

  const { businessName, businessDescription, businessLogo, promotions } =
    business;

  const livePromotions = (promotions as ApiData.BusinessPromotion[]).filter(
    promotion => promotion.status === PromotionStatus.Active,
  );

  const amountTotalLivePromotions = livePromotions.length;

  const amountOfUnclaimedRewards = livePromotions.reduce(
    (currentTotalUnclaimed, promotion) => {
      const { rewards, rewardQuantity } = promotion;
      const rewardsLeft = rewardQuantity - rewards.length;

      return currentTotalUnclaimed + rewardsLeft;
    },
    0,
  );

  return (
    <CardWrapper>
      {withSkeletonLoading(
        <CardTitle>
          <CardImage src={businessLogo} alt="business-image" />
          <CardHeading>{businessName}</CardHeading>
        </CardTitle>,
        isLoading,
        { height: '3rem', width: '60%' },
      )}

      <BusinessDescription>
        {withSkeletonLoading(businessDescription, isLoading, { width: '80%' })}
      </BusinessDescription>

      <DataWrapper>
        <ItemWrapper>
          <DataValue>
            {withSkeletonLoading(amountTotalLivePromotions, isLoading)}
          </DataValue>
          <DataLabel>
            {withSkeletonLoading(t('Number of Live Promotions'), isLoading)}
          </DataLabel>
        </ItemWrapper>
        <ItemWrapper>
          <DataValue>
            {withSkeletonLoading(amountOfUnclaimedRewards, isLoading)}
          </DataValue>
          <DataLabel>
            {withSkeletonLoading(
              t('Rewards Available to be Claimed'),
              isLoading,
            )}
          </DataLabel>
        </ItemWrapper>
      </DataWrapper>
    </CardWrapper>
  );
};

export const CardWrapper = styled.div<{ width?: number }>`
  display: inline-flex;
  flex-direction: column;
  padding: 1rem 1rem 1.5rem;
  border: ${pxToRem(2)} solid ${props => props.theme.inputs.border};
  border-radius: ${pxToRem(5)};
  height: fit-content;
  min-height: ${pxToRem(249)};
  width: ${({ width }) => pxToRem(width ?? 325)};
  margin-right: 2rem;
`;

const BusinessDescription = styled.p`
  margin-bottom: auto;
  font-size: ${pxToRem(14)};
  white-space: pre-wrap;
`;

const DataWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2rem;
`;

const DataValue = styled.div`
  font-weight: 500;
  font-size: ${pxToRem(18)};
`;

const DataLabel = styled.p`
  font-size: ${pxToRem(12)};
  white-space: pre-wrap;
`;

const CardImage = styled.img`
  border-radius: 50%;
  height: ${pxToRem(40)};
  width: ${pxToRem(40)};
  margin-right: 1rem;
`;

export const CardHeading = styled.h1`
  font-size: ${pxToRem(22)};
  font-weight: 400;
  white-space: pre-wrap;
  line-height: 1;
  margin-bottom: 1rem;
`;

export const CardTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

const ItemWrapper = styled.div``;
