import { AES, enc } from 'crypto-js';

export const encryptAes = (value: string, secretKey: string) => {
  const encryptedValue = AES.encrypt(value, secretKey).toString();

  if (!encryptedValue) {
    throw new Error('Could not encrypt value.');
  }

  return encryptedValue;
};

export const decryptAes = (value: string, secretKey: string) => {
  const bytes = AES.decrypt(value, secretKey);
  const decryptedValue = bytes.toString(enc.Utf8);

  if (!decryptedValue) {
    throw new Error('Could not encrypt value.');
  }

  return decryptedValue;
};

// searchParams automatically replaces '+' with ' '
export const parseSearchParams = (searchParam: string | null) => {
  if (searchParam) {
    return searchParam.replaceAll(' ', '+');
  }

  return null;
};
