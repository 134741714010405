import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  clearBusinessPromotions,
  fetchBusinessPromotions,
  resetBusinessPromotionsLoadingStatus,
  useAppDispatch,
} from 'src/store';
import { defaultTheme, pxToRem } from 'casper-ui-kit';
import styled from '@emotion/styled';
import { useForm } from 'react-hook-form';
import { useDebounce } from 'use-debounce';
import { LivePromotionCards } from 'src/components/cards';
import {
  InputErrorMessageWrapper,
  OptionTitle,
  StyledErrorMessage,
} from '../../../pages/SharedStyles/pages.styled';
import { StyledCheckbox } from '../../create-promotion/steps/Partials/CreatePromotionCheckboxes/CreatePromotionCheckboxes.styled';

export interface LivePromotionsForm {
  promotionName: string;
  myPromotionsCheckBox: boolean;
}

export const LivePromotions: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const {
    register,
    formState: { errors },
    watch,
  } = useForm<LivePromotionsForm>();

  const promotionNameSearch = watch('promotionName') ?? '';
  const myPromotionsCheckbox = watch('myPromotionsCheckBox') ?? false;

  const [debouncedPromotionNameSearch] = useDebounce(promotionNameSearch, 500);

  useEffect(() => {
    dispatch(
      fetchBusinessPromotions({
        search: { promotionName: debouncedPromotionNameSearch },
        filters: {
          myPromotions: myPromotionsCheckbox,
        },
      }),
    );
  }, [dispatch, debouncedPromotionNameSearch, myPromotionsCheckbox]);

  useEffect(() => {
    return () => {
      dispatch(clearBusinessPromotions());
      dispatch(resetBusinessPromotionsLoadingStatus());
    };
  }, [dispatch]);

  return (
    <div>
      <OptionTitle>{t('Live Promotions')}</OptionTitle>
      <form>
        <FormComponentsWrapper>
          <InputErrorMessageWrapper>
            <InputWrapper>
              <StyledLabel htmlFor="promotion-name">
                {t('Limit results by:')}
              </StyledLabel>
              <StyledInput
                id="promotion-name"
                placeholder="Promotion name"
                {...register('promotionName', {
                  required: true,
                })}
              />
            </InputWrapper>
            {errors.promotionName && (
              <StyledErrorMessage>
                {t('Please enter a valid promotion name.')}
              </StyledErrorMessage>
            )}
          </InputErrorMessageWrapper>

          <CheckboxWrapper>
            <StyledCheckBoxLabel>
              <StyledCheckbox
                type="checkbox"
                {...register('myPromotionsCheckBox', {
                  required: false,
                })}
              />
              My promotions
            </StyledCheckBoxLabel>
          </CheckboxWrapper>
        </FormComponentsWrapper>
      </form>
      <LivePromotionCards hasCheckedMyPromotionsFilter={myPromotionsCheckbox} />
    </div>
  );
};

const StyledInput = styled.input`
  color: ${props => props.theme.inputs.textColor};
  font-weight: initial;
  width: 100%;
  font-size: ${pxToRem(12)};
  border-radius: ${pxToRem(6)};
  border: solid ${pxToRem(2)} ${props => props.theme.inputs.border};
  padding: 0.25rem 1rem;
  max-width: ${pxToRem(300)};

  @media only screen and (min-width: ${defaultTheme.breakpoints.sm}) {
    font-size: 1rem;
    padding: 0.35rem 1rem;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  white-space: nowrap;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
`;

const StyledLabel = styled.label`
  font-weight: 400;
  font-size: ${pxToRem(12)};
  padding: 0;

  @media only screen and (min-width: ${defaultTheme.breakpoints.sm}) {
    font-size: 1rem;
  }
`;

const StyledCheckBoxLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  font-size: ${pxToRem(12)};

  @media only screen and (min-width: ${defaultTheme.breakpoints.sm}) {
    font-size: 1rem;
  }
`;

const FormComponentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin: 2rem 0;
  width: 100%;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  height: 100%;
`;
