import styled from '@emotion/styled';
import { defaultTheme, pxToRem } from 'casper-ui-kit';
import { t } from 'i18next';
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { PageWrapper } from 'src/components/layout/PageWrapper';
import { clRewardsTheme } from 'src/styled-theme';
import {
  Loading,
  setBusinessUserTermsAndConditions,
  useAppDispatch,
  useAppSelector,
} from 'src/store';
import {
  getBusinessUser,
  getBusinessUserLoadingStatus,
} from 'src/store/selectors/business-user-selectors';
import { ApiData } from 'src/api/types';
import { useNavigate } from 'react-router-dom';
import { ButtonWithLoading } from 'src/components/buttons/ButtonWithLoading';
import { PoliciesText } from 'src/components';
import checkmarkIcon from '../assets/icons/checkmark-icon.svg';

interface TermsAndConditionsFormValues {
  isChecked: boolean;
}

export const TermsAndConditions: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const businessUser = useAppSelector(getBusinessUser);
  const businessUserLoadingStatus = useAppSelector(
    getBusinessUserLoadingStatus,
  );

  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors },
    watch,
  } = useForm<TermsAndConditionsFormValues>();

  const onSubmit: SubmitHandler<TermsAndConditionsFormValues> = async () => {
    clearErrors('isChecked');

    if (businessUser?._id) {
      const { payload } = (await dispatch(
        setBusinessUserTermsAndConditions({
          businessUserId: businessUser._id,
          termsAndConditions: new Date().toISOString(),
        }),
      )) as { payload: ApiData.BusinessUser };

      if (payload.createdProfile) {
        navigate('/business-user/');
      } else {
        navigate('/business-user/create-profile');
      }
    } else {
      navigate('/business-user/page-error', {
        state: { unauthorizedError: true },
      });
    }
  };

  const isChecked = watch('isChecked');

  const isSettingTermsAndConditions =
    businessUserLoadingStatus === Loading.Pending;

  return (
    <PageWrapper>
      <BusinessUserLoginPageComponents onSubmit={handleSubmit(onSubmit)}>
        <StyledWelcomeHeading>
          {t('Welcome to Casper Rewards!')}
        </StyledWelcomeHeading>
        <WelcomeText>
          {t('Please accept our terms and conditions to use Casper Rewards.')}
        </WelcomeText>

        <TermsAndConditionsErrorMessageWrapper>
          <TermsAndConditionsWrapper>
            <PoliciesText />
          </TermsAndConditionsWrapper>
          <TermsAndConditionsWrapper>
            <TermsAndConditionsCheckbox
              type="checkbox"
              {...register('isChecked', {
                required: true,
              })}
            />
            <TermsAndConditionsHeading>
              {t('I agree to the terms and conditions')}
            </TermsAndConditionsHeading>
          </TermsAndConditionsWrapper>
          {errors.isChecked && (
            <TermsAndConditionsErrorMessage>
              {t('Please accept the terms and conditions.')}
            </TermsAndConditionsErrorMessage>
          )}

          <AcceptTermsAndConditionsButton
            data-testid="accept-terms-and-conditions-button"
            type="submit"
            disabled={!isChecked || isSettingTermsAndConditions}
            isLoading={isSettingTermsAndConditions}>
            Continue
          </AcceptTermsAndConditionsButton>
        </TermsAndConditionsErrorMessageWrapper>
      </BusinessUserLoginPageComponents>
    </PageWrapper>
  );
};

const BusinessUserLoginPageComponents = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
`;

const StyledWelcomeHeading = styled.h1`
  font-size: ${pxToRem(60)};
  font-weight: 400;
  line-height: 1;
  margin: ${pxToRem(105)} 0 ${pxToRem(45)} 0;
  text-align: center;
`;

const WelcomeText = styled.p`
  font-size: ${pxToRem(18)};
  color: ${props => props.theme.textColor.userAgreementTermsText};
  text-align: center;
`;

const TermsAndConditionsHeading = styled.h3`
  display: flex;
  font-size: ${pxToRem(14)};
  font-weight: 400;
  line-height: 1;
  text-align: center;
`;

const TermsAndConditionsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${pxToRem(8)};
  margin-top: 1.5rem;
`;

export const TermsAndConditionsErrorMessageWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const TermsAndConditionsErrorMessage = styled.p`
  position: absolute;
  white-space: nowrap;
  text-align: center;
  width: 100%;
  color: ${props => props.theme.textColor.inputErrorMessage};
`;

export const TermsAndConditionsCheckbox = styled.input`
  background-color: ${props => props.theme.inputs.bgColor};
  width: 1.6rem;
  height: 1.6rem;
  margin: 0 0.5rem 0 0;
  border: solid ${pxToRem(2)} ${props => props.theme.inputs.border};
  border-radius: ${pxToRem(6)};
  -webkit-appearance: none;
  appearance: none;

  :checked {
    background-image: url(${checkmarkIcon});
  }
`;

export const InstagramHandleWrapper = styled.div`
  display: flex;
  justify-content: center;

  width: 100%;
  margin: 2rem 0 1.5rem;
`;

const AcceptTermsAndConditionsButton = styled(ButtonWithLoading)`
  width: 100%;
  font-weight: ${defaultTheme.typography.fontWeights.medium};
  height: ${pxToRem(49)};
  margin-top: 2.5rem;
  color: ${clRewardsTheme.buttons.textColor.black};
  background-color: ${clRewardsTheme.buttons.bgColor.casperGreen};
  width: ${pxToRem(315)};
  text-align: center;

  &:disabled {
    background-color: ${clRewardsTheme.buttons.bgColor.disabled};
  }
`;
