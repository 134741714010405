import React, { useEffect } from 'react';
import {
  Loading,
  clearEndUserRedeemedRewards,
  fetchRedeemedEndUserReward,
  resetEndUserRedeemedRewardsLoadingStatus,
  useAppDispatch,
  useAppSelector,
} from 'src/store';
import {
  getEndUserRedeemedRewardsLoadingStatus,
  getRedeemedEndUserRewards,
} from 'src/store/selectors/end-user-selectors';
import styled from '@emotion/styled';
import { pxToRem } from 'casper-ui-kit';
import { useTranslation } from 'react-i18next';
import { getSkeletonLoadingData } from 'src/components/loading';
import { skeletonData } from 'src/mock-data/skeleton-loading';
import { CardContainer } from '../../cards/cards.styled';
import { CardContentsWrapper } from './ParticipatingBusinessesTab';
import { RedeemedRewardCard } from './RedeemedRewardCard';

export const RedeemedRewardCards: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const endUserRedeemedRewards = useAppSelector(getRedeemedEndUserRewards);
  const endUserRedeemedRewardLoadingStatus = useAppSelector(
    getEndUserRedeemedRewardsLoadingStatus,
  );

  useEffect(() => {
    dispatch(fetchRedeemedEndUserReward());

    return () => {
      dispatch(clearEndUserRedeemedRewards());
      dispatch(resetEndUserRedeemedRewardsLoadingStatus());
    };
  }, [dispatch]);

  const isEndUserRedeemedRewardsLoading =
    endUserRedeemedRewardLoadingStatus !== Loading.Complete;

  const getReedemedRewardCardsWithSkeletonLoading = () => {
    if (
      !endUserRedeemedRewards?.length &&
      endUserRedeemedRewardLoadingStatus === Loading.Complete
    ) {
      return (
        <CardContainer>
          <NoResultsText>{t('No redeemed rewards.')}</NoResultsText>
        </CardContainer>
      );
    }

    return (
      endUserRedeemedRewards ??
      getSkeletonLoadingData(skeletonData.endUserPromotionReward, 8)
    ).map(endUserPromotionReward => {
      const {
        _id: uniqueIdHash,
        businessPromotionId: businessPromotion,
        redeemedAt,
        createdAt,
      } = endUserPromotionReward;

      if (typeof businessPromotion === 'string' || !businessPromotion) {
        return null;
      }

      return (
        <RedeemedRewardCard
          key={uniqueIdHash}
          rewardRedemptionDate={redeemedAt}
          promotionName={businessPromotion.promotionName}
          promotionImage={businessPromotion.promotionImage}
          promotionCaption={businessPromotion.promotionCaption}
          promotionHashtag={businessPromotion.promotionHashtag}
          pointsForRedemption={businessPromotion.pointsForRedemption}
          rewardClaimDate={createdAt}
          isLoading={isEndUserRedeemedRewardsLoading}
        />
      );
    });
  };

  return (
    <CardContentsWrapper>
      {getReedemedRewardCardsWithSkeletonLoading()}
    </CardContentsWrapper>
  );
};

const NoResultsText = styled.p`
  font-size: ${pxToRem(18)};
`;
