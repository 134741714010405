import React from 'react';
import { t } from 'i18next';
import {
  CheckboxContainer,
  DaysErrorMessage,
  DaysErrorMessageWrapper,
  StyledCheckbox,
  StyledLabel,
} from './CreatePromotionCheckboxes.styled';
import { CreatePromotionCheckboxProps } from './create-promotion-checkboxes.types';
import { CreateBusinessPromotion3Form } from '../../create-promotion-form-types';

export const weekdays = [
  {
    label: `${t('Sunday')}`,
    key: 'sunday',
    value: '0',
  },
  {
    label: `${t('Monday')}`,
    key: 'monday',
    value: '1',
  },
  {
    label: `${t('Tuesday')}`,
    key: 'tuesday',
    value: '2',
  },
  {
    label: `${t('Wednesday')}`,
    key: 'wednesday',
    value: '3',
  },
  {
    label: `${t('Thursday')}`,
    key: 'thursday',
    value: '4',
  },
  {
    label: `${t('Friday')}`,
    key: 'friday',
    value: '5',
  },
  {
    label: `${t('Saturday')}`,
    key: 'saturday',
    value: '6',
  },
];

export const CreatePromotionDayCheckboxes: React.FC<
  CreatePromotionCheckboxProps<CreateBusinessPromotion3Form>
> = ({ register, errors, selectedValues }) => {
  return (
    <DaysErrorMessageWrapper>
      <CheckboxContainer>
        {weekdays.map(({ label, key, value }) => {
          return (
            <StyledLabel key={key}>
              <StyledCheckbox
                type="checkbox"
                value={value}
                defaultChecked={selectedValues.includes(label)}
                {...register('promotionDays', {
                  required: true,
                })}
              />
              {label}
            </StyledLabel>
          );
        })}
      </CheckboxContainer>
      {errors.promotionDays && (
        <DaysErrorMessage>
          {t('Please select at least one day.')}
        </DaysErrorMessage>
      )}
    </DaysErrorMessageWrapper>
  );
};
