import React, { useEffect, useState } from 'react';
import {
  fetchPromotionToManage,
  getPromotionStatus,
  getPromotionToManage,
  updatePromotionStatus,
  useAppDispatch,
  useAppSelector,
} from 'src/store';
import styled from '@emotion/styled';
import { defaultTheme, pxToRem } from 'casper-ui-kit';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { format } from 'date-fns';
import { PromotionDays, PromotionStatus } from 'src/api/types';
import { ManagePromotionValues } from './mange-promotion.types';
import { StatusSelect } from './Partials';

const DATE_FORMAT = 'M/dd/yy';

const determineEngagementLevel = (
  pointsForEngagement: number,
  pointsForRedemption: number,
) => {
  const engagementsRequiredForRedemption =
    pointsForRedemption / pointsForEngagement;

  switch (engagementsRequiredForRedemption) {
    case 1:
      return 'Light';
    case 5:
      return 'Moderate';
    case 10:
      return 'Deep';
    default:
      return 'Custom';
  }
};

export const ManagePromotionDetails: React.FC = () => {
  const { id: promotionId } = useParams();
  const { control, handleSubmit, reset } = useForm<ManagePromotionValues>();
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const promotionToManage = useAppSelector(getPromotionToManage);
  const promotionStatus = useAppSelector(getPromotionStatus);

  const [dynamicSelectDefaultValue, setDynamicDefaultValue] = useState('');

  const [modalStatusSelectionText, setModalStatusSelectionText] =
    useState<PromotionStatus | null>(promotionStatus);

  useEffect(() => {
    if (promotionStatus) {
      setDynamicDefaultValue(promotionStatus);
    }
  }, [promotionStatus]);

  useEffect(() => {
    if (promotionId) {
      dispatch(fetchPromotionToManage(promotionId));
    }
  }, [dispatch, promotionId]);

  if (!promotionToManage || !promotionId) {
    return <div>Promotion details failed to load.</div>;
  }

  const {
    promotionName,
    promotionImage,
    rewardQuantity,
    rewardExpiry,
    pointsForEngagement,
    pointsForRedemption,
    promotionStartDate,
    promotionCaption,
    promotionEndDate,
    promotionDays: promotionDaysRaw,
    promotionHashtag,
    totalPointsCollected,
    totalRewardsClaimed,
    totalRewardsRedeemed,
  } = promotionToManage;

  const engagementLevel = determineEngagementLevel(
    pointsForEngagement,
    pointsForRedemption,
  );

  const timeline = `${format(
    new Date(promotionStartDate),
    DATE_FORMAT,
  )} to ${format(new Date(promotionEndDate), DATE_FORMAT)}`;

  const formattedRewardExpiry = `Expires ${format(
    new Date(rewardExpiry),
    DATE_FORMAT,
  )}`;

  const promotionDays = promotionDaysRaw.map(day => PromotionDays[day]);

  const promotionDaysFormatted = (promotionDays || []).join(', ');

  const onSubmit: SubmitHandler<ManagePromotionValues> = async data => {
    const updatedStatus = data.promotionStatusSelect as PromotionStatus;
    const args = {
      promotionId,
      updatedStatus,
    };
    dispatch(updatePromotionStatus(args));
  };

  const promotionDetails = [
    {
      label: t('Status'),
      detail: (
        <StatusSelect
          promotionStatus={promotionStatus}
          modalStatusSelectionText={modalStatusSelectionText}
          setModalStatusSelectionText={setModalStatusSelectionText}
          control={control}
          dynamicSelectDefaultValue={dynamicSelectDefaultValue}
          reset={reset}
        />
      ),
      key: 'status',
    },
    {
      label: t('Total Points Collected'),
      detail:
        typeof totalPointsCollected === 'number'
          ? totalPointsCollected
          : t('Unknown'),
      key: 'totalPointsCollected',
    },
    {
      label: t('Total Rewards Claimed'),
      detail:
        typeof totalRewardsClaimed === 'number'
          ? totalRewardsClaimed
          : t('Unknown'),
      key: 'totalRewardsClaimed',
    },
    {
      label: t('Total Rewards Redeemed'),
      detail:
        typeof totalRewardsRedeemed === 'number'
          ? totalRewardsRedeemed
          : t('Unknown'),
      key: 'totalRewardsRedeemed',
    },
    {
      label: t('Remaining Quantity'),
      detail: rewardQuantity,
      key: 'totalPromotionalItems',
    },
    {
      label: t('Points Rewarded per Interaction'),
      detail: pointsForEngagement,
      key: 'pointsRewardedPerInteraction',
    },
    {
      label: t('Points Required for Reward Redemption'),
      detail: pointsForRedemption,
      key: 'pointsRequiredForRedemption',
    },
    {
      label: t('Engagement Level'),
      detail: engagementLevel,
      key: 'engagementLevel',
    },
    {
      label: t('Hashtag'),
      detail: `#${promotionHashtag}`,
      key: 'hashtag',
    },
    {
      label: t('Timeline'),
      detail: timeline,
      key: 'timeline',
    },
    {
      label: t('Reward Expiry Date'),
      detail: formattedRewardExpiry,
      key: 'rewardExpiry',
    },
    {
      label: t('Promotion Frequency'),
      detail: promotionDaysFormatted,
      key: 'promotionFrequency',
    },
  ];

  return (
    <PromotionDetailsContainer>
      <PromotionName>{promotionName}</PromotionName>
      <ManagePromotionContainer>
        <ImageAndDescriptionWrapper>
          <PromotionImageWrapper>
            <PromotionImage src={promotionImage} />
          </PromotionImageWrapper>
          <PromotionDescriptionWrapper>
            <PromotionDescriptionHeading>
              {t('Promotion Description')}
            </PromotionDescriptionHeading>
            <PromotionDescription>{promotionCaption}</PromotionDescription>
          </PromotionDescriptionWrapper>
        </ImageAndDescriptionWrapper>
        <DetailsListWrapper>
          {promotionDetails.map(detail => (
            <ManagePromotionWrapper key={detail.key}>
              {detail.key === 'status' ? (
                <StyledForm onSubmit={handleSubmit(onSubmit)}>
                  <ManagePromotionLabel>{detail.label}</ManagePromotionLabel>
                  {detail.detail}
                </StyledForm>
              ) : (
                <>
                  <ManagePromotionLabel>{detail.label}</ManagePromotionLabel>
                  <ManagePromotionDetail>{detail.detail}</ManagePromotionDetail>
                </>
              )}
            </ManagePromotionWrapper>
          ))}
        </DetailsListWrapper>
      </ManagePromotionContainer>
    </PromotionDetailsContainer>
  );
};

const PromotionDetailsContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const ManagePromotionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;

  @media only screen and (min-width: ${defaultTheme.breakpoints.sm}) {
    display: grid;
    grid-template-columns: 1fr 3fr;
  }
`;

const PromotionImageWrapper = styled.div`
  width: 100%;
  height: auto;
  aspect-ratio: 1;
  overflow: hidden;
  display: grid;
  place-items: center;
  position: relative;
`;

const PromotionImage = styled.img`
  position: absolute;
  height: auto;
  width: 100%;
`;

const ImageAndDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const PromotionDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: ${defaultTheme.breakpoints.xxl}) {
    align-items: flex-start;
  }
`;

const PromotionDescriptionHeading = styled.h2`
  font-weight: 500;
  font-size: ${pxToRem(20)};
  width: 100%;
  margin-bottom: 1rem;
  padding-bottom: 0;
`;

const PromotionDescription = styled.p`
  font-size: ${pxToRem(18)};
  font-weight: 400;
  width: 100%;
`;

const DetailsListWrapper = styled.div`
  width: 100%;
  display: grid;
  gap: 2rem;
  grid-template-columns: 1fr;

  @media only screen and (min-width: ${defaultTheme.breakpoints.xmd}) {
    grid-template-columns: 1fr 1fr;
  }

  @media only screen and (min-width: ${defaultTheme.breakpoints.xl}) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const ManagePromotionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media only screen and (min-width: ${defaultTheme.breakpoints.sm}) {
    margin: 0 auto;
  }

  @media only screen and (min-width: ${defaultTheme.breakpoints.xl}) {
    margin: unset;
  }
`;

const PromotionName = styled.h2`
  color: black;
  font-size: 2rem;
  font-weight: 400;
  width: 100%;
  margin-bottom: 2rem;
`;

const ManagePromotionLabel = styled.p`
  font-size: ${pxToRem(18)};
  white-space: nowrap;
`;

const ManagePromotionDetail = styled.p`
  font-weight: 500;
  font-size: ${pxToRem(20)};
`;

const StyledForm = styled.form`
  height: 3.25rem;
`;
