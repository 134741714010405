import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import { addDays } from 'date-fns';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { CalendarIcon } from 'src/components/icons';
import { t } from 'i18next';
import { StyledErrorMessage } from '../../CreatePromotion.styled';
import {
  DatePickerCalendarWrapper,
  DatePickerStylesWrapper,
  StartEndDateWrapper,
  StyledLabel,
} from './CreatePromotionDatePicker.styled';
import { CreatePromotionStartEndDatePickerProps } from './create-promotion-date-picker.types';
import { CreateBusinessPromotion3Form } from '../../create-promotion-form-types';

export const CreatePromotionStartEndDates: React.FC<
  CreatePromotionStartEndDatePickerProps<CreateBusinessPromotion3Form>
> = ({
  control,
  errors,
  promotionStartDate,
  promotionEndDate,
  clearErrors,
}) => {
  const [selectedStartDate, setSelectedStartDate] =
    useState(promotionStartDate);
  const [selectedEndDate, setSelectedEndDate] = useState(promotionEndDate);

  const getMinimumEndDate = () => {
    if (selectedStartDate) {
      const minEndDate = addDays(selectedStartDate, 1);

      return minEndDate;
    }
  };

  const getMaximumStartDate = () => {
    if (selectedEndDate) {
      const maxStartDate = addDays(selectedEndDate, -1);

      return maxStartDate;
    }
  };

  return (
    <StartEndDateWrapper>
      <div>
        <StyledLabel htmlFor="start-date">{t('Start date:')}</StyledLabel>
        <Controller
          control={control}
          name="promotionStartDate"
          render={ref => {
            const changeHandler = (date: Date) => {
              ref.field.onChange(date);
              setSelectedStartDate(date);
              clearErrors('promotionStartDate');
            };

            const selected = ref.field.value
              ? new Date(Number(ref.field.value))
              : undefined;

            return (
              <DatePickerStylesWrapper>
                <DatePicker
                  wrapperClassName="datePicker"
                  selected={promotionStartDate ?? selected}
                  onChange={changeHandler}
                  minDate={new Date()}
                  maxDate={getMaximumStartDate()}
                  placeholderText="MM/DD/YYYY"
                  dateFormat="MM/dd/yyyy"
                  id="start-date"
                />
                <DatePickerCalendarWrapper htmlFor="start-date">
                  <CalendarIcon />
                </DatePickerCalendarWrapper>
              </DatePickerStylesWrapper>
            );
          }}
          rules={{ required: true }}
        />
        {errors.promotionStartDate && (
          <StyledErrorMessage>
            {t('Please select a start date.')}
          </StyledErrorMessage>
        )}
      </div>
      <div>
        <StyledLabel>{t('End date:')}</StyledLabel>
        <Controller
          control={control}
          name="promotionEndDate"
          render={ref => {
            const changeHandler = (date: Date) => {
              ref.field.onChange(date);
              setSelectedEndDate(date);
              clearErrors('promotionEndDate');
            };

            const selected = ref.field.value
              ? new Date(Number(ref.field.value))
              : undefined;

            return (
              <DatePickerStylesWrapper>
                <DatePicker
                  wrapperClassName="datePicker"
                  selected={promotionEndDate ?? selected}
                  onChange={changeHandler}
                  minDate={getMinimumEndDate() ?? addDays(new Date(), 1)}
                  placeholderText="MM/DD/YYYY"
                  dateFormat="MM/dd/yyyy"
                  id="end-date"
                />
                <DatePickerCalendarWrapper htmlFor="end-date">
                  <CalendarIcon />
                </DatePickerCalendarWrapper>
              </DatePickerStylesWrapper>
            );
          }}
          rules={{ required: true }}
        />
        {errors.promotionEndDate && (
          <StyledErrorMessage>
            {t('Please select an end date.')}
          </StyledErrorMessage>
        )}
      </div>
    </StartEndDateWrapper>
  );
};
