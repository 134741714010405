import styled from '@emotion/styled';
import { pxToRem } from 'casper-ui-kit';
import checkmarkIcon from '../../../../../assets/icons/checkmark-icon.svg';

export const CheckboxContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  margin-top: 0.85rem;
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
`;

export const StyledLabel = styled.label`
  display: flex;
  font-size: ${pxToRem(18)};
`;

export const StyledCheckbox = styled.input`
  background-color: ${props => props.theme.inputs.bgColor};
  width: 1.6rem;
  height: 1.6rem;
  border: solid ${pxToRem(2)} ${props => props.theme.inputs.border};
  border-radius: ${pxToRem(6)};
  -webkit-appearance: none;
  appearance: none;

  :checked {
    background-image: url(${checkmarkIcon});
  }
`;

export const DaysErrorMessageWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 55rem;
`;

export const DaysErrorMessage = styled.p`
  color: red;
  position: absolute;
  left: 0;
  right: 0;
`;
