import styled from '@emotion/styled';
import { pxToRem } from 'casper-ui-kit';

export const FormComponentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
  margin: 3.4rem 0 4.5rem 0;
  width: 100%;
`;

export const PromptWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;

export const SubPromptWrapper = styled.div`
  line-height: 1;
`;

export const Prompt = styled.p`
  font-weight: 500;
  font-size: ${pxToRem(20)};
`;

export const PlatformLabel = styled.span`
  font-size: 2rem;
  padding-bottom: 2rem;
`;

export const StyledErrorMessage = styled.p`
  color: ${props => props.theme.textColor.inputErrorMessage};
  position: absolute;
  line-height: 1.1;
  margin-top: 0.25rem;
`;

export const InputErrorMessageWrapper = styled.div`
  position: relative;
`;

export const StepHeading = styled.span`
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 0;
`;

export const StepSubHeading = styled.span`
  font-size: 1.25rem;
  margin-top: 0;
`;

export const StyledInput = styled.input`
  color: ${props => props.theme.inputs.textColor};
  font-weight: initial;
  width: ${pxToRem(140)};
  height: ${pxToRem(45)};
  font-size: ${pxToRem(18)};
  border-radius: ${pxToRem(6)};
  border: solid ${pxToRem(2)} ${props => props.theme.inputs.border};
  padding-left: 1.2rem;
  margin-top: 0.4rem;
`;

export const PromotionDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 3.4rem 0 4.5rem 0;
`;
