import styled from '@emotion/styled';
import { defaultTheme } from 'casper-ui-kit';
import { t } from 'i18next';
import React from 'react';
import { PageWrapper } from 'src/components/layout/PageWrapper';

export const Reports: React.FC = () => {
  return (
    <PageWrapper>
      <TitleWrapper>
        <TitleText>{t('Reports')}</TitleText>
      </TitleWrapper>
    </PageWrapper>
  );
};

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
  flex-wrap: wrap;
  margin: 2rem 0;

  @media (min-width: ${defaultTheme.breakpoints.md}) {
    margin-top: 0;
  }

  @media (min-width: ${defaultTheme.breakpoints.lg}) {
    flex-wrap: nowrap;
  }
`;

const TitleText = styled.h1`
  font-size: 2.5rem;
  white-space: nowrap;
  font-weight: 400;
`;
