import { RootState } from '../store';

export const getEndUserPromotionReward = (state: RootState) => {
  return state.endUserPromotionReward.endUserPromotionReward;
};

export const getEndUserPromotionRewardLoadingStatus = (state: RootState) => {
  return state.endUserPromotionReward.status;
};

export const getEndUserPromotionRewardRedeemLoadingStatus = (
  state: RootState,
) => {
  return state.endUserPromotionReward.redeemRewardStatus;
};
