import styled from '@emotion/styled';
import { Icon, defaultTheme, pxToRem } from 'casper-ui-kit';
import React, { useEffect, useState } from 'react';
import { Plus } from 'src/components/icons';
import { PageWrapper } from 'src/components/layout/PageWrapper';
import { useTranslation } from 'react-i18next';
import { CharacterCappedTextArea } from 'src/components/inputs/CharacterCappedTextArea/CharacterCappedTextArea';
import { SubmitHandler, useForm } from 'react-hook-form';
import { clRewardsTheme } from 'src/styled-theme';
import {
  Loading,
  createBusinessUserProfile,
  useAppDispatch,
  useAppSelector,
} from 'src/store';
import { useNavigate } from 'react-router-dom';
import { ApiData } from 'src/api/types';
import {
  getBusinessUser,
  getBusinessUserLoadingStatus,
} from 'src/store/selectors/business-user-selectors';
import { ButtonWithLoading } from 'src/components/buttons/ButtonWithLoading';
import { middlewareServiceApi } from 'src/api';
import {
  StyledErrorMessage,
  FileDetails,
  FileInputLabel,
  FileInputWrapper,
  FormComponentsWrapper,
  PromptWrapper,
  RemoveFileButton,
  TitleText,
  PromptHeadingH3,
  InputErrorMessageWrapper,
} from './SharedStyles/pages.styled';

export interface ManageProfileFormValues {
  businessLogo: File;
  businessName: string;
  businessDescription: string;
  defaultPromotionTerms?: string;
}

export const ManageProfile: React.FC = () => {
  const businessUser = useAppSelector(getBusinessUser);

  const [previouslyUploadedBusinessLogo, setPreviouslyUploadedBusinessLogo] =
    useState(businessUser?.businessLogo);

  const [uploadImage, setUploadImage] = useState<File | undefined>();
  const [uploadImageURL, setUploadImageURL] = useState('');
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm<ManageProfileFormValues>();

  const businessUserLoadingStatus = useAppSelector(
    getBusinessUserLoadingStatus,
  );

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!previouslyUploadedBusinessLogo && businessUser?.businessLogo) {
      setPreviouslyUploadedBusinessLogo(businessUser.businessLogo);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessUser]);

  const onSubmit: SubmitHandler<ManageProfileFormValues> = async data => {
    clearErrors();

    const {
      businessName,
      businessDescription,
      defaultPromotionTerms,
      businessLogo,
    } = data;

    const businessLogoUrl = await middlewareServiceApi.fileUpload.uploadFile(
      businessLogo,
    );

    if (businessUser?._id) {
      const { payload } = (await dispatch(
        createBusinessUserProfile({
          businessUserId: businessUser._id,
          createProfile: {
            businessName,
            businessDescription,
            businessLogo: businessLogoUrl,
            defaultPromotionTerms,
            createdProfile: new Date().toISOString(),
          },
        }),
      )) as { payload: ApiData.BusinessUser };

      if (payload.createdProfile) {
        navigate('/business-user/dashboard');
      }
    } else {
      navigate('/business-user/page-error', {
        state: { unauthorizedError: true },
      });
    }
  };

  const clearImageUpload = () => {
    setUploadImage(undefined);
    setUploadImageURL('');
  };

  const { onChange: fileUploadOnChange, ...fileUploadRegisterProps } = register(
    'businessLogo',
    {
      required: true,
    },
  );

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.length) {
      const [currentUploadImage] = event.target.files;
      setUploadImage(currentUploadImage);
      setUploadImageURL(URL.createObjectURL(currentUploadImage));
      fileUploadOnChange({
        target: { value: currentUploadImage, name: 'businessLogo' },
      });
    }
  };

  const isCreatingProfile = businessUserLoadingStatus === Loading.Pending;

  const hasPreviouslyCreatedProfile = businessUser?.createdProfile;

  return (
    <PageWrapper>
      <ManageProfileWrapper>
        {hasPreviouslyCreatedProfile ? (
          <TitleText>{t('Edit Profile')}</TitleText>
        ) : (
          <>
            <TitleText>{t('Using the Platform')}</TitleText>
            <div>
              <StyledHeading>
                {t('As a business owner, you can use Casper Rewards to:')}
              </StyledHeading>
              <PlatformUseCaseList>
                <BulletText>
                  <PlatformUseCaseItem>
                    {t('Create campaigns: ')}
                  </PlatformUseCaseItem>
                  {t(
                    'start a promotional campaign for your business to engage with customers using Facebook, Instagram, or WhatsApp. Manage everything for your campaign within Casper Rewards.',
                  )}
                </BulletText>
                <BulletText>
                  <PlatformUseCaseItem>
                    {t('View reports: ')}
                  </PlatformUseCaseItem>
                  {t(
                    ' Casper Rewards provides real-time analytics for your campaigns.',
                  )}
                </BulletText>
              </PlatformUseCaseList>
            </div>
            <TitleText>{t('Complete Profile')}</TitleText>
          </>
        )}
        <ManageProfileForm onSubmit={handleSubmit(onSubmit)}>
          <PromptWrapper>
            <PromptWrapper>
              <PromptHeadingH3>{t('Add your business name')}</PromptHeadingH3>
            </PromptWrapper>
            <InputErrorMessageWrapper>
              <StyledTextInput
                data-testid="title-input"
                placeholder="Business Name"
                defaultValue={businessUser?.businessName}
                {...register('businessName', {
                  required: true,
                })}
              />
              {errors.businessName && (
                <StyledErrorMessage>
                  {t('Please include your business name.')}
                </StyledErrorMessage>
              )}
            </InputErrorMessageWrapper>
          </PromptWrapper>
          <PromptWrapper>
            <PromptHeadingH3>
              {t('Upload your business logo.*')}
            </PromptHeadingH3>
            <StyledHeading>
              <RequiredImagePrompt>
                {t(
                  '*Required. Recommended sizes are 1200x630px (Facebook) and 1080x1080 (Instagram).',
                )}
              </RequiredImagePrompt>
            </StyledHeading>
            <InputErrorMessageWrapper>
              <FileInputWrapper>
                <FileInputLabel
                  img={previouslyUploadedBusinessLogo ?? uploadImageURL}>
                  {!uploadImage && !previouslyUploadedBusinessLogo && <Plus />}
                  <FileUploadInput
                    {...fileUploadRegisterProps}
                    type="file"
                    accept="image/*"
                    onChange={event => handleImageChange(event)}
                  />
                </FileInputLabel>
                {uploadImage && (
                  <FileDetails>
                    <span>{uploadImage.name}</span>
                    <RemoveFileButton
                      type="button"
                      onClick={() => clearImageUpload()}>
                      {<Icon width={16} height={16} icon="FailureIcon" />}
                      Remove
                    </RemoveFileButton>
                  </FileDetails>
                )}

                {previouslyUploadedBusinessLogo && (
                  <div>
                    <UploadNewButton
                      onClick={() =>
                        setPreviouslyUploadedBusinessLogo(undefined)
                      }>
                      {t('Upload new')}
                    </UploadNewButton>
                  </div>
                )}
              </FileInputWrapper>
              {errors.businessLogo && (
                <StyledErrorMessage>
                  {t('Please upload an image.')}
                </StyledErrorMessage>
              )}
            </InputErrorMessageWrapper>
          </PromptWrapper>
          <PromptWrapper>
            <PromptHeadingH3>
              {t('Describe your business—what do you do?')}
            </PromptHeadingH3>
            <StyledHeading>
              <RequiredDescriptionPrompt>
                {t('*Required')}
              </RequiredDescriptionPrompt>
            </StyledHeading>
            <TextAreaErrorMessageWrapper>
              <CharacterCappedTextArea
                maxLength={1000}
                register={register}
                fieldName="businessDescription"
                required
                defaultValue={businessUser?.businessDescription}
              />
              {errors.businessDescription && (
                <StyledErrorMessage>
                  {t('Please describe your business.')}
                </StyledErrorMessage>
              )}
            </TextAreaErrorMessageWrapper>
          </PromptWrapper>
          <PromptWrapper>
            <PromptHeadingH3>
              {t(
                'What are the terms and conditions for rewards and rewards redemption? ',
              )}
            </PromptHeadingH3>
            <TextAreaErrorMessageWrapper>
              <CharacterCappedTextArea
                maxLength={1000}
                register={register}
                fieldName="defaultPromotionTerms"
                required={false}
                defaultValue={businessUser?.defaultPromotionTerms}
              />
            </TextAreaErrorMessageWrapper>
          </PromptWrapper>
          <ManageProfileButtonContainer>
            <CreatePromotionButton
              type="submit"
              disabled={isCreatingProfile}
              isLoading={isCreatingProfile}>
              {t('Save')}
            </CreatePromotionButton>
          </ManageProfileButtonContainer>
        </ManageProfileForm>
      </ManageProfileWrapper>
    </PageWrapper>
  );
};

const CreatePromotionButton = styled(ButtonWithLoading)`
  min-width: ${pxToRem(244)};
  max-width: fit-content;
  height: ${pxToRem(65)};
  font-size: ${pxToRem(24)};
  font-weight: ${defaultTheme.typography.fontWeights.medium};
  background-color: ${clRewardsTheme.buttons.bgColor.casperGreen};
  text-align: center;
`;

const ManageProfileForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 3rem;
  margin: 0.25rem 0 4.5rem 0;
  width: 100%;
`;

const ManageProfileWrapper = styled(FormComponentsWrapper)`
  margin: 0;
`;

const StyledHeading = styled.h3`
  font-size: ${pxToRem(18)};
  font-weight: 400;
`;

const RequiredImagePrompt = styled.p`
  font-size: ${pxToRem(16)};
`;

const FileUploadInput = styled.input`
  display: none;
`;

const RequiredDescriptionPrompt = styled.p`
  font-size: ${pxToRem(16)};
  margin-bottom: ${pxToRem(8)};
`;

const PlatformUseCaseList = styled.ul`
  list-style: inside;
  padding: 0 ${pxToRem(10)};
`;

const BulletText = styled.li`
  font-size: ${pxToRem(18)};
`;

const PlatformUseCaseItem = styled.span`
  font-weight: 500;
`;

const TextAreaErrorMessageWrapper = styled.div`
  position: relative;
  text-align: left;
  width: 100%;
`;

const ManageProfileButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const StyledTextInput = styled.input`
  padding: 1rem;
  width: ${pxToRem(315)};
  height: ${pxToRem(50)};
  border: solid ${pxToRem(2)} ${props => props.theme.inputs.border};
  background-color: ${props => props.theme.inputs.bgColor};
  margin-top: 0.75rem;
  border-radius: ${pxToRem(8)};
`;

const UploadNewButton = styled(ButtonWithLoading)`
  min-width: ${pxToRem(180)};
  max-width: fit-content;
  height: ${pxToRem(49)};
  font-size: ${pxToRem(18)};
  font-weight: ${defaultTheme.typography.fontWeights.medium};
  background-color: ${clRewardsTheme.buttons.bgColor.casperGreen};
  text-align: center;
`;
