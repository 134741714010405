import { addMilliseconds } from 'date-fns';
import jwtDecode from 'jwt-decode';

export const setJWT = (token: string) => {
  localStorage.setItem('token', token);

  const { iat, exp } = jwtDecode<{ iat: number; exp: number }>(token);
  const tokenLifetimeInMilliseconds = (exp - iat) * 1000;
  const expiryTime = addMilliseconds(new Date(), tokenLifetimeInMilliseconds);

  localStorage.setItem('tokenExpiry', expiryTime.toISOString());
};
