import React, { useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { ButtonsContainer } from 'src/pages/CreatePromotion';
import { format } from 'date-fns';
import { pxToRem } from 'casper-ui-kit';
import { CreatePromotionButton } from 'src/components/buttons/CreatePromotionButton';
import {
  createBusinessPromotion,
  getCreateBusinessPromotionFormData,
  setCreateBusinessPromotionFormData,
  useAppDispatch,
  useAppSelector,
} from 'src/store';
import { clRewardsTheme } from 'src/styled-theme';
import { toastStore } from 'src/store/toast';
import { CreateBusinessPromotionRequest } from 'src/api/types';
import { useNavigate } from 'react-router-dom';
import { middlewareServiceApi } from 'src/api';
import { Loader } from 'src/components/utility';
import { weekdays } from './Partials/CreatePromotionCheckboxes/CreatePromotionDayCheckboxes';

interface CreatePromotionReviewProps {
  handleContinue: () => void;
  handleBack: () => void;
}

const DATE_FORMAT = 'M/dd/yy';

export const CreatePromotionReview: React.FC<CreatePromotionReviewProps> = ({
  handleContinue,
  handleBack,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const createPromotionFormData = useAppSelector(
    getCreateBusinessPromotionFormData,
  );

  const { page1FormData, page2FormData, page3FormData } =
    createPromotionFormData;

  const { promotionHashtag, promotionName } = {
    ...page1FormData,
  };
  const { pointsForEngagement, engagementLevel, pointsForRedemption } = {
    ...page2FormData,
  };
  const {
    promotionStartDate,
    promotionEndDate,
    rewardExpiry,
    rewardQuantity,
    promotionDays,
  } = { ...page3FormData };

  const mappedPromotionDays = (promotionDays || []).map(promotionDay => {
    const [weekday] = weekdays.filter(
      weekday => weekday.value === promotionDay,
    );

    return weekday.label;
  });

  const promotionDaysFormatted = mappedPromotionDays.join(', ');

  const handleCreate = async () => {
    if (page1FormData && page2FormData && page3FormData) {
      setIsSubmitting(true);

      const { pointsForEngagement, pointsForReferral, pointsForRedemption } = {
        ...page2FormData,
      };

      const {
        promotionStartDate,
        promotionEndDate,
        rewardExpiry,
        rewardQuantity,
        promotionDays,
      } = { ...page3FormData };

      const promotionImageUrl =
        await middlewareServiceApi.fileUpload.uploadFile(
          page1FormData.promotionImage,
        );

      const createPromotionRequestData: CreateBusinessPromotionRequest = {
        ...page1FormData,
        promotionImage: promotionImageUrl,
        pointsForEngagement: Number(pointsForEngagement),
        pointsForReferral: Number(pointsForReferral),
        pointsForRedemption: Number(pointsForRedemption),
        promotionType: 'points-only',
        status: 'active',
        promotionStartDate: promotionStartDate.toISOString(),
        promotionEndDate: promotionEndDate.toISOString(),
        rewardExpiry: rewardExpiry.toISOString(),
        rewardQuantity: Number(rewardQuantity),
        promotionDays,
      };

      const { payload, meta } = await dispatch(
        createBusinessPromotion(createPromotionRequestData),
      );

      toastStore.processActionMetaWithToast(meta.requestStatus, {
        success: 'Created promotion successfully',
        error: 'Error creating promotion.',
      });

      if (payload) {
        // clear the form data so the user can go back to create a new promotion if desired
        dispatch(
          setCreateBusinessPromotionFormData({
            page1FormData: undefined,
            page2FormData: undefined,
            page3FormData: undefined,
          }),
        );
        handleContinue();
      } else {
        navigate('/business-user/page-error');
      }
    }
  };

  const timeline = `${format(
    promotionStartDate ?? new Date(),
    DATE_FORMAT,
  )} to ${format(
    promotionEndDate ?? new Date(),
    DATE_FORMAT,
  )} (expires ${format(rewardExpiry ?? new Date(), DATE_FORMAT)})`;

  const details = useMemo(
    () => [
      {
        label: t('Promotion Name'),
        detail: promotionName,
      },
      {
        label: t('Total promotional items'),
        detail: rewardQuantity,
      },
      {
        label: t('Points rewarded per interaction'),
        detail: pointsForEngagement,
      },
      {
        label: t('Hashtag'),
        detail: `#${promotionHashtag || ''}`,
      },
      {
        label: t('Engagement level'),
        detail: engagementLevel,
      },
      {
        label: t('Points required for reward redemption'),
        detail: pointsForRedemption,
      },
      {
        label: t('Promotion frequency'),
        detail: promotionDaysFormatted,
      },
      {
        label: t('Timeline'),
        detail: timeline,
      },
    ],
    [
      promotionName,
      rewardQuantity,
      pointsForEngagement,
      promotionHashtag,
      engagementLevel,
      pointsForRedemption,
      promotionDaysFormatted,
      timeline,
      t,
    ],
  );

  return (
    <ConfirmDetailsContainer>
      <DetailsHeading>{t('Confirm details')}</DetailsHeading>
      <DetailsSubheading data-testid="details-subheading">
        {t('Lets make sure everything looks right.')}
      </DetailsSubheading>

      {isSubmitting ? (
        <StyledLoader size="lg" />
      ) : (
        <PromotionDetailsCard data-testid="promotion-details-card">
          {details.map(detail => (
            <PromotionDetailWrapper key={detail.label}>
              <UserDetailsSubheading>{detail.label}</UserDetailsSubheading>
              <UserDetailValue>{detail.detail}</UserDetailValue>
            </PromotionDetailWrapper>
          ))}
        </PromotionDetailsCard>
      )}

      <ButtonsContainer>
        <CreatePromotionButton
          disabled={isSubmitting}
          type="button"
          bgColor={clRewardsTheme.buttons.bgColor.white}
          onClick={handleBack}>
          {t('Go back')}
        </CreatePromotionButton>

        <CreatePromotionButton
          disabled={isSubmitting}
          type="button"
          bgColor={clRewardsTheme.buttons.bgColor.casperGreen}
          onClick={handleCreate}>
          {t('Schedule promotion')}
        </CreatePromotionButton>
      </ButtonsContainer>
    </ConfirmDetailsContainer>
  );
};

const StyledLoader = styled(Loader)`
  margin: 5rem auto;
`;

const ConfirmDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 3rem 0;
  height: 100%;
`;

const UserDetailValue = styled.p`
  font-weight: 500;
  font-size: ${pxToRem(20)};
  word-wrap: break-all;
  white-space: normal;
  width: 400px;
`;

const DetailsSubheading = styled.p`
  font-size: ${pxToRem(20)};
`;

const UserDetailsSubheading = styled.p`
  font-size: ${pxToRem(18)};
`;

const DetailsHeading = styled.h2`
  font-size: ${pxToRem(31)};
  font-weight: 500;
`;

const PromotionDetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: ${pxToRem(250)};
  word-wrap: break-word;
  padding: 1.1rem 0;
`;

const PromotionDetailsCard = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  white-space: nowrap;
  width: ${pxToRem(800)};
  height: 25rem;
  margin-top: 3rem;
  column-gap: 3rem;
`;
