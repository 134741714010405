import styled from '@emotion/styled';
import { Heading, pxToRem } from 'casper-ui-kit';

export const MobilePageTitle = styled(Heading)`
  font-size: ${pxToRem(28)};
  font-weight: 400;
`;

export const RedemptionStatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const RedemptionStatusMessage = styled.p`
  margin-bottom: 1.5rem;
`;
