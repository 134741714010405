import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { middlewareServiceApi } from 'src/api';
import { ApiData } from 'src/api/types';
import { Loading } from '../loading.type';

export interface EndUserPromotionRewardState {
  status: Loading;
  redeemRewardStatus: Loading;
  error: AxiosError | null;
  endUserPromotionReward: ApiData.EndUserPromotionReward | null;
}

const initialState: EndUserPromotionRewardState = {
  status: Loading.Idle,
  redeemRewardStatus: Loading.Idle,
  error: null,
  endUserPromotionReward: null,
};

export const createEndUserPromotionReward = createAsyncThunk(
  'endUserPromotionReward/createEndUserPromotionReward',
  async (businessPromotionId: string, { rejectWithValue }) => {
    try {
      const endUserPromotionReward =
        await middlewareServiceApi.endUserPromotionReward.createEndUserPromotionReward(
          businessPromotionId,
        );

      return endUserPromotionReward;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const fetchEndUserPromotionReward = createAsyncThunk(
  'endUserPromotionReward/fetchEndUserPromotionReward',
  async (id: string, { rejectWithValue }) => {
    try {
      const endUserPromotionReward =
        await middlewareServiceApi.endUserPromotionReward.fetchEndUserPromotionReward(
          id,
        );

      return endUserPromotionReward;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const fetchEndUserPromotionRewardByEncryptedId = createAsyncThunk(
  'endUserPromotionReward/fetchEndUserPromotionRewardByEncryptedId',
  async (id: string, { rejectWithValue }) => {
    try {
      const endUserPromotionReward =
        await middlewareServiceApi.endUserPromotionReward.fetchEndUserPromotionRewardByEncryptedId(
          id,
        );

      return endUserPromotionReward;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const redeemEndUserPromotionReward = createAsyncThunk(
  'endUserPromotionReward/redeemEndUserPromotionReward',
  async (id: string, { rejectWithValue }) => {
    try {
      const endUserPromotionReward =
        await middlewareServiceApi.endUserPromotionReward.redeemEndUserPromotionReward(
          id,
        );

      return endUserPromotionReward;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const endUserPromotionRewardSlice = createSlice({
  name: 'endUserPromotionReward',
  initialState,
  reducers: {
    resetEndUserPromotionRewardRedeemLoadingStatus: state => {
      state.redeemRewardStatus = Loading.Idle;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(createEndUserPromotionReward.pending, state => {
        state.status = Loading.Pending;
      })
      .addCase(createEndUserPromotionReward.fulfilled, (state, { payload }) => {
        state.status = Loading.Complete;
        state.endUserPromotionReward = payload;
      })
      .addCase(createEndUserPromotionReward.rejected, (state, { payload }) => {
        state.error = payload as AxiosError;
        state.status = Loading.Failed;
      })
      .addCase(fetchEndUserPromotionReward.pending, state => {
        state.status = Loading.Pending;
      })
      .addCase(fetchEndUserPromotionReward.fulfilled, (state, { payload }) => {
        state.status = Loading.Complete;
        state.endUserPromotionReward = payload;
      })
      .addCase(fetchEndUserPromotionReward.rejected, (state, { payload }) => {
        state.error = payload as AxiosError;
        state.status = Loading.Failed;
      })
      .addCase(fetchEndUserPromotionRewardByEncryptedId.pending, state => {
        state.status = Loading.Pending;
      })
      .addCase(
        fetchEndUserPromotionRewardByEncryptedId.fulfilled,
        (state, { payload }) => {
          state.status = Loading.Complete;
          state.endUserPromotionReward = payload;
        },
      )
      .addCase(
        fetchEndUserPromotionRewardByEncryptedId.rejected,
        (state, { payload }) => {
          state.error = payload as AxiosError;
          state.status = Loading.Failed;
        },
      )
      .addCase(redeemEndUserPromotionReward.pending, state => {
        state.redeemRewardStatus = Loading.Pending;
      })
      .addCase(redeemEndUserPromotionReward.fulfilled, (state, { payload }) => {
        state.redeemRewardStatus = Loading.Complete;
        state.endUserPromotionReward = payload;
      })
      .addCase(redeemEndUserPromotionReward.rejected, (state, { payload }) => {
        state.error = payload as AxiosError;
        state.redeemRewardStatus = Loading.Failed;
      });
  },
});

export const { resetEndUserPromotionRewardRedeemLoadingStatus } =
  endUserPromotionRewardSlice.actions;
