import styled from '@emotion/styled';
import { pxToRem } from 'casper-ui-kit';

export const StartEndDateWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;
`;

export const StyledLabel = styled.label`
  display: block;
  margin-top: 0.25rem;
`;

export const DatePickerStylesWrapper = styled.div`
  input {
    background: #fafafa;
    width: ${pxToRem(170)};
    height: ${pxToRem(45)};
    border: solid ${pxToRem(2)} ${props => props.theme.inputs.border};
    margin-top: 0.5rem;
    padding-left: 3rem;
    border-radius: ${pxToRem(8)};
    appearance: none;
    cursor: pointer;
  }
  position: relative;
`;

export const DatePickerCalendarWrapper = styled.label`
  cursor: pointer;
  position: absolute;
  top: 1.35rem;
  left: 1.5rem;
`;
