import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import styled from '@emotion/styled';
import { Icon, defaultTheme, pxToRem } from 'casper-ui-kit';
import { useTranslation } from 'react-i18next';
import { Plus } from 'src/components/icons';
import { CreatePromotionButton } from 'src/components/buttons/CreatePromotionButton';
import {
  getCreateBusinessPromotionFormData,
  setCreateBusinessPromotionFormData,
  useAppDispatch,
  useAppSelector,
} from 'src/store';
import { clRewardsTheme } from 'src/styled-theme';
import {
  FormComponentsWrapper,
  InputErrorMessageWrapper,
  Prompt,
  PromptWrapper,
  StyledErrorMessage,
  SubPromptWrapper,
} from './CreatePromotion.styled';
import { CreateBusinessPromotion1Form } from './create-promotion-form-types';

interface CreatePromotion1Props {
  handleContinue: () => void;
}

export const CreatePromotion1: React.FC<CreatePromotion1Props> = ({
  handleContinue,
}) => {
  const { page1FormData } = useAppSelector(getCreateBusinessPromotionFormData);

  const { promotionCaption, promotionHashtag, promotionImage, promotionName } =
    {
      ...page1FormData,
    };

  const [uploadImage, setUploadImage] = useState<File | undefined>(
    promotionImage,
  );
  const [uploadImageURL, setUploadImageURL] = useState(
    promotionImage ? URL.createObjectURL(promotionImage) : undefined,
  );
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm<CreateBusinessPromotion1Form>();

  useEffect(() => {
    if (uploadImage) {
      clearErrors('promotionImage');
    }
  }, [clearErrors, uploadImage]);

  const onSubmit: SubmitHandler<CreateBusinessPromotion1Form> = ({
    promotionHashtag,
    ...data
  }) => {
    let parsedPromotionHashtag = promotionHashtag;

    if (promotionHashtag[0] === '#') {
      parsedPromotionHashtag = promotionHashtag.substring(1);
    }

    const hashtagRegex = /^\w+$/;

    if (!hashtagRegex.test(parsedPromotionHashtag)) {
      const errorMessage = t(
        'Hashtags can only contain word characters, numbers & underscores.',
      );

      return setError('promotionHashtag', {
        type: 'validate',
        message: errorMessage,
      });
    }

    if (!uploadImage) {
      return setError('promotionImage', {
        type: 'required',
      });
    }

    dispatch(
      setCreateBusinessPromotionFormData({
        page1FormData: {
          ...data,
          promotionImage: uploadImage,
          promotionHashtag: parsedPromotionHashtag,
        },
      }),
    );
    handleContinue();
  };

  const clearImageUpload = () => {
    setUploadImage(undefined);
    setUploadImageURL('');

    setError('promotionImage', { type: 'required' });
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.length) {
      const [currentUploadImage] = event.target.files;

      setUploadImage(currentUploadImage);
      setUploadImageURL(URL.createObjectURL(currentUploadImage));

      clearErrors('promotionImage');
    }
  };

  return (
    <form
      id="create-promotion-1"
      onSubmit={handleSubmit(onSubmit)}
      autoComplete="off">
      <FormComponentsWrapper>
        <PromptWrapper>
          <PromptDetail>
            {t(
              'Set up your promotion for Instagram by writing an informative caption and hashtag, and uploading an image to represent your campaign. This will be automatically posted to your account when you schedule your promotion.',
            )}
          </PromptDetail>
        </PromptWrapper>
        <PromptWrapper>
          <PromptWrapper>
            <Prompt>{t('Give this promotion a title.')}</Prompt>
            <SubPromptWrapper>
              <p>{t('Add a title for your new promotion')}</p>
            </SubPromptWrapper>
          </PromptWrapper>
          <InputErrorMessageWrapper>
            <StyledTextInput
              data-testid="title-input"
              placeholder="Promotion Title"
              {...register('promotionName', {
                required: true,
              })}
              defaultValue={promotionName}
            />
            {errors.promotionName && (
              <StyledErrorMessage>
                {t('Please give your promotion a title.')}
              </StyledErrorMessage>
            )}
          </InputErrorMessageWrapper>
        </PromptWrapper>
        <PromptWrapper>
          <Prompt>{t('Give your promotion an eye-catching caption.')}</Prompt>
          <SubPromptWrapper>
            <p>
              {t(
                'Include something about the type of discount customers will receive.',
              )}
            </p>
          </SubPromptWrapper>
          <InputErrorMessageWrapper>
            <StyledTextArea
              data-testid="promotion-description-input"
              rows={4}
              cols={62}
              placeholder="Description of your promotion"
              {...register('promotionCaption', {
                required: true,
              })}
              defaultValue={promotionCaption}
            />
            {errors.promotionCaption && (
              <TextAreaErrorMessage>
                {t('Please describe your business.')}
              </TextAreaErrorMessage>
            )}
          </InputErrorMessageWrapper>
        </PromptWrapper>
        <PromptWrapper>
          <Prompt>
            {t(
              'Upload an image to represent your promotion that will appear on your platforms.',
            )}
          </Prompt>
          <SubPromptWrapper>
            <p>
              {t(
                'This is the image customers will see and interact with. Recommended sizes are 1200x630px (Facebook) and 1080x1080 (Instagram).',
              )}
            </p>
          </SubPromptWrapper>
          <InputErrorMessageWrapper>
            <FileInputWrapper>
              <FileInputLabel img={uploadImageURL}>
                {!uploadImage && <Plus />}
                <FileUploadInput
                  data-testid="image-file-input"
                  type="file"
                  accept="image/*"
                  {...register('promotionImage', {
                    required: !uploadImageURL,
                  })}
                  onChange={handleImageChange}
                />
              </FileInputLabel>
              {uploadImage && (
                <FileDetails>
                  <span data-testid="image-details">{uploadImage.name}</span>
                  <RemoveFileButton
                    type="button"
                    data-testid="remove-image-button"
                    onClick={() => clearImageUpload()}>
                    {<Icon width={16} height={16} icon="FailureIcon" />}Remove
                  </RemoveFileButton>
                </FileDetails>
              )}
            </FileInputWrapper>
            {errors.promotionImage && (
              <StyledErrorMessage>
                {t('Please upload an image.')}
              </StyledErrorMessage>
            )}
          </InputErrorMessageWrapper>
        </PromptWrapper>
        <PromptWrapper>
          <PromptWrapper>
            <Prompt>{t('Give this promotion a hashtag.')}</Prompt>
            <SubPromptWrapper>
              <p>
                {t('Customers will access your promotion using this hashtag.')}
              </p>
            </SubPromptWrapper>
          </PromptWrapper>
          <InputErrorMessageWrapper>
            <StyledTextInput
              data-testid="hashtag-input"
              placeholder="#hashtag"
              {...register('promotionHashtag', {
                required: true,
              })}
              defaultValue={promotionHashtag}
            />
            {errors.promotionHashtag && (
              <StyledErrorMessage>
                {errors.promotionHashtag.message
                  ? errors.promotionHashtag.message
                  : t('Please give your promotion a hashtag.')}
              </StyledErrorMessage>
            )}
          </InputErrorMessageWrapper>
        </PromptWrapper>

        <CreatePromotionButton
          testId="cp1-continue"
          type="submit"
          bgColor={clRewardsTheme.buttons.bgColor.casperGreen}>
          Continue
        </CreatePromotionButton>
      </FormComponentsWrapper>
    </form>
  );
};

const PromptDetail = styled.h2`
  font-size: ${pxToRem(20)};
  font-weight: 400;
  margin-bottom: 1rem;
`;

const StyledTextInput = styled.input`
  padding: 1rem;
  width: ${pxToRem(315)};
  height: ${pxToRem(50)};
  border: solid ${pxToRem(2)} ${props => props.theme.inputs.border};
  background-color: ${props => props.theme.inputs.bgColor};
  margin-top: 0.75rem;
  border-radius: ${pxToRem(8)};
`;

const StyledTextArea = styled.textarea`
  font-size: ${pxToRem(18)};
  background-color: ${props => props.theme.inputs.bgColor};
  width: 100%;
  max-width: ${pxToRem(666)};
  height: ${pxToRem(126)};
  border: solid ${pxToRem(2)} ${props => props.theme.inputs.border};
  padding: 0.5rem 1rem;
  margin-top: 0.75rem;
  border-radius: ${pxToRem(8)};
  resize: none;
`;

const FileInputLabel = styled.label<{ img: string | undefined }>`
  display: flex;
  width: ${pxToRem(96.7)};
  height: ${pxToRem(96.7)};
  align-items: center;
  justify-content: center;
  border: solid ${pxToRem(2)} ${props => props.theme.inputs.border};
  background-color: ${props => props.theme.inputs.bgColor};
  background-image: url(${({ img }) => img || ''});
  background-size: cover;
  background-position: center;
  border-radius: ${pxToRem(8)};
  cursor: pointer;
`;

const FileUploadInput = styled.input`
  display: none;
`;

const FileInputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 2.35rem;
  margin-top: 1.75rem;
`;

const FileDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const RemoveFileButton = styled.button`
  display: flex;
  gap: 0.5rem;
  margin-top: 0.25rem;
  padding: 0;
  align-items: center;
  justify-content: start;
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: ${defaultTheme.colors.primary.CasperRed};
`;

export const TextAreaErrorMessage = styled.p`
  color: ${props => props.theme.textColor.inputErrorMessage};
  position: absolute;
  white-space: nowrap;
  line-height: 0.9;
`;
