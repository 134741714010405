import React, { useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { Button, defaultTheme, pxToRem } from 'casper-ui-kit';
import { t } from 'i18next';
import { LargeChocolateCake, SmallChocolateCake } from 'src/components/icons';
import { PageWrapper } from 'src/components/layout/PageWrapper';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Loading,
  createEndUserPromotionReward,
  fetchBusinessPromotion,
  getBusinessPromotion,
  getBusinessPromotionLoadingStatus,
  getEndUserPromotionRewardLoadingStatus,
  useAppDispatch,
  useAppSelector,
} from 'src/store';
import { ApiData } from 'src/api/types';
import { ANIMATION_INTERVAL } from 'src/constants';
import { motion } from 'framer-motion';
import Confetti from 'react-confetti';
import { clRewardsTheme } from 'src/styled-theme';
import { getEndUser } from 'src/store/selectors/end-user-selectors';
import { toastStore } from 'src/store/toast';
import { Loader } from 'src/components';
import {
  animatedTextVariants,
  promotionImageVariants,
} from './animation-variants';
import { PageError } from '../PageError';

export const MyRewards: React.FC = () => {
  const businessPromotion = useAppSelector(getBusinessPromotion);
  const endUser = useAppSelector(getEndUser);
  const endUserPromotionRewardLoadingStatus = useAppSelector(
    getEndUserPromotionRewardLoadingStatus,
  );
  const businessPromotionLoadingStatus = useAppSelector(
    getBusinessPromotionLoadingStatus,
  );

  const [isRedeemable, setIsRedeemable] = useState(false);
  const [points, setPoints] = useState(0);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { search: searchParamsString } = useLocation();

  const promotionId = useMemo(() => {
    const searchParams = new URLSearchParams(searchParamsString);

    return searchParams.get('promotionId');
  }, [searchParamsString]);

  useEffect(() => {
    if (promotionId) {
      dispatch(fetchBusinessPromotion(promotionId));
    }
  }, [dispatch, promotionId]);

  const getEngagementPointsClaimed = (
    engagements: ApiData.EndUserPromotionEngagement[],
  ) => {
    if (!endUser) {
      return 0;
    }

    const endUserFilteredEngagements = engagements.filter(
      engagement => engagement.endUserId === endUser._id,
    );

    const pointsClaimed = endUserFilteredEngagements.reduce(
      (acc, engagement) => {
        if (engagement?.pointsCollected) {
          return acc + engagement.pointsCollected;
        }
        return acc;
      },
      0,
    );

    return pointsClaimed;
  };

  const pointsClaimed = useMemo(() => {
    return getEngagementPointsClaimed(
      (businessPromotion?.engagements as ApiData.EndUserPromotionEngagement[]) ||
        [],
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessPromotion?.engagements.length]);

  useEffect(() => {
    let start = 0;

    if (start === pointsClaimed) return;

    const incrementTime = ANIMATION_INTERVAL / pointsClaimed;

    const timer = setInterval(() => {
      start += 1;
      setPoints(start);
      if (start === pointsClaimed) clearInterval(timer);
    }, incrementTime);

    return () => {
      clearInterval(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pointsClaimed]);

  useEffect(() => {
    if (
      typeof businessPromotion?.pointsForRedemption === 'number' &&
      pointsClaimed >= businessPromotion.pointsForRedemption &&
      points >= businessPromotion.pointsForRedemption
    ) {
      setTimeout(() => {
        setIsRedeemable(true);
      }, 1000);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [points]);

  if (
    businessPromotionLoadingStatus === Loading.Pending ||
    endUserPromotionRewardLoadingStatus === Loading.Pending
  ) {
    return (
      <LoaderWrapper>
        <Loader size="md" />;
      </LoaderWrapper>
    );
  }

  const handleInstagramButton = () => {
    window.location.replace(
      `https://instagram.com/${
        (businessPromotion?.businessUserId as ApiData.BusinessUser)
          ?.instagramAccount ?? ''
      }`,
    );
  };

  const handleGoToDashboardButton = () => {
    navigate('/end-user/dashboard');
  };

  if (!businessPromotion) {
    return <PageError />;
  }

  const endUserRewards = (
    businessPromotion.rewards as ApiData.EndUserPromotionReward[]
  ).filter(
    reward =>
      reward.endUserId === endUser?._id &&
      reward.businessPromotionId === businessPromotion._id,
  );

  const endUserHasReward = endUserRewards.length > 0;

  const redeemReward = async () => {
    if (!endUserHasReward) {
      const { payload, meta } = await dispatch(
        createEndUserPromotionReward(businessPromotion._id),
      );

      toastStore.processActionMetaWithToast(meta.requestStatus, {
        success: 'Redeemed points successfully',
        error: 'Error redeeming points.',
      });

      if (payload) {
        navigate(
          `/end-user/redeem-points?rewardId=${
            (payload as ApiData.BusinessPromotion)._id
          }&promotionId=${promotionId ?? ''}`,
        );
      }
    } else {
      toastStore.setErrorToast('Reward already redeemed');
      navigate('/end-user/dashboard');
    }
  };

  return (
    <PageWrapper>
      <MyRewardsContainer>
        <MyRewardsHeading>{t('My Rewards')}</MyRewardsHeading>
        <BusinessInfoWrapper>
          <div>
            <BusinessNameHeading data-testid="business-name">
              {
                (businessPromotion?.businessUserId as ApiData.BusinessUser)
                  ?.businessName
              }
            </BusinessNameHeading>
            <BusinessNameSubHeading data-testid="promotion-details">
              {businessPromotion?.promotionName}
            </BusinessNameSubHeading>
          </div>
          {isRedeemable || <SmallChocolateCake data-testid="promotion-image" />}
        </BusinessInfoWrapper>
        {!isRedeemable ? (
          <>
            <PointsClaimedProgressBar
              key={pointsClaimed}
              pointsClaimedPercentage={
                (pointsClaimed / businessPromotion.pointsForRedemption) * 100
              }
              animationInterval={ANIMATION_INTERVAL}
              data-testid="progress-bar"
            />
            <PointsClaimedWrapper>
              <PointsClaimed>
                <AnimatedPoints data-testid="users-points">
                  {points}
                </AnimatedPoints>
                /{businessPromotion.pointsForRedemption}
              </PointsClaimed>
              <PointsClaimedSubHeading>
                {t('Points Claimed')}
              </PointsClaimedSubHeading>
            </PointsClaimedWrapper>
          </>
        ) : (
          <>
            <Confetti
              recycle={false}
              numberOfPieces={500}
              tweenDuration={10000}
            />
            <AnimatedTextContainer>
              <LargePromotionalImageWrapper
                initial={promotionImageVariants.initial}
                animate={promotionImageVariants.animate}
                transition={promotionImageVariants.transition}>
                <LargeChocolateCake data-testid="large-promotion-image" />
              </LargePromotionalImageWrapper>
              <AnimatedText
                initial={animatedTextVariants.initial}
                animate={animatedTextVariants.animate}
                transition={animatedTextVariants.transition}>
                {t('Congratulations!')}
              </AnimatedText>
              <RedeemPointsTextWrapper data-testid="redeem-points-text">
                <StyledSpan>{t('You did it!')}</StyledSpan>{' '}
                {t('You can redeem your points for the promotion reward.')}
              </RedeemPointsTextWrapper>
            </AnimatedTextContainer>
          </>
        )}
        <ButtonsWrapper>
          {endUserHasReward ? (
            <MyRewardsButton
              type="button"
              bgColor={clRewardsTheme.buttons.bgColor.casperGreen}
              disabled={
                !isRedeemable ||
                endUserPromotionRewardLoadingStatus === Loading.Failed
              }
              onClick={() =>
                navigate(
                  `/end-user/redeem-points?rewardId=${endUserRewards[0]._id}&promotionId=${businessPromotion._id}`,
                )
              }
              data-testid="redeem-points-button">
              {t('Go to Reward')}
            </MyRewardsButton>
          ) : (
            <MyRewardsButton
              type="button"
              bgColor={clRewardsTheme.buttons.bgColor.casperGreen}
              disabled={
                !isRedeemable ||
                endUserPromotionRewardLoadingStatus === Loading.Failed
              }
              onClick={redeemReward}
              data-testid="redeem-points-button">
              {t('Redeem Reward')}
            </MyRewardsButton>
          )}
          <MyRewardsButton
            type="button"
            color="black"
            bgColor={clRewardsTheme.buttons.bgColor.casperGreen}
            data-testid="instagram-button"
            onClick={handleInstagramButton}>
            {t('Return to Instagram')}
          </MyRewardsButton>
          <MyRewardsButton
            type="button"
            color="black"
            bgColor={clRewardsTheme.buttons.bgColor.casperGreen}
            data-testid="go-to-dashboard-button"
            onClick={handleGoToDashboardButton}>
            {t('Go to dashboard')}
          </MyRewardsButton>
        </ButtonsWrapper>
      </MyRewardsContainer>
    </PageWrapper>
  );
};

const MyRewardsContainer = styled.div`
  width: 100%;
  /* this width will likely change if desktop designs are created */
  max-width: ${pxToRem(700)};
  padding: 0 ${pxToRem(10)};
  margin: 0 auto;
  overflow-y: auto;
`;

const MyRewardsHeading = styled.h1`
  font-size: ${pxToRem(28)};
  font-weight: 400;
`;

const BusinessInfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${pxToRem(20)};
  margin-bottom: ${pxToRem(22)};
  width: 100%;
`;

const BusinessNameHeading = styled.h2`
  font-weight: 500;
  font-size: ${pxToRem(20)};
  padding-top: 0.75rem;
`;

const BusinessNameSubHeading = styled.h3`
  font-weight: 400;
  font-size: ${pxToRem(20)};
`;

export const PointsClaimedProgressBar = styled.div<{
  pointsClaimedPercentage: number;
  animationInterval: number;
}>`
  display: block;
  width: 100%;
  height: ${pxToRem(55)};
  border: solid ${pxToRem(2)} ${props => props.theme.inputs.border};
  border-radius: ${pxToRem(10)};
  background-image: ${props => props.theme.gradients.progressBar};
  background-color: white;
  background-repeat: no-repeat;
  animation: ${({ animationInterval }) =>
    `${animationInterval}ms gradient ease`};
  background-size: ${({ pointsClaimedPercentage }) =>
      `${pointsClaimedPercentage}%`}
    100%;

  @keyframes gradient {
    0% {
      background-size: 0% 100%;
    }
    100% {
      background-size: ${({ pointsClaimedPercentage }) =>
        `${pointsClaimedPercentage}% 100%`};
    }
  }
`;

const PointsClaimedWrapper = styled.div`
  margin-top: ${pxToRem(8)};
  margin-bottom: ${pxToRem(40)};
`;

const PointsClaimed = styled.h2`
  text-align: center;
  font-size: ${pxToRem(40)};
  font-weight: 500;
  line-height: 1.375;
`;

const AnimatedPoints = styled.span`
  font-size: ${pxToRem(40)};
  font-weight: 500;
  width: 2.5ch;
  text-align: right;
`;

const PointsClaimedSubHeading = styled.h3`
  font-size: ${pxToRem(20)};
  font-weight: 400;
  text-align: center;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  gap: ${pxToRem(25)};
`;

const MyRewardsButton = styled(Button)`
  color: ${props => props.theme.buttons.textColor.black};
  font-size: ${pxToRem(18)};
  font-weight: 500;
  padding: ${pxToRem(10)} 0;
  width: ${pxToRem(326)};
  cursor: pointer;

  &:disabled {
    color: ${props => props.theme.buttons.textColor.disabled};
    background-color: ${props => props.theme.buttons.bgColor.disabled};
    cursor: not-allowed;
  }
`;

const LargePromotionalImageWrapper = styled(motion.div)`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const AnimatedTextContainer = styled.div`
  position: relative;
`;

const AnimatedText = styled(motion.div)`
  color: transparent;
  font-family: Casper Sans;
  font-size: ${pxToRem(35)};
  font-weight: 500;
  text-align: center;
  background: linear-gradient(270.41deg, #f622e6 -16.4%, #9f23e1 98.22%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: absolute;
  right: 0;
  left: 0;
`;

const RedeemPointsTextWrapper = styled.h3`
  font-size: ${pxToRem(20)};
  font-weight: 400;
  margin: ${pxToRem(40)} 0;

  @media (min-width: ${defaultTheme.breakpoints.xxs}) {
    text-align: center;
  }
`;

const StyledSpan = styled.span`
  font-size: ${pxToRem(20)};
  font-weight: 500;
  margin-bottom: ${pxToRem(65)};
`;

const LoaderWrapper = styled.div`
  height: 50vh;
`;
