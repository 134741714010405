import { AxiosResponse } from 'axios';
import { ApiService } from 'src/api/base-api';
import { setJWT } from 'src/api/helper';

export class AuthRoutes {
  private readonly prefix: string = '/auth';

  constructor(private readonly api: ApiService) {}

  async authenticateAdmin(emailAddress: string, password: string) {
    type Response = AxiosResponse<{ jwt: { access_token: string } }>;

    const response = await this.api.post<Response>(`${this.prefix}/admin`, {
      emailAddress,
      password,
    });

    if (response.status !== 200) throw new Error(response.statusText);

    const {
      jwt: { access_token: token },
    } = response.data;

    setJWT(token);

    return token;
  }
}
