import React from 'react';
import { PageWrapper } from 'src/components/layout/PageWrapper';
import { useTranslation } from 'react-i18next';
import { useAppWidth } from 'src/hooks';
import { TitleText } from '../SharedStyles/pages.styled';
import { MobileDashboard } from './dashboard/MobileDashboard';
import { DesktopDashboard } from './dashboard/DesktopDashboard';

export enum MyRewardsFilterOptions {
  Redeemed = 'Redeemed',
  ToBeRedeemed = 'To Be Redeemed',
}

export const Dashboard: React.FC = () => {
  const { t } = useTranslation();

  const { isMobile } = useAppWidth();

  const getDashboardView = () => {
    if (isMobile) {
      return <MobileDashboard />;
    }

    return <DesktopDashboard />;
  };

  return (
    <PageWrapper>
      <TitleText>{t('Dashboard')}</TitleText>
      {getDashboardView()}
    </PageWrapper>
  );
};
