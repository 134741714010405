/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import styled from '@emotion/styled';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const PoliciesText: React.FC = () => {
  const { t } = useTranslation();

  return (
    <PolicyText>
      {t('View our')}{' '}
      <a href="/assets/policies/privacy-policy.txt" download>
        {t('privacy policy')}
      </a>{' '}
      {t('and')}{' '}
      <a href="/assets/policies/terms-and-conditions.txt" download>
        {t('terms and conditions')}
      </a>
      .
    </PolicyText>
  );
};

const PolicyText = styled.p`
  text-align: left;
  width: 100%;
  max-width: 32.75rem;

  & > a {
    color: black;
    text-decoration: underline;

    &:hover {
      text-underline-position: under;
    }
  }
`;
