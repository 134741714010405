import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { t } from 'i18next';
import { SubmitHandler, useForm } from 'react-hook-form';
import { defaultTheme, pxToRem } from 'casper-ui-kit';
import { PageWrapper } from 'src/components/layout/PageWrapper';
import { CreatePromotionButton } from 'src/components/buttons/CreatePromotionButton';
import {
  setBusinessLogin,
  setFacebookAuthResponse,
  useAppDispatch,
  useAppSelector,
} from 'src/store';
import { mockPilotBusinesses } from 'src/mock-data';
import { SignUpPageHeader } from 'src/components/layout/SignUpPageHeader';
import { isSuccessfullyLoggedIn } from 'src/store/selectors/auth-selectors';
import { clRewardsTheme } from 'src/styled-theme';
import {
  InputErrorMessageWrapper,
  PromptHeadingH3,
  StyledErrorMessage,
  StyledInput,
} from './SharedStyles/pages.styled';

export interface SignUpPageValues {
  instagramHandle: string;
}

export const SignUpPage: React.FC = () => {
  const isLoggedIn = useAppSelector(isSuccessfullyLoggedIn);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SignUpPageValues>();

  useEffect(() => {
    if (isLoggedIn) {
      window.FB.logout(res => {
        if (res.status !== 'connected') {
          dispatch(setFacebookAuthResponse(res));
        }
      });
    }
  }, [dispatch, isLoggedIn]);

  if (isLoggedIn) {
    return null;
  }

  const onSubmit: SubmitHandler<SignUpPageValues> = data => {
    const mockBusinessHandles = mockPilotBusinesses.map(
      business => business.instagramHandle,
    );
    const submittedHandle = data.instagramHandle;
    // TODO: implement accepting both IG handles with and without the @ symbol.
    const isInvited = mockBusinessHandles.includes(submittedHandle);

    dispatch(setBusinessLogin(isInvited));

    if (isInvited) {
      navigate('business-user/login');
    } else {
      navigate('business-user/sign-up-failure');
    }
  };

  return (
    <PageWrapper>
      <SignUpPageHeader />
      <StyledHero>[Hero text about Casper Rewards]</StyledHero>
      <HeroSubheading>
        {t(
          'You need a Facebook, Instagram, or WhatsApp Business account to sign up for Casper Rewards',
        )}
      </HeroSubheading>
      <SignUpPageForm onSubmit={handleSubmit(onSubmit)}>
        <InputErrorMessageWrapper>
          <PromptHeadingH3>
            {t('Enter business Instagram handle')}
          </PromptHeadingH3>
          <StyledInput
            placeholder="@instagram"
            {...register('instagramHandle', {
              required: true,
              pattern: /^(?:@?[a-zA-Z0-9_.]+)$/,
              maxLength: 30,
            })}
          />
          {errors.instagramHandle && (
            <StyledErrorMessage>
              {t('Please enter your Instagram handle.')}
            </StyledErrorMessage>
          )}
        </InputErrorMessageWrapper>
        <SignUpPageButtonWrapper>
          <CreatePromotionButton
            type="submit"
            bgColor={clRewardsTheme.buttons.bgColor.casperGreen}>
            {t('Continue')}
          </CreatePromotionButton>
        </SignUpPageButtonWrapper>
      </SignUpPageForm>
    </PageWrapper>
  );
};

const SignUpPageForm = styled.form`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: ${pxToRem(55)};
  width: 100%;

  @media (min-width: ${defaultTheme.breakpoints.md}) {
    text-align: start;
  }
`;

const StyledHero = styled.h1`
  font-size: ${pxToRem(80)};
  font-weight: 400;
  line-height: 1;
  width: 100%;
  margin-top: ${pxToRem(90)};
  margin-bottom: ${pxToRem(55)};
  text-align: center;

  @media (min-width: ${defaultTheme.breakpoints.md}) {
    max-width: 600px;
    text-align: left;
  }
`;

const HeroSubheading = styled.h2`
  font-size: ${pxToRem(18)};
  font-weight: 400;
  margin-bottom: 1rem;
  width: 100%;
  max-width: ${pxToRem(475)};
  margin: 0 auto;

  @media (min-width: ${defaultTheme.breakpoints.md}) {
    margin: 0;
  }
`;

const SignUpPageButtonWrapper = styled.div`
  margin: ${pxToRem(40)} 0 ${pxToRem(105)} 0;
  width: 100%;

  @media (min-width: ${defaultTheme.breakpoints.md}) {
    margin: ${pxToRem(40)} 0 ${pxToRem(105)} 0;
  }
`;
