import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { Button, Icon, defaultTheme, pxToRem } from 'casper-ui-kit';
import { AccountLogo } from 'src/components/logos';

export const Nav = styled.nav`
  display: flex;
  justify-content: flex-end;
  width: fit-content;
  height: ${pxToRem(136)};
  @media (min-width: ${defaultTheme.breakpoints.xl}) {
    width: 100%;
  }
`;

export const NavComponentsContainer = styled.div`
  display: flex;
  padding: 0;
`;

export const NavItemsContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const MobileNav = styled.nav`
  @media (min-width: ${defaultTheme.breakpoints.xl}) {
    display: none;
  }
`;

export const MobileNavItemsContainer = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  align-items: center;
  gap: 1.25rem;
  z-index: 10;
  background-color: ${defaultTheme.colors.primary.CasperWhite};
  position: fixed;
  left: 0;
  top: 0;
`;

export const MobileNavItemLink = styled(Link)`
  color: ${props => props.theme.links.textColor.black};
  font-size: 1.375rem;
  padding: 0.3125rem;
  width: 100%;
  font-weight: 500;
  letter-spacing: 0.025em;

  :hover {
    transition: color ease-in 200ms;
    color: ${props => props.theme.links.textColor.hover.mobileNavItemLink};
    transition-property: color;
    text-decoration: none;
  }

  @media only screen and (min-width: ${defaultTheme.breakpoints.md}) {
    font-size: 1.75rem;
  }
`;

export const MobileNavActionItem = styled.button`
  all: unset;
  cursor: pointer;
  color: ${props => props.theme.links.textColor.black};
  font-size: 1.375rem;
  padding: 0.3125rem;
  width: 100%;
  font-weight: 500;
  letter-spacing: 0.025em;

  :hover {
    transition: color ease-in 200ms;
    color: ${props => props.theme.links.textColor.hover.mobileNavItemLink};
    transition-property: color;
    text-decoration: none;
    cursor: pointer;
  }

  @media only screen and (min-width: ${defaultTheme.breakpoints.md}) {
    font-size: 1.75rem;
  }
`;

export const DesktopNav = styled.nav`
  display: none;

  @media (min-width: ${defaultTheme.breakpoints.xl}) {
    display: flex;
  }
`;

export const DesktopNavItemsContainer = styled.ul`
  display: flex;
  align-items: center;
  gap: ${pxToRem(100)};
  width: 100%;
  min-height: ${pxToRem(75)};
`;

export const DesktopNavItemLink = styled(Button)`
  transition: all 0.2s ease;
  padding: ${pxToRem(6)} ${pxToRem(20)};
  font-size: clamp(0.9rem, 1.2vw, 1.4rem);
  font-weight: 500;
  text-decoration: none;
  border-radius: ${pxToRem(8)};

  :focus {
    text-decoration: none;
  }

  :active,
  :hover {
    color: ${props => props.theme.links.textColor.black};
    background-color: ${props => props.theme.backgrounds.black};
    padding: ${pxToRem(6)} ${pxToRem(20)};
    text-decoration: none;
    border: none;
  }
`;

export const DesktopNavContainer = styled(NavItemsContainer)`
  display: none;

  @media only screen and (min-width: ${defaultTheme.breakpoints.xl}) {
    display: flex;
  }
`;

export const NavIcon = styled(Icon)`
  svg {
    width: ${pxToRem(35)};
    height: ${pxToRem(35)};
    stroke-width: 2;

    @media only screen and (min-width: ${defaultTheme.breakpoints.xs}) {
      stroke-width: 3;
      width: ${pxToRem(50)};
      height: ${pxToRem(50)};
    }
  }
`;

export const StyledAccountLogo = styled(AccountLogo)`
  width: ${pxToRem(35)};
  height: ${pxToRem(35)};
`;
