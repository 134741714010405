export const getUserType = (pathname: string) => {
  if (pathname.includes('end-user')) {
    return 'end-user';
  }

  if (pathname.includes('business-user')) {
    return 'business-user';
  }

  throw new Error('Unable to get user type from location.pathname');
};
