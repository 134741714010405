import { differenceInDays, getDay } from 'date-fns';
import {
  CreateBusinessPromotion2Form,
  CreateBusinessPromotion3Form,
} from 'src/components/create-promotion/steps/create-promotion-form-types';

export const getHasAttainableEndUserReward = (
  page2FormData: CreateBusinessPromotion2Form,
  page3FormData: CreateBusinessPromotion3Form,
) => {
  const { pointsForEngagement, pointsForRedemption } = page2FormData;
  const { promotionDays, promotionStartDate, promotionEndDate } = page3FormData;

  const numberOfTimesNeededToEngage =
    Number(pointsForRedemption) / Number(pointsForEngagement);

  const daysBetweenStartEndDates = differenceInDays(
    promotionEndDate,
    promotionStartDate,
  );

  const startDayOfWeek = getDay(promotionStartDate);

  let possibleEngagementCount = 0;
  // eslint-disable-next-line no-restricted-syntax
  for (const day of promotionDays) {
    let numberOfDaysFromStart = Number(day) - startDayOfWeek;

    if (numberOfDaysFromStart < 0) {
      numberOfDaysFromStart += 7;
    }

    const numberOfEngagementsPerDay =
      Math.floor((daysBetweenStartEndDates - numberOfDaysFromStart) / 7) + 1;

    possibleEngagementCount += numberOfEngagementsPerDay;
  }

  return possibleEngagementCount >= numberOfTimesNeededToEngage;
};
