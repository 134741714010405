import styled from '@emotion/styled';
import { defaultTheme, pxToRem } from 'casper-ui-kit';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  EndUserRewards,
  LivePromotions,
  ParticipatingBusinesses,
} from 'src/components/end-user-dashboard/mobile-view';

enum DashboardSelectorOption {
  Businesses = 'Businesses',
  LivePromos = 'Live Promos',
  MyRewards = 'My Rewards',
}

export enum MyRewardsFilterOptions {
  Redeemed = 'Redeemed',
  ToBeRedeemed = 'To Be Redeemed',
}

const DASHBOARD_TOGGLE_OPTIONS = [
  DashboardSelectorOption.Businesses,
  DashboardSelectorOption.LivePromos,
  DashboardSelectorOption.MyRewards,
];

export const MobileDashboard: React.FC = () => {
  const { t } = useTranslation();
  const [selectedToggleOption, setSelectedToggleOption] = useState(
    DashboardSelectorOption.Businesses,
  );

  const handleToggle = (toggleOption: DashboardSelectorOption) => {
    setSelectedToggleOption(toggleOption);
  };

  const renderToggleOption = (toggleOption: string) => {
    switch (toggleOption) {
      case DashboardSelectorOption.Businesses:
        return <ParticipatingBusinesses />;
      case DashboardSelectorOption.LivePromos:
        return <LivePromotions />;
      case DashboardSelectorOption.MyRewards:
        return <EndUserRewards />;
      default:
        break;
    }
  };

  return (
    <>
      <ToggleSelectorWrapper>
        {DASHBOARD_TOGGLE_OPTIONS.map(toggleOption => (
          <ToggleButton
            key={toggleOption}
            type="submit"
            onClick={() => handleToggle(toggleOption)}
            disabled={toggleOption === selectedToggleOption}>
            {t(toggleOption)}
          </ToggleButton>
        ))}
      </ToggleSelectorWrapper>

      {renderToggleOption(selectedToggleOption)}
    </>
  );
};

export const ToggleButton = styled.button`
  text-align: center;
  border: none;
  font-size: ${pxToRem(12)};
  font-weight: 500;
  padding: 0.5rem 0.35rem;
  line-height: 1.25;
  cursor: pointer;
  white-space: nowrap;
  background-color: ${props => props.theme.inputs.border};
  min-width: 6rem;
  color: #000;

  :disabled {
    background-color: ${props => props.theme.links.bgColor.casperGreen};
  }

  @media only screen and (min-width: ${defaultTheme.breakpoints.xs}) {
    font-size: 1rem;
  }
`;

const ToggleSelectorWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 0.5rem;
  margin-top: 1.5rem;
`;
