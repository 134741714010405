import React from 'react';
import styled from '@emotion/styled';
import { Loader } from '../utility';

interface ButtonWithLoadingProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  isLoading?: boolean;
}

export const ButtonWithLoading: React.FC<ButtonWithLoadingProps> = ({
  children,
  isLoading,
  ...buttonProps
}) => {
  const getChildrenWithLoader = () => {
    if (isLoading) {
      return <Loader size="sm" />;
    }

    return children;
  };

  return <Button {...buttonProps}>{getChildrenWithLoader()}</Button>;
};

const Button = styled.button`
  all: unset;
  cursor: pointer;

  :disabled {
    cursor: not-allowed;
    opacity: 0.75;
  }
`;
