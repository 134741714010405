import { AxiosResponse } from 'axios';
import qs from 'query-string';
import { ApiService } from 'src/api/base-api';

import {
  ApiData,
  CreateBusinessPromotionRequest,
  PromotionStatus,
} from 'src/api/types';

export class BusinessPromotionRoutes {
  private readonly prefix: string = '/business-promotion';

  constructor(private readonly api: ApiService) {}

  async fetchBusinessPromotions(
    search?: {
      promotionName?: string;
    },
    filters?: { myPromotions?: boolean },
    active?: boolean,
  ) {
    type Response = AxiosResponse<ApiData.BusinessPromotion[]>;

    const urlParams: { [key: string]: string } = {
      search: JSON.stringify(search),
      filters: JSON.stringify(filters),
    };

    if (active) {
      urlParams.status = 'active';
    }

    const url = `${this.prefix}${search ? '/search/?' : ''}${qs.stringify(
      urlParams,
    )}`;

    const response = await this.api.get<Response>(url);

    if (response.status !== 200) throw new Error(response.statusText);

    const { data } = response;

    return data;
  }

  async fetchPromotionToManage(promotionId: string) {
    type Response = AxiosResponse<ApiData.BusinessPromotion>;

    const response = await this.api.get<Response>(
      `${this.prefix}/${promotionId}`,
    );

    if (response.status !== 200) throw new Error(response.statusText);

    const { data } = response;

    return data;
  }

  async fetchMyPromotionsForBusinessDashboard() {
    type Response = AxiosResponse<ApiData.BusinessPromotion[]>;

    const response = await this.api.get<Response>(this.prefix);

    if (response.status !== 200) throw new Error(response.statusText);

    const { data } = response;

    return data;
  }

  async updatePromotionStatus({
    promotionId,
    updatedStatus,
  }: {
    promotionId: string;
    updatedStatus: PromotionStatus;
  }) {
    type Response = AxiosResponse<PromotionStatus>;

    const response = await this.api.patch<Response>(
      `${this.prefix}/${promotionId}`,
      {
        status: updatedStatus,
      },
    );

    if (response.status !== 200) throw new Error(response.statusText);

    return updatedStatus;
  }

  async fetchBusinessPromotion(id: string) {
    type Response = AxiosResponse<ApiData.BusinessPromotion>;

    const response = await this.api.get<Response>(`/business-promotion/${id}`);

    if (response.status !== 200) throw new Error(response.statusText);

    const { data } = response;

    return data;
  }

  async fetchBusinessPromotionByEncryptedId(id: string) {
    type Response = AxiosResponse<ApiData.BusinessPromotion>;

    const urlParams = {
      encryptedId: JSON.stringify(id),
    };

    const url = `${this.prefix}/encrypted/?${qs.stringify(urlParams)}`;

    const response = await this.api.get<Response>(url);

    if (response.status !== 200) throw new Error(response.statusText);

    const { data } = response;

    return data;
  }

  async fetchBusinessPromotionUnguarded(id: string) {
    type Response = AxiosResponse<ApiData.BusinessPromotionUnguarded>;

    const response = await this.api.get<Response>(
      `/business-promotion/unguarded/${id}`,
    );

    if (response.status !== 200) throw new Error(response.statusText);

    const { data } = response;

    return data;
  }

  async createBusinessPromotion(args: CreateBusinessPromotionRequest) {
    type Response = AxiosResponse<ApiData.BusinessPromotion>;

    const response = await this.api.post<Response>(this.prefix, args);

    if (response.status !== 201) throw new Error(response.statusText);

    const { data } = response;

    return data;
  }
}
