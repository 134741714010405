import styled from '@emotion/styled';
import { defaultTheme, pxToRem } from 'casper-ui-kit';
import React, { useEffect } from 'react';
import { Location, useLocation, useNavigate } from 'react-router-dom';
import { setFacebookAuthResponse, useAppDispatch } from 'src/store';

import { clRewardsTheme } from 'src/styled-theme';
import { getUserType, logoutJwtLocalStorage } from 'src/utils';

interface LocationState extends Location {
  state: {
    unauthorizedError?: boolean;
    headerOverride?: string;
    descriptionOverride?: string;
    displayNavigateBack?: boolean;
  };
}

interface PageErrorProps {
  header?: string | React.ReactNode;
  description?: string | React.ReactNode;
  displayNavigateBack?: boolean;
  unauthorizedError?: boolean;
}

export const PageError: React.FC<PageErrorProps> = ({
  header,
  description,
  displayNavigateBack,
  unauthorizedError,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { pathname, state } = useLocation() as LocationState;

  const handleLogout = (userType: 'end-user' | 'business-user') => {
    window.FB.logout(res => {
      if (res.status !== 'connected') {
        logoutJwtLocalStorage();

        dispatch(setFacebookAuthResponse(res));

        navigate(`/${userType}/login`);
      }
    });
  };

  useEffect(() => {
    if (unauthorizedError || state?.unauthorizedError) {
      handleLogout(getUserType(pathname));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigateBack = () => navigate(-1);

  const navigateTo = (path: string) => {
    if (pathname.includes('end-user')) {
      navigate(`/end-user/${path}`);
    } else if (pathname.includes('business-user')) {
      navigate(`/business-user/${path}`);
    } else {
      throw new Error(`Unable to navigate to ${path}.`);
    }
  };

  const getPageErrorButtons = () => {
    if (unauthorizedError || state?.unauthorizedError) {
      return (
        <PageErrorButton onClick={() => navigateTo('login')}>
          Return to login
        </PageErrorButton>
      );
    }

    return (
      <>
        {(displayNavigateBack || state?.displayNavigateBack) && (
          <PageErrorButton onClick={navigateBack}>Go back</PageErrorButton>
        )}
        <PageErrorButton onClick={() => navigate('dashboard')}>
          Return to dashboard
        </PageErrorButton>
      </>
    );
  };

  return (
    <PageErrorWrapper>
      <ErrorHeader>
        {header ?? state?.headerOverride ?? 'Something went wrong.'}
      </ErrorHeader>
      <ErrorDescription>
        {description ??
          state?.descriptionOverride ??
          'There was an error handling the request.'}
      </ErrorDescription>
      <ButtonWrapper>{getPageErrorButtons()}</ButtonWrapper>
    </PageErrorWrapper>
  );
};

const PageErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 2rem;
  text-align: center;
`;

const ErrorHeader = styled.h2`
  font-size: ${pxToRem(28)};
  font-weight: 400;
  margin-top: 2rem;

  @media (min-width: ${defaultTheme.breakpoints.sm}) {
    margin-top: 5rem;
  }
`;

const ErrorDescription = styled.p`
  font-size: ${pxToRem(20)};
  margin-top: 1rem;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 1rem;
`;

const PageErrorButton = styled.button`
  all: unset;
  cursor: pointer;
  text-align: center;
  height: ${pxToRem(45)};
  width: 100%;
  background-color: ${() => clRewardsTheme.buttons.bgColor.casperGreen};
  color: ${() => clRewardsTheme.buttons.textColor.black};
  font-weight: 500;
  margin-top: 1rem;
  letter-spacing: 0.025rem;
  max-width: ${pxToRem(524)};
`;
