import React, { useEffect } from 'react';
import {
  Loading,
  clearEndUserRedeemedRewards,
  fetchRedeemedEndUserReward,
  resetEndUserRedeemedRewardsLoadingStatus,
  useAppDispatch,
  useAppSelector,
} from 'src/store';
import {
  getEndUserRedeemedRewardsLoadingStatus,
  getRedeemedEndUserRewards,
} from 'src/store/selectors/end-user-selectors';
import { skeletonData } from 'src/mock-data/skeleton-loading';
import { getSkeletonLoadingData } from 'src/components/loading';
import { CardContainer } from '../cards.styled';
import { RedeemedRewardCard } from './RedeemedRewardCard';

export const RedeemedRewardCards: React.FC = () => {
  const dispatch = useAppDispatch();

  const endUserRedeemedRewards = useAppSelector(getRedeemedEndUserRewards);
  const endUserRedeemedRewardLoadingStatus = useAppSelector(
    getEndUserRedeemedRewardsLoadingStatus,
  );

  useEffect(() => {
    dispatch(fetchRedeemedEndUserReward());

    return () => {
      dispatch(clearEndUserRedeemedRewards());
      dispatch(resetEndUserRedeemedRewardsLoadingStatus());
    };
  }, [dispatch]);

  if (
    !endUserRedeemedRewards?.length &&
    endUserRedeemedRewardLoadingStatus === Loading.Complete
  ) {
    return <CardContainer>No Redeemed Rewards</CardContainer>;
  }

  const getEndUserRedeemedRewardsWithSkeletonLoading = () => {
    return (
      endUserRedeemedRewards ??
      getSkeletonLoadingData(skeletonData.endUserPromotionReward, 3)
    );
  };

  const isEndUserRedeemedRewardsLoading =
    endUserRedeemedRewardLoadingStatus !== Loading.Complete;

  return (
    <CardContainer>
      {getEndUserRedeemedRewardsWithSkeletonLoading().map(endUserReward => {
        const { _id: uniqueIdHash } = endUserReward;
        const { businessPromotionId, redeemedAt, createdAt } = endUserReward;

        if (typeof businessPromotionId === 'string' || !businessPromotionId) {
          return (
            <RedeemedRewardCard
              key={uniqueIdHash}
              promotionName="Not Available"
              promotionImage=""
              promotionCaption="Not Available"
              promotionHashtag="Not Available"
              pointsForRedemption={0}
              rewardClaimDate="Not Available"
              rewardRedemptionDate="Not Available"
              isLoading={isEndUserRedeemedRewardsLoading}
            />
          );
        }

        return (
          <RedeemedRewardCard
            key={uniqueIdHash}
            rewardRedemptionDate={redeemedAt}
            promotionName={businessPromotionId.promotionName}
            promotionImage={businessPromotionId.promotionImage}
            promotionCaption={businessPromotionId.promotionCaption}
            promotionHashtag={businessPromotionId.promotionHashtag}
            pointsForRedemption={businessPromotionId.pointsForRedemption}
            rewardClaimDate={createdAt}
            isLoading={isEndUserRedeemedRewardsLoading}
          />
        );
      })}
    </CardContainer>
  );
};
