import { RootState } from '../store';

export const getBusinessUser = (state: RootState) => {
  return state.businessUser.businessUser;
};

export const getBusinessUserLoadingStatus = (state: RootState) => {
  return state.businessUser.status;
};

export const getBusinessUserAuthLoadingStatus = (state: RootState) => {
  return state.businessUser.businessUserAuthenticationStatus;
};
