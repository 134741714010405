import React, { useEffect, useMemo } from 'react';
import { Heading, HeadingType, pxToRem } from 'casper-ui-kit';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import {
  Loading,
  fetchBusinessPromotion,
  fetchEndUserPromotionReward,
  getBusinessPromotion,
  getBusinessPromotionLoadingStatus,
  getEndUserPromotionReward,
  getEndUserPromotionRewardLoadingStatus,
  useAppDispatch,
  useAppSelector,
} from 'src/store';
import { useLocation } from 'react-router-dom';
import { ApiData } from 'src/api/types';
import QRCode from 'react-qr-code';
import { loadConfig } from 'src/utils';
import { clRewardsTheme } from 'src/styled-theme';
import { Loader } from 'src/components';
import { PageWrapper } from '../../components/layout/PageWrapper';
import { PageError } from '../PageError';

const { qrCodeLink } = loadConfig();

export const RedeemPoints: React.FC = () => {
  const dispatch = useAppDispatch();
  const { search: searchParamsString } = useLocation();

  const businessPromotion = useAppSelector(getBusinessPromotion);
  const endUserPromotionReward = useAppSelector(getEndUserPromotionReward);

  const businessPromotionLoadingStatus = useAppSelector(
    getBusinessPromotionLoadingStatus,
  );
  const endUserPromotionRewardLoadingStatus = useAppSelector(
    getEndUserPromotionRewardLoadingStatus,
  );

  const promotionId = useMemo(() => {
    const searchParams = new URLSearchParams(searchParamsString);

    return searchParams.get('promotionId');
  }, [searchParamsString]);

  const rewardId = useMemo(() => {
    const searchParams = new URLSearchParams(searchParamsString);

    return searchParams.get('rewardId');
  }, [searchParamsString]);

  useEffect(() => {
    if (promotionId && !businessPromotion?.encryptedId) {
      dispatch(fetchBusinessPromotion(promotionId));
    }
  }, [dispatch, promotionId, businessPromotion]);

  useEffect(() => {
    if (rewardId && !endUserPromotionReward?.encryptedId) {
      dispatch(fetchEndUserPromotionReward(rewardId));
    }
  }, [dispatch, rewardId, endUserPromotionReward]);

  const { t } = useTranslation();

  const isLoading =
    businessPromotionLoadingStatus === Loading.Pending ||
    endUserPromotionRewardLoadingStatus === Loading.Pending;

  if (!businessPromotion || !endUserPromotionReward || isLoading) {
    return (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    );
  }

  if (!businessPromotion?.encryptedId || !endUserPromotionReward?.encryptedId) {
    return <PageError />;
  }

  return (
    <PageWrapper>
      <RedeemPointsWrapper>
        <RedeemPointsHeader type={HeadingType.H2}>
          {t('My Rewards')}
        </RedeemPointsHeader>

        <div>
          <BusinessTitle>
            {
              (businessPromotion?.businessUserId as ApiData.BusinessUser)
                ?.businessName
            }
          </BusinessTitle>
          <PromotionTitle>{businessPromotion?.promotionName}</PromotionTitle>
        </div>

        <QRCodeDetailsWrapper>
          <div>
            <QRCode
              value={`${qrCodeLink}/business-user/reward-details?promotionId=${businessPromotion?.encryptedId}&rewardId=${endUserPromotionReward?.encryptedId}`}
              bgColor={clRewardsTheme.backgrounds.primary}
            />
          </div>

          <RedemptionConditionsList>
            <RewardRedemptionConditionsText>
              {t('Reward redemption conditions')}:
            </RewardRedemptionConditionsText>
            <span>
              {(businessPromotion.businessUserId as ApiData.BusinessUser)
                ?.defaultPromotionTerms || ' No conditions have been set.'}
            </span>
          </RedemptionConditionsList>
        </QRCodeDetailsWrapper>
      </RedeemPointsWrapper>
    </PageWrapper>
  );
};

const RedeemPointsHeader = styled(Heading)`
  font-size: ${pxToRem(28)};
  font-weight: 400;
`;

const BusinessTitle = styled.p`
  font-size: ${pxToRem(20)};
  font-weight: 500;
`;

const PromotionTitle = styled.p`
  font-size: ${pxToRem(20)};
  font-weight: 400;
`;

const RedeemPointsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pxToRem(30)};
`;

const RewardRedemptionConditionsText = styled.span`
  font-weight: 500;
`;

const QRCodeDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pxToRem(30)};
`;

const RedemptionConditionsList = styled.ul`
  list-style: inside;
`;

const LoaderWrapper = styled.div`
  height: 50vh;
`;
