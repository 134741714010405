import styled from '@emotion/styled';
import { pxToRem } from 'casper-ui-kit';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getSkeletonLoadingData } from 'src/components/loading';
import { skeletonData } from 'src/mock-data/skeleton-loading';
import {
  Loading,
  clearParticipatingBusinesses,
  fetchBusinesses,
  resetParticipatingBusinessesLoadingStatus,
  useAppDispatch,
  useAppSelector,
} from 'src/store';
import {
  getParticipatingBusinessLoading,
  getParticipatingBusinesses,
} from 'src/store/selectors/end-user-selectors';
import { useDebounce } from 'use-debounce';
import { useForm } from 'react-hook-form';
import {
  InputErrorMessageWrapper,
  StyledErrorMessage,
} from '../../../pages/SharedStyles/pages.styled';
import { CardContainer } from '../../cards/cards.styled';
import { ParticipatingBusinessesCard } from './ParticipatingBusinessesCard';
import { ParticipatingBusinessesForm } from '../mobile-view/ParticipatingBusinesses';

export const ParticipatingBusinessesTab: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const {
    register,
    formState: { errors },
    watch,
  } = useForm<ParticipatingBusinessesForm>();

  const participatingBusinesses = useAppSelector(getParticipatingBusinesses);
  const endUserBusinessesLoadingStatus = useAppSelector(
    getParticipatingBusinessLoading,
  );
  const businessNameSearch = watch('businessName') ?? '';
  const [debouncedBusinessNameSearch] = useDebounce(businessNameSearch, 500);

  useEffect(() => {
    dispatch(
      fetchBusinesses(
        debouncedBusinessNameSearch
          ? { businessName: debouncedBusinessNameSearch }
          : undefined,
      ),
    );
  }, [debouncedBusinessNameSearch, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(clearParticipatingBusinesses());
      dispatch(resetParticipatingBusinessesLoadingStatus());
    };
  }, [dispatch]);

  const getBusinessesContent = () => {
    if (
      !participatingBusinesses?.length &&
      endUserBusinessesLoadingStatus === Loading.Complete
    ) {
      return (
        <CardContainer>
          <NoResultsText>No active participating businesses</NoResultsText>
        </CardContainer>
      );
    }

    return (
      participatingBusinesses ??
      getSkeletonLoadingData(skeletonData.businessUser, 8)
    ).map(business => {
      const { _id: uniqueIdHash } = business;

      return (
        <ParticipatingBusinessesCard
          key={uniqueIdHash}
          business={business}
          isLoading={isEndUserBusinessesLoading}
        />
      );
    });
  };

  const isEndUserBusinessesLoading =
    endUserBusinessesLoadingStatus !== Loading.Complete;

  return (
    <TabWrapper>
      <HeaderWrapper>
        <TabHeader>{t('Participating Businesses')}</TabHeader>
        <SearchForm>
          <InputErrorMessageWrapper>
            <InputWrapper>
              <StyledInput
                id="business-name"
                placeholder="Search for business"
                {...register('businessName', {
                  required: true,
                })}
              />
            </InputWrapper>
            {errors.businessName && (
              <StyledErrorMessage>
                {t('Please enter a valid business name.')}
              </StyledErrorMessage>
            )}
          </InputErrorMessageWrapper>
        </SearchForm>
      </HeaderWrapper>

      <CardContentsWrapper>{getBusinessesContent()}</CardContentsWrapper>
    </TabWrapper>
  );
};

export const TabWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
`;

export const TabHeader = styled.div`
  font-size: ${pxToRem(28)};
  font-weight: 400;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
`;

export const CardContentsWrapper = styled.div`
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
`;

export const StyledInput = styled.input`
  color: ${props => props.theme.inputs.textColor};
  font-weight: initial;
  width: ${pxToRem(300)};
  height: ${pxToRem(49)};
  font-size: ${pxToRem(18)};
  border-radius: ${pxToRem(6)};
  border: solid ${pxToRem(2)} ${props => props.theme.inputs.border};
  padding-left: 1.2rem;
`;

export const InputWrapper = styled.div`
  display: flex;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
`;

const SearchForm = styled.form`
  margin-left: 2rem;
`;

const NoResultsText = styled.p`
  font-size: ${pxToRem(18)};
`;
