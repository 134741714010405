import React from 'react';
import { Controller } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import { addDays } from 'date-fns';
import { t } from 'i18next';
import { CalendarIcon } from 'src/components/icons';
import {
  DatePickerCalendarWrapper,
  DatePickerStylesWrapper,
  StyledLabel,
} from './CreatePromotionDatePicker.styled';
import 'react-datepicker/dist/react-datepicker.css';
import { StyledErrorMessage } from '../../CreatePromotion.styled';
import { CreatePromotionExpiryDatePickerProps } from './create-promotion-date-picker.types';
import { CreateBusinessPromotion3Form } from '../../create-promotion-form-types';

export const CreatePromotionExpiryDate: React.FC<
  CreatePromotionExpiryDatePickerProps<CreateBusinessPromotion3Form>
> = ({ control, errors, promotionEndDate, rewardExpiry, clearErrors }) => {
  const endDate = promotionEndDate ?? addDays(new Date(), 1);
  const minimumExpiryDate = addDays(endDate, 1);

  return (
    <div>
      <StyledLabel>
        {t(
          'This is how long the consumer has to redeem the reward from the promotion.',
        )}
      </StyledLabel>
      <Controller
        control={control}
        name={'rewardExpiry'}
        render={ref => {
          const selected = ref.field.value
            ? new Date(Number(ref.field.value))
            : undefined;

          return (
            <DatePickerStylesWrapper>
              <DatePicker
                wrapperClassName="datePicker"
                selected={rewardExpiry ?? selected}
                onChange={(date: Date) => {
                  ref.field.onChange(date);
                  clearErrors('rewardExpiry');
                }}
                minDate={minimumExpiryDate}
                placeholderText="MM/DD/YYYY"
                dateFormat="MM/dd/yyyy"
                id="expiration-date"
              />
              <DatePickerCalendarWrapper htmlFor="expiration-date">
                <CalendarIcon />
              </DatePickerCalendarWrapper>
            </DatePickerStylesWrapper>
          );
        }}
        rules={{ required: true }}
      />
      {errors.rewardExpiry && (
        <StyledErrorMessage>
          {t('Please select an expiration date.')}
        </StyledErrorMessage>
      )}
    </div>
  );
};
