import React, { useEffect } from 'react';
import { Routes, useLocation, useNavigate } from 'react-router-dom';
import { Loading, useAppSelector } from 'src/store';
import {
  getAuthLoadingStatus,
  getFacebookAuthInfo,
} from 'src/store/selectors/auth-selectors';
import { getBusinessUser } from 'src/store/selectors/business-user-selectors';

interface AuthRoutesProps {
  children: React.ReactNode;
}

export const AuthRoutes: React.FC<AuthRoutesProps> = ({ children }) => {
  const facebookAuthInfo = useAppSelector(getFacebookAuthInfo);
  const authLoadingStatus = useAppSelector(getAuthLoadingStatus);
  const businessUser = useAppSelector(getBusinessUser);
  const { pathname, search } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (businessUser && facebookAuthInfo?.status === 'connected') {
      if (!businessUser?.acceptedTermsAndConditions) {
        return navigate('/business-user/terms-and-conditions');
      }

      if (!businessUser.createdProfile) {
        return navigate('/business-user/create-profile');
      }
    }
  }, [facebookAuthInfo, businessUser, pathname, navigate]);

  useEffect(() => {
    if (pathname.includes('encryption')) {
      return;
    }

    if (
      pathname.includes('business-user') &&
      facebookAuthInfo?.status !== 'connected' &&
      authLoadingStatus === Loading.Complete
    ) {
      return navigate(`/business-user/login${search}`);
    }

    if (
      pathname.includes('end-user') &&
      facebookAuthInfo?.status !== 'connected' &&
      authLoadingStatus === Loading.Complete
    ) {
      return navigate(`/end-user/login${search}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Routes>{children}</Routes>;
};
