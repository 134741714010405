import React from 'react';
import { PageWrapper } from 'src/components/layout/PageWrapper';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { BusinessUserDashboardPromotions } from 'src/components/cards/BusinessUserDashboardPromotions';
import { defaultTheme, pxToRem } from 'casper-ui-kit';
import { Link } from 'react-router-dom';

export const BusinessUserDashboard: React.FC = () => {
  const { t } = useTranslation();

  return (
    <PageWrapper>
      <PageHeading>
        <BusinessUserDashboardTitleText>
          {t('Dashboard')}
        </BusinessUserDashboardTitleText>
        <ButtonContainer>
          <BusinessUserDashboardButton to="/business-user/create-promotion">
            Create New Promotion
          </BusinessUserDashboardButton>
          <ScanRewardWrapper>
            <BusinessUserDashboardButton to="/business-user/redeem-customer-reward">
              Scan Reward QR Code
            </BusinessUserDashboardButton>
          </ScanRewardWrapper>
        </ButtonContainer>
      </PageHeading>
      <BusinessUserDashboardPromotions />
    </PageWrapper>
  );
};

export const BusinessUserDashboardTitleText = styled.h1`
  font-size: ${pxToRem(40)};
  white-space: nowrap;
  font-weight: 400;
  margin-bottom: 1.5rem;

  @media (min-width: ${defaultTheme.breakpoints.xl}) {
    margin-bottom: 0;
  }
`;

const ScanRewardWrapper = styled.div`
  display: flex;

  @media (min-width: ${defaultTheme.breakpoints.xl}) {
    display: none;
  }
`;

const BusinessUserDashboardButton = styled(Link)`
  background-color: ${props => props.theme.buttons.bgColor.casperGreen};
  font-size: ${pxToRem(24)};
  font-weight: 500;
  white-space: nowrap;
  padding: ${pxToRem(15)} ${pxToRem(40)};
  width: 100%;
  border: none;
  text-align: center;
  color: ${props => props.theme.textColor.black};
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${pxToRem(365)};
  gap: 2rem;

  @media (min-width: ${defaultTheme.breakpoints.xl}) {
    flex-direction: row;
    max-width: ${pxToRem(680)};
  }
`;

const PageHeading = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 3.2rem;

  @media (min-width: ${defaultTheme.breakpoints.xl}) {
    flex-direction: row;
    justify-content: space-between;
  }
`;
