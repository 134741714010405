import React from 'react';
import styled from '@emotion/styled';
import { defaultTheme, Logo, pxToRem } from 'casper-ui-kit';
import { Link } from 'react-router-dom';
import { CasperRewardsLogo } from 'src/components/logos';

interface DefaultHeaderLogoProps {
  linkTo?: string;
}

export const DefaultHeaderLogo: React.FC<DefaultHeaderLogoProps> = ({
  linkTo,
}) => {
  return (
    <LogoContainer>
      <LogoLink to={linkTo ?? '/'} aria-label="home">
        <CasperRewardsLogoStyled />
      </LogoLink>
    </LogoContainer>
  );
};

export const LogoContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration-line: none;

  :hover,
  :focus {
    text-decoration-line: none;
  }
`;

export const LogoLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration-line: none;
  margin: 0;
  padding: 0;
`;

export const LogoMenu = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  text-decoration-line: none;
  margin: 0;
  padding: 0;
`;

export const ExplorerLogoWrapper = styled.div`
  padding-top: ${pxToRem(1.75)};
  min-width: ${pxToRem(112)};

  @media (min-width: ${defaultTheme.breakpoints.lg}) {
    padding-top: 0;
    width: ${pxToRem(108)};
  }
`;

export const ExplorerLogo = styled(Logo)`
  display: block;
  width: 100%;
`;

export const CasperRewardsLogoStyled = styled(CasperRewardsLogo)`
  width: ${pxToRem(115)};

  @media (min-width: ${defaultTheme.breakpoints.xs}) {
    width: unset;
  }
`;
