import styled from '@emotion/styled';
import { defaultTheme, pxToRem } from 'casper-ui-kit';
import { Link } from 'react-router-dom';

export const StyledInput = styled.input`
  color: ${props => props.theme.inputs.textColor};
  font-weight: initial;
  width: 100%;
  height: ${pxToRem(50)};
  font-size: ${pxToRem(18)};
  border-radius: ${pxToRem(6)};
  border: solid ${pxToRem(2)} ${props => props.theme.inputs.border};
  padding-left: 1.2rem;
  margin-top: 0.4rem;
`;

export const PromptHeadingH3 = styled.h3`
  font-weight: 500;
  font-size: ${pxToRem(20)};
`;

export const StyledErrorMessage = styled.p`
  color: ${props => props.theme.textColor.inputErrorMessage};
  position: absolute;
`;

export const InputErrorMessageWrapper = styled.div`
  position: relative;
  width: 100%;

  @media (min-width: ${defaultTheme.breakpoints.md}) {
    position: relative;
    margin: 0;
  }
`;

export const TitleText = styled.h1`
  font-size: ${pxToRem(40)};
  white-space: nowrap;
  font-weight: 400;
  margin-bottom: 2rem;
`;

export const FileInputLabel = styled.label<{ img: string | undefined }>`
  display: flex;
  width: ${pxToRem(96.7)};
  height: ${pxToRem(96.7)};
  align-items: center;
  justify-content: center;
  border: solid ${pxToRem(2)} ${props => props.theme.inputs.border};
  background-color: ${props => props.theme.inputs.imageUploadBgColor};
  background-image: url(${({ img }) => img || ''});
  background-size: cover;
  background-position: center;
  border-radius: ${pxToRem(8)};
  cursor: pointer;
  margin-bottom: ${pxToRem(4)};
`;

export const FileInputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 2.35rem;
  margin-top: 1.75rem;
`;

export const FileDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RemoveFileButton = styled.button`
  display: flex;
  gap: 0.5rem;
  margin-top: 0.25rem;
  padding: 0;
  align-items: center;
  justify-content: start;
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: ${defaultTheme.colors.primary.CasperRed};
`;

export const FormComponentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
  margin: 3.4rem 0 4.5rem 0;
  width: 100%;
`;

export const PromptWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;

export const StyledLink = styled(Link)`
  text-align: center;
  color: ${props => props.theme.buttons.textColor.black};
  padding: ${pxToRem(10)} ${pxToRem(95)};
  font-size: ${pxToRem(24)};
  background-color: ${props => props.theme.links.bgColor.casperGreen};
  font-weight: ${defaultTheme.typography.fontWeights.medium};

  &:hover {
    text-decoration: none;
  }
`;
export const OptionTitle = styled.h2`
  font-size: ${pxToRem(20)};
  font-weight: 400;
  margin-top: 1rem;

  @media only screen and (min-width: ${defaultTheme.breakpoints.sm}) {
    font-size: ${pxToRem(24)};
  }
`;
