import { AxiosResponse } from 'axios';
import { ApiService } from 'src/api/base-api';
import { setJWT } from 'src/api/helper';

import { ApiData } from 'src/api/types';
import { BusinessUserCreateProfile } from 'src/store/types';

export class BusinessUserRoutes {
  private readonly prefix: string = '/business-user';

  constructor(private readonly api: ApiService) {}

  async authenticateBusinessUser(accessToken: string) {
    type Response = AxiosResponse<{
      jwt: { access_token: string };
      businessUser: ApiData.BusinessUser;
    }>;

    const response = await this.api.post<Response>(
      `${this.prefix}/authenticate`,
      {
        accessToken,
      },
    );

    if (response.status !== 201) throw new Error(response.statusText);

    const {
      jwt: { access_token: token },
      businessUser,
    } = response.data;

    setJWT(token);

    return businessUser;
  }

  async fetchLoggedInBusinessUser() {
    type Response = AxiosResponse<ApiData.BusinessUser>;

    const response = await this.api.get<Response>(
      `${this.prefix}/get-loggedin-user`,
    );

    if (response.status !== 200) throw new Error(response.statusText);

    return response.data;
  }

  async fetchBusinessUserByInstagramHandle(instagramHandle: string) {
    type Response = AxiosResponse<ApiData.BusinessUser>;

    const response = await this.api.get<Response>(
      `${this.prefix}/searchOne?instagramAccount=${instagramHandle}`,
    );

    if (response.status !== 200) throw new Error(response.statusText);

    return response.data;
  }

  async setBusinessUserTermsAndConditions({
    businessUserId,
    termsAndConditions,
  }: {
    businessUserId: string;
    termsAndConditions: null | string;
  }) {
    type Response = AxiosResponse<ApiData.BusinessUser>;

    const response = await this.api.patch<Response>(
      `${this.prefix}/${businessUserId}`,
      {
        acceptedTermsAndConditions: termsAndConditions,
      },
    );

    if (response.status !== 200) throw new Error(response.statusText);

    const { data } = response;

    return data;
  }

  async createProfile({
    businessUserId,
    createProfile,
  }: {
    businessUserId: string;
    createProfile: BusinessUserCreateProfile;
  }) {
    type Response = AxiosResponse<ApiData.BusinessUser>;

    const response = await this.api.patch<Response>(
      `${this.prefix}/${businessUserId}`,
      createProfile,
    );

    if (response.status !== 200) throw new Error(response.statusText);

    const { data } = response;

    return data;
  }
}
