import { AxiosResponse } from 'axios';
import qs from 'query-string';
import { ApiService } from 'src/api/base-api';

import { ApiData } from 'src/api/types';

export class EndUserPromotionRewardRoutes {
  private readonly prefix: string = '/end-user-promotion-reward';

  constructor(private readonly api: ApiService) {}

  async createEndUserPromotionReward(businessPromotionId: string) {
    type Response = AxiosResponse<ApiData.EndUserPromotionReward>;

    const response = await this.api.post<Response>(`${this.prefix}`, {
      businessPromotionId,
    });

    if (response.status !== 201) throw new Error(response.statusText);

    const { data } = response;

    return data;
  }

  async fetchEndUserPromotionReward(id: string) {
    type Response = AxiosResponse<ApiData.EndUserPromotionReward>;

    const response = await this.api.get<Response>(`${this.prefix}/${id}`);

    if (response.status !== 200) throw new Error(response.statusText);

    const { data } = response;

    return data;
  }

  async fetchEndUserPromotionRewardByEncryptedId(id: string) {
    type Response = AxiosResponse<ApiData.EndUserPromotionReward>;

    const urlParams = {
      encryptedId: JSON.stringify(id),
    };

    const url = `${this.prefix}/encrypted/?${qs.stringify(urlParams)}`;

    const response = await this.api.get<Response>(url);

    if (response.status !== 200) throw new Error(response.statusText);

    const { data } = response;

    return data;
  }

  async redeemEndUserPromotionReward(id: string) {
    type Response = AxiosResponse<ApiData.EndUserPromotionReward>;

    const response = await this.api.patch<Response>(
      `${this.prefix}/redeem/${id}`,
      {},
    );

    if (response.status !== 200) throw new Error(response.statusText);

    const { data } = response;

    return data;
  }
}
