import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const DefaultNavigate: React.FC = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (pathname.includes('end-user')) {
      navigate('/end-user/');
    } else if (pathname.includes('business-user')) {
      navigate('/business-user/');
    } else {
      navigate('/page-error');
    }
  }, [navigate, pathname]);

  return null;
};
