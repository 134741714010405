import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { middlewareServiceApi } from 'src/api';
import { ApiData } from 'src/api/types';
import { Loading } from '../loading.type';

export interface EndUserState {
  status: Loading;
  error: AxiosError | null;
  endUserAuthenticationStatus: Loading;
  businessesFetchLoadingStatus: Loading;
  redeemedRewardLoadingStatus: Loading;
  redeemableRewardLoadingStatus: Loading;
  endUser: ApiData.EndUser | null;
  participatingBusinesses: ApiData.BusinessUser[] | null;
  redeemedEndUserReward: ApiData.EndUserPromotionReward[] | null;
  redeemableEndUserReward: ApiData.EndUserPromotionReward[] | null;
}

const initialState: EndUserState = {
  status: Loading.Idle,
  error: null,
  endUserAuthenticationStatus: Loading.Idle,
  businessesFetchLoadingStatus: Loading.Idle,
  redeemedRewardLoadingStatus: Loading.Idle,
  redeemableRewardLoadingStatus: Loading.Idle,
  endUser: null,
  participatingBusinesses: null,
  redeemedEndUserReward: [],
  redeemableEndUserReward: [],
};

// looks up end user based on the JWT
export const fetchLoggedInEndUser = createAsyncThunk(
  'endUser/fetchLoggedInEndUser',
  async () => {
    try {
      const endUser = await middlewareServiceApi.endUser.fetchLoggedInEndUser();

      return endUser;
    } catch (error) {
      throw new Error(
        'An error occurred while getting logged in end user.',
        error as AxiosError,
      );
    }
  },
);

export const fetchEndUser = createAsyncThunk(
  'endUser/fetchEndUser',
  async (id: string, { rejectWithValue }) => {
    try {
      const endUser = await middlewareServiceApi.endUser.fetchEndUser(id);

      return endUser;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const fetchEndUserByFacebookId = createAsyncThunk(
  'endUser/fetchEndUserByFacebookId',
  async (id: string, { rejectWithValue }) => {
    try {
      const endUser =
        await middlewareServiceApi.endUser.fetchEndUserByFacebookId(id);

      return endUser;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const createEndUserByFacebookId = createAsyncThunk(
  'endUser/createEndUserByFacebookId',
  async (id: string, { rejectWithValue }) => {
    try {
      const endUser =
        await middlewareServiceApi.endUser.createEndUserByFacebookId(id);

      return endUser;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const fetchBusinesses = createAsyncThunk(
  'endUser/fetchBusinesses',
  async (search: { businessName: string } | undefined, { rejectWithValue }) => {
    try {
      const participatingBusinesses =
        await middlewareServiceApi.endUser.fetchBusinesses(search);

      return participatingBusinesses;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const fetchRedeemedEndUserReward = createAsyncThunk(
  'endUser/redeemed-reward',
  async (_, { rejectWithValue }) => {
    try {
      const RedeemedEndUserReward =
        await middlewareServiceApi.endUser.fetchRedeemedEndUserRewards();

      return RedeemedEndUserReward;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const fetchRedeemableEndUserReward = createAsyncThunk(
  'endUser/redeemable-reward',
  async (_, { rejectWithValue }) => {
    try {
      const RedeemableEndUserReward =
        await middlewareServiceApi.endUser.fetchRedeemableEndUserRewards();

      return RedeemableEndUserReward;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const authenticateEndUser = createAsyncThunk(
  'endUser/authenticate',
  async (
    {
      facebookId,
      accessToken,
    }: {
      facebookId: string;
      accessToken: string;
    },
    { rejectWithValue },
  ) => {
    try {
      const jwt = await middlewareServiceApi.endUser.authenticateEndUser(
        facebookId,
        accessToken,
      );

      return jwt;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const endUserSlice = createSlice({
  name: 'endUser',
  initialState,
  reducers: {
    clearEndUserRedeemedRewards: state => {
      state.redeemedEndUserReward = null;
    },
    clearEndUserRedeemableRewards: state => {
      state.redeemedEndUserReward = null;
    },
    resetEndUserRedeemedRewardsLoadingStatus: state => {
      state.redeemedRewardLoadingStatus = Loading.Idle;
    },
    resetEndUserRedeemableRewardsLoadingStatus: state => {
      state.redeemableRewardLoadingStatus = Loading.Idle;
    },
    clearParticipatingBusinesses: state => {
      state.participatingBusinesses = null;
    },
    resetParticipatingBusinessesLoadingStatus: state => {
      state.businessesFetchLoadingStatus = Loading.Idle;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchLoggedInEndUser.pending, state => {
        state.status = Loading.Pending;
      })
      .addCase(fetchLoggedInEndUser.fulfilled, (state, { payload }) => {
        state.status = Loading.Complete;
        state.endUser = payload;
      })
      .addCase(fetchLoggedInEndUser.rejected, state => {
        state.status = Loading.Failed;
      })
      .addCase(fetchEndUser.pending, state => {
        state.status = Loading.Pending;
      })
      .addCase(fetchEndUser.fulfilled, (state, { payload }) => {
        state.status = Loading.Complete;
        state.endUser = payload;
      })
      .addCase(fetchEndUser.rejected, (state, { payload }) => {
        state.error = payload as AxiosError;
        state.status = Loading.Failed;
      })
      .addCase(authenticateEndUser.pending, state => {
        state.endUserAuthenticationStatus = Loading.Pending;
      })
      .addCase(authenticateEndUser.fulfilled, (state, { payload }) => {
        state.endUserAuthenticationStatus = Loading.Complete;
        state.endUser = payload;
      })
      .addCase(authenticateEndUser.rejected, (state, { payload }) => {
        state.error = payload as AxiosError;
        state.endUserAuthenticationStatus = Loading.Failed;
      })
      .addCase(fetchEndUserByFacebookId.pending, state => {
        state.status = Loading.Pending;
      })
      .addCase(fetchEndUserByFacebookId.fulfilled, (state, { payload }) => {
        state.status = Loading.Complete;
        state.endUser = payload;
      })
      .addCase(fetchEndUserByFacebookId.rejected, (state, { payload }) => {
        state.error = payload as AxiosError;
        state.status = Loading.Failed;
      })
      .addCase(createEndUserByFacebookId.pending, state => {
        state.status = Loading.Pending;
      })
      .addCase(createEndUserByFacebookId.fulfilled, (state, { payload }) => {
        state.status = Loading.Complete;
        state.endUser = payload;
      })
      .addCase(createEndUserByFacebookId.rejected, (state, { payload }) => {
        state.error = payload as AxiosError;
        state.status = Loading.Failed;
      })
      .addCase(fetchBusinesses.pending, state => {
        state.businessesFetchLoadingStatus = Loading.Pending;
      })
      .addCase(fetchBusinesses.fulfilled, (state, { payload }) => {
        state.businessesFetchLoadingStatus = Loading.Complete;
        state.participatingBusinesses = payload;
      })
      .addCase(fetchBusinesses.rejected, (state, { payload }) => {
        state.error = payload as AxiosError;
        state.businessesFetchLoadingStatus = Loading.Failed;
      })
      .addCase(fetchRedeemableEndUserReward.pending, state => {
        state.redeemableRewardLoadingStatus = Loading.Pending;
      })
      .addCase(fetchRedeemableEndUserReward.fulfilled, (state, { payload }) => {
        state.redeemableRewardLoadingStatus = Loading.Complete;
        state.redeemableEndUserReward = payload;
      })
      .addCase(fetchRedeemableEndUserReward.rejected, (state, { payload }) => {
        state.error = payload as AxiosError;
        state.redeemableRewardLoadingStatus = Loading.Failed;
      })
      .addCase(fetchRedeemedEndUserReward.pending, state => {
        state.redeemedRewardLoadingStatus = Loading.Pending;
      })
      .addCase(fetchRedeemedEndUserReward.fulfilled, (state, { payload }) => {
        state.redeemedRewardLoadingStatus = Loading.Complete;
        state.redeemedEndUserReward = payload;
      })
      .addCase(fetchRedeemedEndUserReward.rejected, (state, { payload }) => {
        state.error = payload as AxiosError;
        state.redeemedRewardLoadingStatus = Loading.Failed;
      });
  },
});

export const {
  clearEndUserRedeemedRewards,
  clearEndUserRedeemableRewards,
  clearParticipatingBusinesses,
  resetParticipatingBusinessesLoadingStatus,
  resetEndUserRedeemedRewardsLoadingStatus,
  resetEndUserRedeemableRewardsLoadingStatus,
} = endUserSlice.actions;
