import React from 'react';
import { useTranslation } from 'react-i18next';
import { CreatePromotionButton } from 'src/components/buttons/CreatePromotionButton';
import { Link } from 'react-router-dom';
import { clRewardsTheme } from 'src/styled-theme';
import { getBusinessPromotion, useAppSelector } from 'src/store';
import { PageError } from 'src/pages/PageError';
import styled from '@emotion/styled';
import {
  PromotionDetailsWrapper,
  StepHeading,
  StepSubHeading,
} from './CreatePromotion.styled';

interface CreatePromotion5Props {
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
}

export const CreatePromotion5: React.FC<CreatePromotion5Props> = ({
  setCurrentStep,
}) => {
  const { t } = useTranslation();

  const businessPromotion = useAppSelector(getBusinessPromotion);

  if (!businessPromotion) {
    return <PageError />;
  }

  const { promotionCaption, promotionHashtag } = businessPromotion;

  return (
    <PromotionDetailsWrapper data-testid="promotion-details">
      <StepHeading data-testid="promotion-status-heading">
        {t('Congratulations—your new promotion is live!')}
      </StepHeading>

      <PromotionDetailsText>{promotionCaption}</PromotionDetailsText>
      <PromotionDetailsText>#{promotionHashtag}</PromotionDetailsText>

      <StepSubHeading data-testid="promotion-management-prompt">
        {t('You can manage your promotion ')}{' '}
        <Link
          data-testid="promotion-management-link"
          to="/business-user/dashboard">
          here.{' '}
        </Link>
        {t(
          'This is how you can make edits, delete, or end your promotion early.',
        )}
      </StepSubHeading>

      <CreatePromotionButton
        type="button"
        bgColor={clRewardsTheme.buttons.bgColor.casperGreen}
        onClick={() => setCurrentStep(0)}>
        {t('Create new promotion')}
      </CreatePromotionButton>
    </PromotionDetailsWrapper>
  );
};

const PromotionDetailsText = styled.p`
  font-size: 1.5rem;
`;
