import React from 'react';
import { useTranslation } from 'react-i18next';
import { withSkeletonLoading } from 'src/components/loading';
import {
  PromotionDescription,
  PromotionHashtag,
} from 'src/components/cards/LivePromotionCard/LivePromotionCard';
import { format } from 'date-fns';
import { CardHeading, CardWrapper } from './ParticipatingBusinessesCard';
import {
  CardData,
  CardDataWrapper,
  CardDescriptionWrapper,
  CardImage,
  CardImageWrapper,
  CardLabel,
  CardTopSectionWrapper,
  DataWrapper,
} from './cards.styled';

export interface RedeemedRewardCardProps {
  readonly promotionName: string;
  readonly promotionImage: string;
  readonly promotionCaption: string;
  readonly promotionHashtag: string;
  readonly pointsForRedemption: number;
  readonly rewardRedemptionDate: string | null;
  readonly rewardClaimDate: string;
  readonly isLoading: boolean;
}

export const RedeemedRewardCard: React.FC<RedeemedRewardCardProps> = ({
  promotionName,
  promotionImage,
  promotionCaption,
  promotionHashtag,
  pointsForRedemption,
  rewardRedemptionDate,
  rewardClaimDate,
  isLoading,
}) => {
  const { t } = useTranslation();

  return (
    <CardWrapper width={417}>
      <CardHeading>{withSkeletonLoading(promotionName, isLoading)}</CardHeading>

      <CardTopSectionWrapper>
        <CardImageWrapper>
          {withSkeletonLoading(
            <CardImage src={promotionImage} alt="promotion" />,
            isLoading,
            { height: '5rem' },
          )}
        </CardImageWrapper>
        <CardDescriptionWrapper>
          <PromotionDescription>
            {withSkeletonLoading(promotionCaption, isLoading, {
              height: '2rem',
            })}
          </PromotionDescription>
          <PromotionHashtag>
            <p>
              {withSkeletonLoading(t('Promotion hashtag:'), isLoading, {
                height: '1rem',
              })}
            </p>
            <p>{withSkeletonLoading(`#${promotionHashtag}`, isLoading)}</p>
          </PromotionHashtag>
        </CardDescriptionWrapper>
      </CardTopSectionWrapper>

      <CardDataWrapper>
        <DataWrapper>
          <CardData>
            {withSkeletonLoading(
              rewardRedemptionDate
                ? format(new Date(rewardRedemptionDate), 'dd-MM-yyyy')
                : '',
              isLoading,
            )}
          </CardData>
          <CardLabel>
            {withSkeletonLoading(t('Date reward redeemed'), isLoading)}
          </CardLabel>
        </DataWrapper>

        <DataWrapper>
          <CardData>
            {withSkeletonLoading(
              format(new Date(rewardClaimDate), 'dd-MM-yyyy'),
              isLoading,
            )}
          </CardData>
          <CardLabel>
            {withSkeletonLoading(t('Date reward claimed'), isLoading)}
          </CardLabel>
        </DataWrapper>

        <DataWrapper>
          <CardData>
            {withSkeletonLoading(`${pointsForRedemption} points`, isLoading)}
          </CardData>
          <CardLabel>
            {withSkeletonLoading(t('Points exchanged for reward'), isLoading)}
          </CardLabel>
        </DataWrapper>
      </CardDataWrapper>
    </CardWrapper>
  );
};
