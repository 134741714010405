import styled from '@emotion/styled';
import React from 'react';
import { defaultTheme, pxToRem } from 'casper-ui-kit';
import { MyRewardsFilterOptions } from 'src/pages/end-user/Dashboard';
import { clRewardsTheme } from 'src/styled-theme';

interface MyRewardToggleFilterProps {
  myRewardFilter: MyRewardsFilterOptions;
  setMyRewardFilter: React.Dispatch<
    React.SetStateAction<MyRewardsFilterOptions>
  >;
}

export const MyRewardToggleFilter: React.FC<MyRewardToggleFilterProps> = ({
  myRewardFilter,
  setMyRewardFilter,
}) => {
  const { Redeemed, ToBeRedeemed } = MyRewardsFilterOptions;
  const handleChange = () => {
    if (myRewardFilter === Redeemed) {
      setMyRewardFilter(ToBeRedeemed);
    } else {
      setMyRewardFilter(Redeemed);
    }
  };

  return (
    <AssetToggleWrapper>
      <Label>
        <Input type="checkbox" onChange={handleChange} />
        <OptionLabel
          myRewardFilter={myRewardFilter}
          optionValue={Redeemed}
          positionLeft={160}>
          Redeemed
        </OptionLabel>
        <OptionLabel
          myRewardFilter={myRewardFilter}
          optionValue={ToBeRedeemed}
          positionLeft={10}>
          To Be Redeemed
        </OptionLabel>
        <Switch currentAssetFilter={myRewardFilter} />
      </Label>
    </AssetToggleWrapper>
  );
};

const OptionLabel = styled.p<{
  myRewardFilter: string;
  positionLeft: number;
  optionValue: string;
}>`
  z-index: 2;
  font-weight: 500;
  position: absolute;
  left: ${({ positionLeft }) => pxToRem(positionLeft)};
  color: ${({ myRewardFilter, optionValue }) =>
    optionValue === myRewardFilter
      ? 'black'
      : defaultTheme.colors.lowContrastSecondary.MediumGrey};
`;

const Label = styled.label`
  display: flex;
  position: relative;
  align-items: center;
  gap: ${pxToRem(10)};
  cursor: pointer;
`;

export const Switch = styled.div<{ currentAssetFilter: string }>`
  position: relative;
  width: ${pxToRem(250)};
  height: 2.5rem;
  background: ${defaultTheme.colors.secondary.Grey84};
  border-radius: ${pxToRem(32)};
  padding: ${pxToRem(4)} 0.5rem;
  border: 3px 'grey' solid;
  transition: 300ms all;

  &:before {
    transition: 300ms all;
    content: '';
    position: absolute;
    width: ${({ currentAssetFilter }) =>
      currentAssetFilter === 'To Be Redeemed'
        ? `${pxToRem(146)}`
        : `${pxToRem(120)}`};
    height: 2.5rem;
    border-radius: ${pxToRem(28)};
    top: 50%;
    left: ${pxToRem(0)};
    background: ${clRewardsTheme.buttons.bgColor.casperGreen};
    transform: translate(
      ${({ currentAssetFilter }) =>
        currentAssetFilter === 'To Be Redeemed' ? 0 : `${pxToRem(140)}`},
      -50%
    );
  }
`;

export const Input = styled.input`
  opacity: 0;
  position: absolute;
`;

export const AssetToggleWrapper = styled.div`
  display: flex;
  padding: 1rem 0;
`;
