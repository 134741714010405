import { AxiosError } from 'axios';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  getAppState,
  setFacebookAuthResponse,
  useAppDispatch,
  useAppSelector,
} from 'src/store';
import { toastStore } from 'src/store/toast';
import useAsyncEffect from 'use-async-effect';

interface ErrorBoundaryProps {
  children: React.ReactNode;
}

export const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({ children }) => {
  const appState = useAppSelector(getAppState);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  // TODO: add to auth store once auth flow has been updated
  const logout = async () => {
    return new Promise((resolve, reject) => {
      window.FB.logout(res => {
        if (res.status !== 'connected') {
          dispatch(setFacebookAuthResponse(res));
          resolve('Successfully logged out from facebook.');
        } else {
          reject(new Error('Could not logout from facebook.'));
        }
      });
    });
  };

  useAsyncEffect(() => {
    // check if any error in store is unauthorized (401) and kick from app
    Object.keys(appState).forEach(async key => {
      const store = appState[key as keyof typeof appState] as object;

      if (
        'error' in store &&
        store.error instanceof AxiosError &&
        store.error.response?.status === 401
      ) {
        let basePathname: string;

        if (pathname.includes('end-user')) {
          basePathname = '/end-user';
        } else if (pathname.includes('business-user')) {
          basePathname = '/business-user';
        } else {
          // TODO: determine ambiguous error page once designs have been created
          basePathname = '';
        }

        toastStore.setErrorToast('Unauthorized access.');

        await logout();

        navigate(`${basePathname}/page-error`, {
          state: {
            unauthorizedError: true,
            descriptionOverride:
              'You are not authorized to access this application.',
          },
        });
      }
    });
  }, [appState]);

  return <div>{children}</div>;
};
