import { toastStore } from 'src/store/toast';

export const logoutJwtLocalStorage = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('tokenExpiry');

  const tokenLocalStorage = localStorage.getItem('token');
  const tokenExpiryLocalStorage = localStorage.getItem('tokenExpiry');

  if (tokenLocalStorage || tokenExpiryLocalStorage) {
    const logoutError = 'Could not logout via JWT.';

    toastStore.setErrorToast(logoutError);
    throw new Error(logoutError);
  }
};
