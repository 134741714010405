import React from 'react';
import styled from '@emotion/styled';
import { SubmitHandler, useForm } from 'react-hook-form';
import { t } from 'i18next';
import { CreatePromotionButton } from 'src/components/buttons/CreatePromotionButton';
import { EngagementLevelUi } from 'src/components/create-promotion/steps/Partials/EngagementLevel/EngagementLevelUi';
import { ButtonsContainer } from 'src/pages/CreatePromotion';
import {
  getCreateBusinessPromotionFormData,
  setCreateBusinessPromotionFormData,
  useAppDispatch,
  useAppSelector,
} from 'src/store';
import { clRewardsTheme } from 'src/styled-theme';
import { defaultTheme } from 'casper-ui-kit';
import {
  FormComponentsWrapper,
  Prompt,
  PromptWrapper,
} from './CreatePromotion.styled';
import { CreateBusinessPromotion2Form } from './create-promotion-form-types';

interface CreatePromotion2Props {
  handleContinue: () => void;
  handleBack: () => void;
}

export const CreatePromotion2: React.FC<CreatePromotion2Props> = ({
  handleContinue,
  handleBack,
}) => {
  const { page2FormData } = useAppSelector(getCreateBusinessPromotionFormData);

  const { pointsForEngagement, engagementLevel, pointsForRedemption } = {
    ...page2FormData,
  };

  // TODO: uncomment once referrals are supported
  // const [isReferring, setIsReferring] = useState<boolean | undefined>(referral);

  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm<CreateBusinessPromotion2Form>();

  const onSubmit: SubmitHandler<CreateBusinessPromotion2Form> = data => {
    dispatch(
      setCreateBusinessPromotionFormData({
        page2FormData: {
          ...data,
          referral: false,
        },
      }),
    );
    handleContinue();
  };

  return (
    <form
      id="create-promotion-2"
      onSubmit={handleSubmit(onSubmit)}
      autoComplete="off">
      <FormComponentsWrapper>
        <PromptWrapper>
          <Prompt>
            {t(
              'Set the engagement level for customers to achieve the rewards for this promotion.',
            )}
          </Prompt>
          <EngagementLevelUi
            data-testid="engagement-level-ui"
            register={register}
            errors={errors}
            setValue={setValue}
            engagementLevel={engagementLevel || 'Light'}
          />
        </PromptWrapper>
        <PromptWrapper>
          <Prompt>
            {`${t('I want to reward')} `}

            <HiddenInput
              data-testid="engagement-points"
              placeholder="Enter number"
              defaultValue={pointsForEngagement}
              {...register('pointsForEngagement', {
                required: true,
                pattern: /^[1-9][0-9]?$|^100$/,
                max: pointsForRedemption,
              })}
            />
            <EngagementValue addMargin>
              {watch('pointsForEngagement')}
            </EngagementValue>
            {` ${t(
              'points to each customer each time they engage with the promotion.',
            )}`}
          </Prompt>
        </PromptWrapper>
        <PromptWrapper>
          <Prompt>
            {`${t(
              'How many points do customers need to use to redeem the promotion?',
            )} `}
          </Prompt>
          <HiddenInput
            data-testid="redemption-points"
            placeholder="Enter number"
            defaultValue={pointsForRedemption}
            {...register('pointsForRedemption', {
              required: true,
              pattern: /^[1-9][0-9]?$|^100$/,
            })}
          />
          <EngagementValue>{watch('pointsForRedemption')}</EngagementValue>
        </PromptWrapper>
        <LastPromptContainer>
          {/* TODO: uncomment once referrals are supported
          <PromptWrapper>
            <Prompt>{t('Support friend referrals?')}</Prompt>
            <CreatePromotionReferralCheckboxes
              data-testid="referral-checkboxes"
              register={register}
              errors={errors}
              isReferring={isReferring}
              setIsReferring={setIsReferring}
              setValue={setValue}
              referral={referral}
            />
          </PromptWrapper>
          <PromptWrapper>
            <Prompt>
              {t(
                'If yes, how many points do you want to reward for each referral?',
              )}
            </Prompt>
            <InputErrorMessageWrapper>
              <StyledInput
                data-testid="referral-points"
                placeholder="Enter number"
                disabled={isReferring === false}
                defaultValue={pointsForReferral}
                {...register('pointsForReferral', {
                  required: isReferring,
                  pattern: /^[1-9][0-9]?$|^100$/,
                  max: pointsForRedemption,
                })}
              />
              {errors.pointsForReferral && (
                <ReferralErrorMessage data-testid="referral-points-error-message">
                  {t(
                    'Please enter a number less than or equal to the number of points required to redeem the promotion.',
                  )}
                </ReferralErrorMessage>
              )}
            </InputErrorMessageWrapper>
          </PromptWrapper> */}
        </LastPromptContainer>
        <ButtonsContainer>
          <CreatePromotionButton
            testId="cp-back"
            type="button"
            bgColor={clRewardsTheme.buttons.bgColor.white}
            onClick={handleBack}>
            Go back
          </CreatePromotionButton>

          <CreatePromotionButton
            testId="cp2-continue"
            type="submit"
            bgColor={clRewardsTheme.buttons.bgColor.casperGreen}>
            Continue
          </CreatePromotionButton>
        </ButtonsContainer>
      </FormComponentsWrapper>
    </form>
  );
};

const LastPromptContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 3.5rem;
  margin-top: 1rem;
`;

export const ReferralErrorMessage = styled.p`
  color: ${props => props.theme.textColor.inputErrorMessage};
  position: absolute;
  line-height: 1.1;
  margin-top: 0.25rem;

  @media (min-width: ${defaultTheme.breakpoints.md}) {
    max-width: 65%;
  }
`;

const EngagementValue = styled.span<{ addMargin?: boolean }>`
  display: inline-block;
  font-weight: 500;
  color: black;
  font-size: 1.5rem;
  width: fit-content;
  border-bottom: solid ${({ theme }) => theme.buttons.bgColor.casperGreen}
    0.25rem;
  line-height: 1.1;
  margin: ${({ addMargin }) => (addMargin ? '0 0.25rem' : '0')};
`;

const HiddenInput = styled.input`
  display: none;
`;
