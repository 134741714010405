import styled from '@emotion/styled';
import { defaultTheme } from 'casper-ui-kit';
import { clRewardsTheme } from 'src/styled-theme';

export const HeaderComponent = styled.header`
  width: 100%;
  background-color: ${clRewardsTheme.backgrounds.headerBlue};
`;

export const HeaderComponentsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: auto;
  margin: 0 1.5rem;
  padding: 0;

  @media (min-width: ${defaultTheme.breakpoints.xl}) {
    width: auto;
    margin: 0 4rem;
    padding: 0;
  }
`;

export const PageTableHeader = styled.h1`
  font-weight: ${defaultTheme.typography.fontWeights.light};
  font-size: clamp(2.7rem, 5.5vw, 3.75rem);
  margin: 1.5rem 0 2.5rem 0;
  color: ${props => props.theme.textColor.black};

  @media (min-width: ${defaultTheme.breakpoints.xl}) {
    margin: 0 0 2.5rem 0;
  }
`;
