import React, { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import 'react-datepicker/dist/react-datepicker.css';
import { t } from 'i18next';
import { CreatePromotionButton } from 'src/components/buttons/CreatePromotionButton';
import { ButtonsContainer } from 'src/pages/CreatePromotion';
import {
  getCreateBusinessPromotionFormData,
  setCreateBusinessPromotionFormData,
  useAppDispatch,
  useAppSelector,
} from 'src/store';
import { clRewardsTheme } from 'src/styled-theme';
import { getHasAttainableEndUserReward } from 'src/utils';
import {
  FormComponentsWrapper,
  InputErrorMessageWrapper,
  Prompt,
  PromptWrapper,
  StyledErrorMessage,
  StyledInput,
} from './CreatePromotion.styled';
import { CreatePromotionStartEndDates } from './Partials/CreatePromotionDatePicker/CreatePromotionStartEndDates';
import { CreatePromotionDayCheckboxes } from './Partials/CreatePromotionCheckboxes/CreatePromotionDayCheckboxes';
import { CreatePromotionExpiryDate } from './Partials/CreatePromotionDatePicker/CreatePromotionExpiryDate';
import { CreateBusinessPromotion3Form } from './create-promotion-form-types';

interface CreatePromotion3Props {
  handleContinue: () => void;
  handleBack: () => void;
}

export const CreatePromotion3: React.FC<CreatePromotion3Props> = ({
  handleContinue,
  handleBack,
}) => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm<CreateBusinessPromotion3Form>();

  const dispatch = useAppDispatch();

  const { page2FormData, page3FormData } = useAppSelector(
    getCreateBusinessPromotionFormData,
  );

  const {
    promotionStartDate,
    promotionEndDate,
    rewardExpiry,
    rewardQuantity,
    promotionDays,
  } = { ...page3FormData };

  useEffect(() => {
    if (promotionStartDate) setValue('promotionStartDate', promotionStartDate);
    if (promotionEndDate) setValue('promotionEndDate', promotionEndDate);
    if (rewardExpiry) setValue('rewardExpiry', rewardExpiry);
    if (promotionDays) setValue('promotionDays', promotionDays);
  }, [
    promotionStartDate,
    promotionEndDate,
    rewardExpiry,
    promotionDays,
    setValue,
  ]);

  const onSubmit: SubmitHandler<CreateBusinessPromotion3Form> = data => {
    if (!page2FormData) {
      throw new Error('Not able to calculate reward attainability.');
    }

    const hasAttainableReward = getHasAttainableEndUserReward(
      page2FormData,
      data,
    );

    if (!hasAttainableReward) {
      return setError('root', {
        message:
          'Reward is not attainable based on current schedule and engagement level.',
      });
    }

    dispatch(setCreateBusinessPromotionFormData({ page3FormData: data }));
    handleContinue();
  };

  const promotionEndDateWatched = watch('promotionEndDate');

  const minNumOfOneRegex = /^[1-9][0-9]*$/;

  return (
    <form
      id="create-promotion-3"
      onSubmit={handleSubmit(onSubmit)}
      autoComplete="off">
      <FormComponentsWrapper>
        <PromptWrapper>
          <Prompt>{t('How often would you like to promote?')}</Prompt>
          <CreatePromotionDayCheckboxes
            register={register}
            errors={errors}
            selectedValues={promotionDays ?? []}
          />
        </PromptWrapper>
        <PromptWrapper>
          <Prompt>{t('How long will this promotion last?')}</Prompt>
          <CreatePromotionStartEndDates
            control={control}
            errors={errors}
            promotionStartDate={promotionStartDate}
            promotionEndDate={promotionEndDate}
            watch={watch}
            clearErrors={clearErrors}
          />
        </PromptWrapper>
        <PromptWrapper>
          <Prompt>{t('What is the expiration for this promotion?')}</Prompt>
          <CreatePromotionExpiryDate
            control={control}
            errors={errors}
            promotionEndDate={promotionEndDateWatched}
            rewardExpiry={rewardExpiry}
            clearErrors={clearErrors}
          />
        </PromptWrapper>
        <PromptWrapper>
          <Prompt>
            {t(
              'How many units of each item do you want to distribute through this promotion?',
            )}
          </Prompt>
          <InputErrorMessageWrapper>
            <StyledInput
              placeholder="Enter number"
              {...register('rewardQuantity', {
                required: true,
                pattern: minNumOfOneRegex,
              })}
              defaultValue={rewardQuantity}
            />
            {errors.rewardQuantity && (
              <StyledErrorMessage>
                {t('Please enter a number greater than zero.')}
              </StyledErrorMessage>
            )}
          </InputErrorMessageWrapper>
        </PromptWrapper>
        <InputErrorMessageWrapper>
          <StyledErrorMessage>{errors.root?.message}</StyledErrorMessage>
        </InputErrorMessageWrapper>
        <ButtonsContainer>
          <CreatePromotionButton
            type="button"
            bgColor={clRewardsTheme.buttons.bgColor.white}
            onClick={handleBack}>
            Go back
          </CreatePromotionButton>

          <CreatePromotionButton
            testId="cp3-continue"
            type="submit"
            bgColor={clRewardsTheme.buttons.bgColor.casperGreen}>
            Continue
          </CreatePromotionButton>
        </ButtonsContainer>
      </FormComponentsWrapper>
    </form>
  );
};
