import styled from '@emotion/styled';
import { defaultTheme } from 'casper-ui-kit';
import React, { useState } from 'react';
import { Navbar } from 'src/components/layout';
import { useScrollLock } from 'src/hooks';
import { CasperRewardsLogo } from 'src/components/logos';
import { Link, useNavigate } from 'react-router-dom';
import { t } from 'i18next';

const businessNavItems = [
  {
    title: `${t('Dashboard')}`,
    path: 'business-user/dashboard',
    key: 'dashboard',
  },
  {
    title: `${t('Create Promotion')}`,
    path: 'business-user/create-promotion',
    key: 'create-promo',
  },
  {
    title: `${t('Scan Reward')}`,
    path: 'business-user/scan-reward',
    key: 'scan-reward',
  },
];

export const BusinessUserMobileHeader: React.FC = () => {
  const [isOpened, setIsOpened] = useState(false);
  const { lockScroll, unlockScroll } = useScrollLock();
  const windowWidth = window.innerWidth || 0;

  const navigate = useNavigate();

  const handleHomePageLink = () => {
    navigate('/');
  };

  const openNav = () => {
    setIsOpened(true);
    lockScroll();
  };
  const closeNav = () => {
    setIsOpened(false);
    unlockScroll();
  };

  return (
    <HeaderWrapper>
      <LogoWrapper
        to="#"
        aria-label="home"
        data-testid="casper-logo-link"
        onClick={handleHomePageLink}>
        <CasperRewardsLogo width={115} />
      </LogoWrapper>
      <NavWrapper>
        <Navbar
          windowWidth={windowWidth}
          openNav={openNav}
          closeNav={closeNav}
          isOpened={isOpened}
          navItems={businessNavItems}
          userType="business-user"
        />
      </NavWrapper>
    </HeaderWrapper>
  );
};

const HeaderWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  margin-bottom: 1rem;

  @media (min-width: ${defaultTheme.breakpoints.xl}) {
    display: flex;
    justify-content: space-between;
  }
`;

const LogoWrapper = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  grid-column-start: 2;

  @media (min-width: ${defaultTheme.breakpoints.xl}) {
    display: block;
    width: fit-content;
    margin: auto 0;
  }
`;
const NavWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  grid-column-start: 3;

  @media (min-width: ${defaultTheme.breakpoints.xl}) {
    display: block;
    width: fit-content;
    margin: 0;
  }
`;
