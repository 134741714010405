import React from 'react';
import { CreatePromotion1 } from './CreatePromotion1';
import { CreatePromotion2 } from './CreatePromotion2';
import { CreatePromotion3 } from './CreatePromotion3';
import { CreatePromotion5 } from './CreatePromotion5';
import { CreatePromotionReview } from './CreatePromotionReview';

export interface CreatePromotionFormControllerProps {
  currentStep: number;
  handleContinue: () => void;
  handleBack: () => void;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
}

export const CreatePromotionFormController: React.FC<
  CreatePromotionFormControllerProps
> = ({ currentStep, handleContinue, handleBack, setCurrentStep }) => {
  switch (currentStep) {
    case 0: {
      return <CreatePromotion1 handleContinue={handleContinue} />;
    }
    case 1: {
      return (
        <CreatePromotion2
          handleContinue={handleContinue}
          handleBack={handleBack}
        />
      );
    }
    case 2: {
      return (
        <CreatePromotion3
          handleContinue={handleContinue}
          handleBack={handleBack}
        />
      );
    }
    case 3: {
      return (
        <CreatePromotionReview
          handleContinue={handleContinue}
          handleBack={handleBack}
        />
      );
    }
    case 4: {
      return <CreatePromotion5 setCurrentStep={setCurrentStep} />;
    }

    default: {
      throw new Error('Step out of range.');
    }
  }
};
