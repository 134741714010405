import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RedeemableRewardCards } from 'src/components/cards/RewardCard/RedeemableRewardCards';
import { RedeemedRewardCards } from 'src/components/cards/RewardCard/RedeemedRewardCards';
import { MyRewardToggleFilter } from 'src/components/filters/MyRewardToggleFilter';
import { MyRewardsFilterOptions } from 'src/pages/end-user/Dashboard';
import {
  clearEndUserRedeemableRewards,
  clearEndUserRedeemedRewards,
  resetEndUserRedeemableRewardsLoadingStatus,
  resetEndUserRedeemedRewardsLoadingStatus,
  useAppDispatch,
} from 'src/store';
import { OptionTitle } from '../../../pages/SharedStyles/pages.styled';

export const EndUserRewards: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [myRewardFilter, setMyRewardFilter] = useState(
    MyRewardsFilterOptions.ToBeRedeemed,
  );

  useEffect(() => {
    return () => {
      dispatch(clearEndUserRedeemedRewards());
      dispatch(clearEndUserRedeemableRewards());
      dispatch(resetEndUserRedeemableRewardsLoadingStatus());
      dispatch(resetEndUserRedeemedRewardsLoadingStatus());
    };
  }, [dispatch]);

  const renderRewardsCards = (myRewardsFilter: MyRewardsFilterOptions) => {
    switch (myRewardsFilter) {
      case MyRewardsFilterOptions.Redeemed:
        return <RedeemedRewardCards />;
      case MyRewardsFilterOptions.ToBeRedeemed:
        return <RedeemableRewardCards />;
      default:
        break;
    }
  };

  return (
    <div>
      <OptionTitle>{t('My Rewards')}</OptionTitle>
      <MyRewardToggleFilter
        myRewardFilter={myRewardFilter}
        setMyRewardFilter={setMyRewardFilter}
      />
      {renderRewardsCards(myRewardFilter)}
    </div>
  );
};
