import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { t } from 'i18next';
import { defaultTheme, pxToRem } from 'casper-ui-kit';
import { DefaultHeaderLogo } from 'src/components/layout';
import { PageWrapper } from 'src/components/layout/PageWrapper';
import {
  Loading,
  authenticateBusinessUser,
  fetchBusinessUserByInstagramHandle,
  resetLoadingStatus,
  setFacebookAuthResponse,
  useAppDispatch,
  useAppSelector,
} from 'src/store';
import { ApiData } from 'src/api/types';
import { SubmitHandler, useForm } from 'react-hook-form';
import { loadConfig } from 'src/utils';
import { toastStore } from 'src/store/toast';
import { ButtonWithLoading } from 'src/components/buttons/ButtonWithLoading';
import { clRewardsTheme } from 'src/styled-theme';
import {
  getBusinessUserAuthLoadingStatus,
  getBusinessUserLoadingStatus,
} from 'src/store/selectors/business-user-selectors';
import { ExtendedLoginOptions } from 'src/types/facebook';
import checkmarkIcon from '../assets/icons/checkmark-icon.svg';
import {
  InputErrorMessageWrapper,
  PromptHeadingH3,
  StyledErrorMessage,
  StyledInput,
} from './SharedStyles/pages.styled';

const logo = <DefaultHeaderLogo aria-label="home" linkTo="#" />;

export interface InstagramHandleFormValues {
  instagramHandle: string;
}

export const BusinessUserLoginPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isLoginLoading, setIsLoadingLoading] = useState(false);
  const { fbAppBusinessUserConfigId } = loadConfig();

  const fetchBusinessUserLoadingStatus = useAppSelector(
    getBusinessUserLoadingStatus,
  );
  const fetchBusinessUserAuthLoadingStatus = useAppSelector(
    getBusinessUserAuthLoadingStatus,
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<InstagramHandleFormValues>();

  useEffect(() => {
    return () => {
      dispatch(resetLoadingStatus());
    };
  }, [dispatch]);

  const logout = async () => {
    return new Promise((resolve, reject) => {
      window.FB.logout(res => {
        if (res.status !== 'connected') {
          dispatch(setFacebookAuthResponse(res));
          resolve('Successfully logged out from facebook.');
        } else {
          reject(new Error('Could not logout from facebook.'));
        }
      });
    });
  };

  const onSubmit: SubmitHandler<InstagramHandleFormValues> = async ({
    instagramHandle,
  }) => {
    let parsedInstagramHandle = instagramHandle;
    if (instagramHandle[0] === '@') {
      parsedInstagramHandle = instagramHandle.substring(1);
    }

    setIsLoadingLoading(true);
    const { payload } = (await dispatch(
      fetchBusinessUserByInstagramHandle(parsedInstagramHandle),
    )) as { payload: ApiData.BusinessUser };

    if (!payload) {
      navigate('/business-user/sign-up-failure');
      setIsLoadingLoading(false);
    } else {
      window.FB.login(
        response => {
          const facebookAuthResponse = response;

          dispatch(setFacebookAuthResponse(facebookAuthResponse));

          if (facebookAuthResponse.status === 'connected') {
            // TODO
            // check 'data_access_expiration_time' and get them to re-auth:

            (async () => {
              const { meta } = await dispatch(
                authenticateBusinessUser(
                  facebookAuthResponse.authResponse.accessToken,
                ),
              );

              toastStore.processActionMetaWithToast(meta.requestStatus, {
                success: 'Logged in successfully',
                error: 'Error logging in.',
              });

              if (meta.requestStatus === 'rejected') {
                await logout();
                setIsLoadingLoading(false);

                return navigate('/business-user/page-error', {
                  state: { unauthorizedError: true },
                });
              }

              let navigateUrl: string;

              if (!payload.acceptedTermsAndConditions) {
                navigateUrl = '/business-user/terms-and-conditions';
              } else if (!payload.createdProfile) {
                navigateUrl = '/business-user/create-profile';
              } else {
                navigateUrl = '/business-user/dashboard';
              }

              navigate(navigateUrl);
            })();
          } else {
            navigate('/business-user/page-error', {
              state: { unauthorizedError: true },
            });
          }

          setIsLoadingLoading(false);
        },
        {
          config_id: fbAppBusinessUserConfigId,
          response_type: 'token',
          override_default_response_type: true,
        } as fb.LoginOptions & ExtendedLoginOptions,
      );
    }
  };

  const isFetchBusinessUserLoading =
    fetchBusinessUserLoadingStatus === Loading.Pending ||
    fetchBusinessUserAuthLoadingStatus === Loading.Pending;
  const isFetchBusinessUserFailed =
    fetchBusinessUserLoadingStatus === Loading.Failed ||
    fetchBusinessUserAuthLoadingStatus === Loading.Failed;

  const isLoginButtonDisabled =
    isLoginLoading || isFetchBusinessUserLoading || isFetchBusinessUserFailed;

  return (
    <PageWrapper>
      <BusinessUserLoginPageComponents onSubmit={handleSubmit(onSubmit)}>
        <LogoWrapper>{logo}</LogoWrapper>

        <InstagramHandleWrapper>
          <InputErrorMessageWrapper>
            <PromptHeadingH3>
              {t('Enter business Instagram handle')}
            </PromptHeadingH3>
            <StyledInput
              placeholder="@instagram"
              {...register('instagramHandle', {
                required: true,
                pattern: /^(?:@?[a-zA-Z0-9_.]+)$/,
                maxLength: 30,
              })}
            />
            {errors.instagramHandle && (
              <StyledErrorMessage>
                {t('Please enter your Instagram handle.')}
              </StyledErrorMessage>
            )}
          </InputErrorMessageWrapper>
        </InstagramHandleWrapper>

        <FacebookButton
          type="submit"
          disabled={isLoginButtonDisabled}
          isLoading={isLoginLoading}>
          Continue with Facebook
        </FacebookButton>
      </BusinessUserLoginPageComponents>
    </PageWrapper>
  );
};

export const BusinessUserLoginPageComponents = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: ${pxToRem(700)};
  margin: 0 auto;
`;

const LogoWrapper = styled.div`
  margin-top: ${pxToRem(40)};
  margin-bottom: 2rem;

  * {
    fill: black;
  }
`;

const FacebookButton = styled(ButtonWithLoading)`
  text-align: center;
  height: ${pxToRem(45)};
  width: 100%;
  background-color: ${clRewardsTheme.buttons.bgColor.facebookBlue};
  color: ${clRewardsTheme.buttons.textColor.white};
  font-size: 0.9rem;
  font-weight: 500;
  border-radius: 0.25rem;
  margin-bottom: 0.75rem;
  letter-spacing: 0.025rem;
  max-width: ${pxToRem(524)};

  @media (min-width: ${defaultTheme.breakpoints.xs}) {
    margin-top: 2rem;
    font-size: 1rem;
    height: ${pxToRem(55)};
  }
`;

export const TermsAndConditionsErrorMessageWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const TermsAndConditionsCheckbox = styled.input`
  background-color: ${props => props.theme.inputs.bgColor};
  width: 1.6rem;
  height: 1.6rem;
  margin: 0 0.5rem 0 0;
  border: solid ${pxToRem(2)} ${props => props.theme.inputs.border};
  border-radius: ${pxToRem(6)};
  -webkit-appearance: none;
  appearance: none;

  :checked {
    background-image: url(${checkmarkIcon});
  }
`;

export const InstagramHandleWrapper = styled.div`
  display: flex;
  justify-content: center;
  max-width: 32.75rem;
  width: 100%;
  margin: 2rem 0 1.5rem;
`;

export const TermAndConditionsText = styled.p`
  font-size: 1rem;
`;
