import { AxiosResponse } from 'axios';
import { ApiService } from 'src/api/base-api';

export class FileUploadRoutes {
  private readonly prefix: string = '/file';

  constructor(private readonly api: ApiService) {}

  async uploadFile(file: File) {
    type Response = AxiosResponse<string>;

    const formData = new FormData();

    formData.append('file', file);

    const response = await this.api.post<Response>(
      `${this.prefix}/upload`,
      formData,
      { headers: { 'content-type': 'multipart/form-data' } },
    );

    if (response.status !== 201) throw new Error(response.statusText);

    const { data } = response;

    return data;
  }
}
