import React from 'react';
import { useTranslation } from 'react-i18next';
import { withSkeletonLoading } from 'src/components/loading';
import {
  PromotionDescription,
  PromotionHashtag,
} from 'src/components/cards/LivePromotionCard/LivePromotionCard';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { toastStore } from 'src/store/toast';
import styled from '@emotion/styled';
import { pxToRem } from 'casper-ui-kit';
import { clRewardsTheme } from 'src/styled-theme';
import { CardHeading, CardWrapper } from './ParticipatingBusinessesCard';
import {
  CardData,
  CardDataWrapper,
  CardDescriptionWrapper,
  CardImage,
  CardImageWrapper,
  CardLabel,
  CardTopSectionWrapper,
  DataWrapper,
} from './cards.styled';

export interface RedeemableRewardCardProps {
  readonly promotionName: string;
  readonly promotionImage: string;
  readonly promotionCaption: string;
  readonly promotionHashtag: string;
  readonly rewardClaimDate: string;
  readonly rewardExpiry: string;
  readonly rewardQuantity: number;
  readonly isLoading: boolean;
  readonly businessPromotionId?: string;
  readonly endUserPromotionRewardId?: string;
}

export const RedeemableRewardCard: React.FC<RedeemableRewardCardProps> = ({
  promotionName,
  promotionImage,
  promotionCaption,
  promotionHashtag,
  rewardClaimDate,
  rewardExpiry,
  rewardQuantity,
  isLoading,
  businessPromotionId,
  endUserPromotionRewardId,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleNavigateToRewardDetails = () => {
    if (businessPromotionId && endUserPromotionRewardId) {
      navigate(
        `/end-user/redeem-points?rewardId=${endUserPromotionRewardId}&promotionId=${businessPromotionId}`,
      );
    } else {
      toastStore.setErrorToast('Error viewing QR code.', undefined, true);
      throw new Error(
        'Invalid businessPromotionId or endUserPromotionRewardId.',
      );
    }
  };

  return (
    <CardWrapper width={417}>
      <CardHeading>{withSkeletonLoading(promotionName, isLoading)}</CardHeading>

      <CardTopSectionWrapper>
        <CardImageWrapper>
          {withSkeletonLoading(
            <CardImage src={promotionImage} alt="promotion" />,
            isLoading,
            { height: '5rem' },
          )}
        </CardImageWrapper>
        <CardDescriptionWrapper>
          <PromotionDescription>
            {withSkeletonLoading(promotionCaption, isLoading, {
              height: '2rem',
            })}
          </PromotionDescription>
          <PromotionHashtag>
            <p>
              {withSkeletonLoading(t('Promotion hashtag:'), isLoading, {
                height: '1rem',
              })}
            </p>
            <p>{withSkeletonLoading(`#${promotionHashtag}`, isLoading)}</p>
          </PromotionHashtag>
        </CardDescriptionWrapper>
      </CardTopSectionWrapper>

      <CardDataWrapper>
        <DataWrapper>
          <CardData>
            {withSkeletonLoading(
              format(new Date(rewardClaimDate), 'dd-MM-yyyy'),
              isLoading,
            )}
          </CardData>
          <CardLabel>
            {withSkeletonLoading(t('Date reward claimed'), isLoading)}
          </CardLabel>
        </DataWrapper>

        <DataWrapper>
          <CardData>{withSkeletonLoading(rewardQuantity, isLoading)}</CardData>
          <CardLabel>
            {withSkeletonLoading(t('Remaining Promotional Items'), isLoading)}
          </CardLabel>
        </DataWrapper>

        <DataWrapper>
          <CardData>
            {withSkeletonLoading(
              format(new Date(rewardExpiry), 'dd-MM-yyyy'),
              isLoading,
            )}
          </CardData>
          <CardLabel>
            {withSkeletonLoading(t('Reward expiration date'), isLoading)}
          </CardLabel>
        </DataWrapper>
      </CardDataWrapper>

      <CardDataWrapper>
        <ViewQRCodeButton type="button" onClick={handleNavigateToRewardDetails}>
          {t('View QR code')}
        </ViewQRCodeButton>
      </CardDataWrapper>
    </CardWrapper>
  );
};

const ViewQRCodeButton = styled.button`
  all: unset;
  cursor: pointer;
  text-align: center;
  margin-top: 0.5rem;
  height: ${pxToRem(49)};
  width: ${pxToRem(229)};
  background-color: ${() => clRewardsTheme.buttons.bgColor.casperGreen};
  color: ${() => clRewardsTheme.buttons.textColor.black};
  font-size: ${pxToRem(18)};
  font-weight: 500;
`;
