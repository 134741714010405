import { loadConfig } from 'src/utils';
import { createBaseApi } from './base-api';
import {
  AuthRoutes,
  BusinessUserRoutes,
  BusinessPromotionRoutes,
  EndUserRoutes,
  EndUserPromotionEngagementRoutes,
  EndUserPromotionRewardRoutes,
  FileUploadRoutes,
} from './routes';

const { baseApiUrl } = loadConfig();

const createApi = (baseUrl: string) => {
  const middlewareApi = createBaseApi(baseUrl);

  return {
    auth: new AuthRoutes(middlewareApi),
    businessUser: new BusinessUserRoutes(middlewareApi),
    businessPromotion: new BusinessPromotionRoutes(middlewareApi),
    endUser: new EndUserRoutes(middlewareApi),
    endUserPromotionEngagement: new EndUserPromotionEngagementRoutes(
      middlewareApi,
    ),
    endUserPromotionReward: new EndUserPromotionRewardRoutes(middlewareApi),
    fileUpload: new FileUploadRoutes(middlewareApi),
  };
};

export const middlewareServiceApi = createApi(baseApiUrl);
