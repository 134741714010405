import React from 'react';
import {
  LivePromotionsTab,
  ParticipatingBusinessesTab,
  RewardsTab,
} from 'src/components/end-user-dashboard/desktop-view';

export const DesktopDashboard: React.FC = () => {
  return (
    <>
      <ParticipatingBusinessesTab />
      <LivePromotionsTab />
      <RewardsTab />
    </>
  );
};
