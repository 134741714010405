import styled from '@emotion/styled';
import { pxToRem } from 'casper-ui-kit';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ButtonWithLoading } from 'src/components/buttons/ButtonWithLoading';
import { PageWrapper } from 'src/components/layout/PageWrapper';
import { setFacebookAuthResponse, useAppDispatch } from 'src/store';
import { toastStore } from 'src/store/toast';
import { clRewardsTheme } from 'src/styled-theme';
import { logoutJwtLocalStorage } from 'src/utils';

interface MyAccountProps {
  userType: 'end-user' | 'business-user';
}

export const MyAccount: React.FC<MyAccountProps> = ({ userType }) => {
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    setIsLoading(true);

    window.FB.logout(res => {
      if (res.status !== 'connected') {
        logoutJwtLocalStorage();

        dispatch(setFacebookAuthResponse(res));

        navigate(`/${userType}/login`);

        toastStore.setSuccessToast('Logged out successfully');
      } else {
        toastStore.setErrorToast('Could not logout via facebook.');
      }

      setIsLoading(false);
    });
  };

  return (
    <PageWrapper>
      <LogoutButtonWrapper>
        <LogoutButton
          type="button"
          onClick={handleLogout}
          isLoading={isLoading}>
          Logout
        </LogoutButton>
      </LogoutButtonWrapper>
    </PageWrapper>
  );
};

const LogoutButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const LogoutButton = styled(ButtonWithLoading)`
  text-align: center;
  height: ${pxToRem(45)};
  width: 100%;
  max-width: ${pxToRem(220)};
  font-weight: 500;
  color: ${() => clRewardsTheme.buttons.textColor.black};
  background-color: ${() => clRewardsTheme.buttons.bgColor.casperGreen};
`;
