import React from 'react';
import styled from '@emotion/styled';
import { pxToRem } from 'casper-ui-kit';
import { DefaultHeaderLogo } from '../Logos';
import { HeaderComponent } from '../Header/Header.styled';

export const SignUpPageHeader: React.FC = () => {
  const logo = <DefaultHeaderLogo aria-label="home" />;

  return (
    <HeaderComponent>
      <SignUpPageHeaderComponentsWrapper>
        <div>{logo}</div>
      </SignUpPageHeaderComponentsWrapper>
    </HeaderComponent>
  );
};

const SignUpPageHeaderComponentsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${props => props.theme.backgrounds.primary};
  margin-top: ${pxToRem(65)};
`;
