import styled from '@emotion/styled';
import { pxToRem } from 'casper-ui-kit';

export const CardTopSectionWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: auto 1fr;
  margin-bottom: 1rem;
`;

export const CardImageWrapper = styled.div`
  width: ${pxToRem(165)};
  height: ${pxToRem(165)};
  aspect-ratio: 1;
  overflow: hidden;
  display: grid;
  place-items: center;
  position: relative;
`;

export const CardImage = styled.img`
  height: auto;
  width: 100%;
  position: absolute;
`;

export const CardDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  white-space: pre-line;
`;

export const CardDataWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-top: 1rem;
  grid-gap: 0.75rem;
`;

export const DataWrapper = styled.div``;

export const CardData = styled.p`
  font-weight: 500;
  font-size: ${pxToRem(18)};
  margin: 0;
`;

export const CardLabel = styled.p`
  font-size: ${pxToRem(12)};
  margin: 0;
`;
