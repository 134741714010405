import styled from '@emotion/styled';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { defaultTheme, pxToRem } from 'casper-ui-kit';
import { format } from 'date-fns';
import { ApiData } from 'src/api/types';
import { withSkeletonLoading } from 'src/components/loading';
import { ButtonWithLoading } from 'src/components/buttons/ButtonWithLoading';
import { clRewardsTheme } from 'src/styled-theme';
import { useAppSelector } from 'src/store';
import { getEndUser } from 'src/store/selectors/end-user-selectors';
import {
  CardWrapper,
  PromotionDataLabel,
  PromotionDataValue,
  PromotionHeading,
} from '../cards.styled';

interface LivePromotionCardProps {
  businessPromotion: ApiData.BusinessPromotion;
  hasCheckedMyPromotionsFilter?: boolean;
  isLoading: boolean;
}

export const LivePromotionCard: React.FC<LivePromotionCardProps> = ({
  businessPromotion,
  hasCheckedMyPromotionsFilter,
  isLoading,
}) => {
  const {
    promotionName,
    promotionCaption,
    promotionHashtag,
    promotionImage,
    rewardExpiry,
    rewardQuantity,
    rewards,
    pointsForEngagement,
    pointsForRedemption,
    engagements,
    _id: businessPromotionId,
  } = businessPromotion;

  const endUser = useAppSelector(getEndUser);
  const navigate = useNavigate();

  const getEngagementPointsClaimed = (
    engagements: ApiData.EndUserPromotionEngagement[],
  ) => {
    const pointsClaimed = engagements.reduce((acc, engagement) => {
      if (engagement?.pointsCollected) {
        return acc + engagement.pointsCollected;
      }
      return acc;
    }, 0);

    return pointsClaimed;
  };

  const endUserRewards = (
    businessPromotion.rewards as ApiData.EndUserPromotionReward[]
  ).filter(
    reward =>
      reward.endUserId === endUser?._id &&
      reward.businessPromotionId === businessPromotion._id,
  );

  const endUserHasReward = endUserRewards.length > 0;

  const remainingQuantity = rewardQuantity - rewards.length;
  const promotionExpiry = format(new Date(rewardExpiry), 'dd-MM-yyyy');

  const { t } = useTranslation();

  const pointsClaimed = useMemo(() => {
    return getEngagementPointsClaimed(
      engagements as ApiData.EndUserPromotionEngagement[],
    );
  }, [engagements]);

  const onHandleViewReward = () => {
    navigate(`/end-user/rewards?promotionId=${businessPromotionId}`);
  };

  return (
    <CardWrapper>
      <PromotionHeading>
        {withSkeletonLoading(promotionName, isLoading)}
      </PromotionHeading>
      <PromotionDataWrapper>
        <PromotionImageWrapper>
          {withSkeletonLoading(
            <PromotionImage src={promotionImage} alt="promotion" />,
            isLoading,
            { height: '5rem' },
          )}
        </PromotionImageWrapper>
        <PromotionDescriptionWrapper>
          <PromotionDescription>
            {withSkeletonLoading(promotionCaption, isLoading, {
              height: '2rem',
            })}
          </PromotionDescription>
          <PromotionHashtag>
            <p>
              {withSkeletonLoading(t('Promotion hashtag:'), isLoading, {
                height: '1rem',
              })}
            </p>
            <p>{withSkeletonLoading(`#${promotionHashtag}`, isLoading)}</p>
          </PromotionHashtag>
        </PromotionDescriptionWrapper>
      </PromotionDataWrapper>
      {hasCheckedMyPromotionsFilter &&
        withSkeletonLoading(
          <ProgressBarWrapper>
            <PointsClaimedProgressBar
              pointsClaimedPercentage={
                (pointsClaimed / pointsForRedemption) * 100
              }
              data-testid="progress-bar"
            />
            <PointsClaimed>
              {pointsClaimed}/{pointsForRedemption}
            </PointsClaimed>

            <PointsClaimedSubHeading>
              {t('Points Claimed')}
            </PointsClaimedSubHeading>
          </ProgressBarWrapper>,
          isLoading,
          { height: '5rem' },
        )}

      <RewardDataWrapper>
        <div>
          <PromotionDataValue>
            {withSkeletonLoading(promotionExpiry, isLoading)}
          </PromotionDataValue>
          <PromotionDataLabel>
            {withSkeletonLoading(t('Promotion Expiry'), isLoading)}
          </PromotionDataLabel>
        </div>
        <div>
          <PromotionDataValue>
            {withSkeletonLoading(remainingQuantity, isLoading)}
          </PromotionDataValue>
          <PromotionDataLabel>
            {withSkeletonLoading(t('Remaining Quantity'), isLoading)}
          </PromotionDataLabel>
        </div>
        <div>
          <PromotionDataValue>
            {withSkeletonLoading(pointsForEngagement, isLoading)}
          </PromotionDataValue>
          <PromotionDataLabel>
            {withSkeletonLoading(t('Points per Engagement'), isLoading)}
          </PromotionDataLabel>
        </div>
        <div>
          <PromotionDataValue>
            {withSkeletonLoading(pointsForRedemption, isLoading)}
          </PromotionDataValue>
          <PromotionDataLabel>
            {withSkeletonLoading(t('Points Required'), isLoading)}
          </PromotionDataLabel>
        </div>
        {hasCheckedMyPromotionsFilter && (
          <ViewRewardWrapper>
            {endUserHasReward ? (
              <ViewRewardButton
                onClick={() =>
                  navigate(
                    `/end-user/redeem-points?rewardId=${endUserRewards[0]._id}&promotionId=${businessPromotion._id}`,
                  )
                }>
                {withSkeletonLoading(t('Go to Reward'), isLoading)}
              </ViewRewardButton>
            ) : (
              <ViewRewardButton onClick={onHandleViewReward}>
                {withSkeletonLoading(t('View Reward'), isLoading)}
              </ViewRewardButton>
            )}
          </ViewRewardWrapper>
        )}
      </RewardDataWrapper>
    </CardWrapper>
  );
};

export const PromotionDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PromotionImageWrapper = styled.div``;

export const PromotionImage = styled.img`
  width: ${pxToRem(120)};
  height: ${pxToRem(120)};
`;

export const RewardDataWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 2rem;
  column-gap: 1rem;
`;

export const PromotionDataWrapper = styled.div`
  display: grid;
  gap: 2rem;
  grid-template-columns: ${pxToRem(120)} 1fr;
  margin-bottom: 1rem;
`;

export const PromotionDescription = styled.p`
  font-size: ${pxToRem(12)};
  margin-bottom: 1rem;
`;

export const PromotionHashtag = styled.div`
  font-size: ${pxToRem(12)};
`;

export const PointsClaimedProgressBar = styled.div<{
  pointsClaimedPercentage: number;
}>`
  display: block;
  width: 100%;
  height: ${pxToRem(55)};
  border: solid ${pxToRem(2)} ${props => props.theme.inputs.border};
  border-radius: ${pxToRem(10)};
  margin-bottom: 0.25rem;
  background-image: ${props => props.theme.gradients.progressBar};
  background-color: white;
  background-repeat: no-repeat;
  background-size: ${({ pointsClaimedPercentage }) =>
      `${pointsClaimedPercentage}%`}
    100%;

  @media only screen and (min-width: ${defaultTheme.breakpoints.sm}) {
    height: ${pxToRem(47)};
    max-width: ${pxToRem(400)};
    margin: 0 auto;
    margin-bottom: 0.5rem;
  }
`;

const ProgressBarWrapper = styled.div`
  margin-bottom: 1rem;
`;

const PointsClaimed = styled.h3`
  display: flex;
  justify-content: center;
  font-weight: 500;
  line-height: 1.375;
  font-size: ${pxToRem(24)};

  @media only screen and (min-width: ${defaultTheme.breakpoints.sm}) {
    font-size: ${pxToRem(28)};
  }

  @media only screen and (min-width: ${defaultTheme.breakpoints.md}) {
    font-size: ${pxToRem(40)};
  }
`;

const PointsClaimedSubHeading = styled.h4`
  font-size: ${pxToRem(16)};
  font-weight: 400;
  text-align: center;

  @media only screen and (min-width: ${defaultTheme.breakpoints.sm}) {
    font-size: ${pxToRem(18)};
  }

  @media only screen and (min-width: ${defaultTheme.breakpoints.md}) {
    font-size: ${pxToRem(20)};
  }
`;

const ViewRewardWrapper = styled.div`
  width: 100%;
  grid-column: span 4;
  display: flex;
  justify-content: space-around;
`;

const ViewRewardButton = styled(ButtonWithLoading)`
  text-align: center;
  padding: 0.75rem 3rem;
  color: ${() => clRewardsTheme.buttons.textColor.black};
  background-color: ${() => clRewardsTheme.buttons.bgColor.casperGreen};
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0.025rem;
`;
