import React, { useState } from 'react';
import { t } from 'i18next';
import { useScrollLock } from 'src/hooks';
import { useLocation } from 'react-router-dom';
import { Spacer } from 'src/components/base';
import {
  Loading,
  getAuthLoadingStatus,
  getFacebookAuthInfo,
  useAppSelector,
} from 'src/store';
import { HeaderComponent, HeaderComponentsContainer } from '../Header.styled';
import { Navbar } from '../Navbar';
import { DefaultHeaderLogo } from '../../Logos';

const navItems = [
  {
    title: `${t('Dashboard')}`,
    path: '/end-user/dashboard',
    key: 'dashboard',
  },
  {
    title: `${t('Logout')}`,
    path: null,
    key: 'my-account',
  },
];

const logo = (
  <DefaultHeaderLogo linkTo="/end-user/dashboard" aria-label="home" />
);

const END_USER_HEADER_ROUTES_BLACKLIST = ['/end-user/login'];

export const EndUserMobileHeader: React.FC = () => {
  const [isOpened, setIsOpened] = useState(false);
  const { lockScroll, unlockScroll } = useScrollLock();
  const { pathname } = useLocation();

  const facebookAuthInfo = useAppSelector(getFacebookAuthInfo);
  const authLoadingStatus = useAppSelector(getAuthLoadingStatus);

  const windowWidth = window.innerWidth || 0;

  const openNav = () => {
    setIsOpened(true);
    lockScroll();
  };
  const closeNav = () => {
    setIsOpened(false);
    unlockScroll();
  };

  if (
    END_USER_HEADER_ROUTES_BLACKLIST.some(route => pathname.includes(route)) ||
    authLoadingStatus !== Loading.Complete ||
    facebookAuthInfo?.status !== 'connected'
  ) {
    return <Spacer height="1rem" />;
  }

  return (
    <HeaderComponent>
      <HeaderComponentsContainer>
        {logo}
        <Navbar
          windowWidth={windowWidth}
          openNav={openNav}
          closeNav={closeNav}
          isOpened={isOpened}
          navItems={navItems}
          userType="end-user"
        />
      </HeaderComponentsContainer>
    </HeaderComponent>
  );
};
