import { REDEEM_POINTS_TEXT_BREAKPOINT } from 'src/constants';

const windowWidth = window.innerWidth || 0;

export const promotionImageVariants = {
  initial: { opacity: 0, scale: 0 },
  animate: { opacity: 1, scale: [0.5, 1.2, 1] },
  transition: {
    duration: 2,
    delay: 2,
    ease: [0, 0.71, 0.2, 1.01],
  },
};

export const animatedTextVariants =
  // Where the RedeemPointsText unwraps to a single line
  windowWidth < REDEEM_POINTS_TEXT_BREAKPOINT
    ? {
        initial: { opacity: 0, scale: 0 },
        animate: {
          opacity: [1, 1, 1, 1, 1, 0],
          scale: [0.5, 1.25, 1.25, 1, 0],
          bottom: 185,
        },
        transition: {
          duration: 6,
          ease: [0, 0.71, 0.2, 1.01],
        },
      }
    : {
        initial: { opacity: 0, scale: 0 },
        animate: {
          opacity: [1, 1, 1, 1, 1, 0],
          scale: [0.5, 1.25, 1.25, 1, 0],
          bottom: 160,
        },
        transition: {
          duration: 6,
          ease: [0, 0.71, 0.2, 1.01],
        },
      };
