import styled from '@emotion/styled';
import { pxToRem } from 'casper-ui-kit';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { PageWrapper } from 'src/components/layout/PageWrapper';
import { ManagePromotionDetails } from 'src/components/manage-promotion/ManagePromotionDetails';

export const ManagePromotion: React.FC = () => {
  const { t } = useTranslation();

  return (
    <PageWrapper>
      <PathHeader>
        <BreadCrumbLink to="business-user/dashboard">
          {t('Dashboard')}
        </BreadCrumbLink>
        {' >'} {t('Manage Promotion')}
      </PathHeader>
      <TitleText>{t('Manage Promotion')}</TitleText>
      <ManagePromotionDetails />
    </PageWrapper>
  );
};

const PathHeader = styled.p`
  font-size: ${pxToRem(16)};
  font-weight: 400;
  margin-bottom: 2rem;
`;

const TitleText = styled.h1`
  width: 100%;
  margin-bottom: 2rem;
  font-size: 2.5rem;
  white-space: nowrap;
  font-weight: 400;
`;

const BreadCrumbLink = styled(Link)`
  color: black;
`;
