import React from 'react';
import Skeleton, { SkeletonStyleProps } from 'react-loading-skeleton';

export const withSkeletonLoading = (
  child: React.ReactNode,
  isLoading: boolean,
  skeletonStyleOverride?: SkeletonStyleProps,
) => {
  if (isLoading) {
    return (
      <Skeleton containerTestId="skeleton-loader" {...skeletonStyleOverride} />
    );
  }

  return child;
};

export const getSkeletonLoadingData = <T extends {}>(
  skeletonData: T,
  count = 1,
): T[] => {
  return Array(count)
    .fill(skeletonData)
    .map((data: T, index) => {
      return { ...data, _id: index.toString() };
    });
};
