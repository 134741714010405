import styled from '@emotion/styled';
import { defaultTheme, pxToRem } from 'casper-ui-kit';
import React, { useMemo } from 'react';
import Modal from 'styled-react-modal';
import { clRewardsTheme } from 'src/styled-theme';
import { UseFormReset } from 'react-hook-form';
import { t } from 'i18next';
import { PromotionStatus } from 'src/api/types';
import { CreatePromotionButton } from '../buttons/CreatePromotionButton';
import { ManagePromotionValues } from '../manage-promotion/mange-promotion.types';

export interface ManagePromotionModalProps {
  readonly promotionStatus: PromotionStatus | null;
  readonly modalStatusSelectionText: PromotionStatus | null;
  readonly isModalOpen: boolean;
  readonly toggleModal: () => void;
  readonly setModalStatusSelectionText: React.Dispatch<
    React.SetStateAction<PromotionStatus | null>
  >;
  readonly reset: UseFormReset<ManagePromotionValues>;
}

export const ManagePromotionModal: React.FC<ManagePromotionModalProps> = ({
  promotionStatus,
  modalStatusSelectionText,
  setModalStatusSelectionText,
  isModalOpen,
  toggleModal,
  reset,
}) => {
  const cancelButtonHandler = () => {
    setModalStatusSelectionText(promotionStatus);
    reset({
      promotionStatusSelect: promotionStatus || '',
    });
    toggleModal();
  };

  const promotionStatusDialogue = useMemo(() => {
    switch (modalStatusSelectionText) {
      case 'ended':
        return { prompt: t('Are sure you want to cancel your promotion?') };
      case 'paused':
        return { prompt: t('Are sure you want to pause your promotion?') };
      case 'active':
        return { prompt: t('Are sure you want to resume your promotion?') };
      default:
        return { prompt: '' };
    }
  }, [modalStatusSelectionText]);

  return (
    <Modal
      isOpen={isModalOpen}
      onBackgroundClick={toggleModal}
      onEscapeKeydown={toggleModal}>
      <UpdatePromotionStatusModalContainer>
        <StatusChangePrompt>
          {promotionStatusDialogue.prompt}
        </StatusChangePrompt>
        <ButtonsWrapper>
          <CreatePromotionButton
            type="reset"
            onClick={cancelButtonHandler}
            bgColor={clRewardsTheme.buttons.bgColor.white}>
            {t('Cancel')}
          </CreatePromotionButton>
          <CreatePromotionButton
            type="submit"
            onClick={toggleModal}
            bgColor={clRewardsTheme.buttons.bgColor.casperGreen}>
            {t('Yes')}
          </CreatePromotionButton>
        </ButtonsWrapper>
      </UpdatePromotionStatusModalContainer>
    </Modal>
  );
};

const UpdatePromotionStatusModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${props => props.theme.backgrounds.primary};
  width: fit-content;
  gap: ${pxToRem(20)};
  padding: 2rem 1.25rem;

  @media (min-width: ${defaultTheme.breakpoints.sm}) {
    padding: 2rem 2rem;
  }
`;

const StatusChangePrompt = styled.p`
  font-size: ${pxToRem(22)};
  width: 24ch;
  text-align: center;

  @media (min-width: ${defaultTheme.breakpoints.sm}) {
    font-size: ${pxToRem(24)};
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pxToRem(20)};
  @media (min-width: ${defaultTheme.breakpoints.sm}) {
    flex-direction: row;
  }
`;
