import styled from '@emotion/styled';
import { pxToRem } from 'casper-ui-kit';

export const EngagementLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 45.875rem;
  margin-top: 2rem;
  margin-bottom: 0.75rem;
  padding: 0;
`;

export const GradientCapsuleWrapper = styled.div`
  line-height: 0;
`;

export const TriangleIconsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledRadioButton = styled.input`
  padding: 0;
  margin: 0;
  width: 0;
  height: 0;
  border-left: ${pxToRem(16)} solid transparent;
  border-right: ${pxToRem(16)} solid transparent;
  border-top: ${pxToRem(22)} solid #fff;
  -webkit-appearance: none;
  appearance: none;

  &:checked {
    border-top: ${pxToRem(22)} solid
      ${props => props.theme.buttons.bgColor.casperGreen};
  }

  :hover {
    cursor: pointer;
  }
`;

export const EngagementLevelOptions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 8.125rem;
  margin: 0 1.75rem;

  :hover {
    cursor: pointer;
  }
`;

export const EngagementLevelHeader = styled.h3`
  font-size: 1.5rem;
  font-weight: 500;
  margin: 0.25rem 0;
`;

export const LevelDescription = styled.p`
  font-size: 1rem;
  line-height: 1.15;
  font-weight: 300;
  text-align: center;
  margin-bottom: 0.75rem;
`;

export const StyledStrong = styled.strong`
  font-weight: 500;
`;

export const UsersSelectionAlert = styled.h3`
  color: ${props => props.theme.textColor.userSelectionAlert};
  font-size: 0.9rem;
  font-weight: 500;
  text-align: center;
  height: 1rem;
`;

export const EngagementLevelErrorMessage = styled.p`
  color: red;
  text-align: center;
  position: absolute;
  right: 0;
  left: 0;
`;
