import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from '@emotion/styled';
import { t } from 'i18next';
import { Loading, useAppSelector } from 'src/store';
import {
  getAuthLoadingStatus,
  getFacebookAuthInfo,
} from 'src/store/selectors/auth-selectors';
import { useScrollLock } from '../../../hooks';
import { DefaultHeaderLogo } from '../Logos';
import { HeaderComponent, HeaderComponentsContainer } from './Header.styled';
import { Navbar } from './Navbar';
import { EndUserMobileHeader } from './MobileHeader';

const navItems = [
  {
    title: `${t('Dashboard')}`,
    path: '/business-user/dashboard',
    key: 'dashboard',
  },
  {
    title: `${t('Create Promotion')}`,
    path: '/business-user/create-promotion',
    key: 'create-promo',
  },
  {
    title: `${t('Scan Reward')}`,
    path: '/business-user/redeem-customer-reward',
    key: 'scan-reward',
    mobileOnly: true,
  },
  {
    title: `${t('Logout')}`,
    path: null,
    key: 'my-account',
  },
];

const BUSINESS_USER_HEADER_ROUTES_BLACKLIST = [
  '/business-user/login',
  '/business-user/sign-up-failure',
  '/business-user/terms-and-conditions',
  '/business-user/create-profile',
];

export const Header: React.FC = () => {
  const [isOpened, setIsOpened] = useState(false);
  const { lockScroll, unlockScroll } = useScrollLock();

  const facebookAuthInfo = useAppSelector(getFacebookAuthInfo);
  const authLoadingStatus = useAppSelector(getAuthLoadingStatus);

  const { pathname } = useLocation();

  if (pathname.includes('end-user')) {
    return (
      <MobileHeaderWrapper>
        <EndUserMobileHeader />
      </MobileHeaderWrapper>
    );
  }

  const logo = (
    <DefaultHeaderLogo linkTo="/business-user/dashboard" aria-label="home" />
  );

  if (
    BUSINESS_USER_HEADER_ROUTES_BLACKLIST.some(route =>
      pathname.includes(route),
    )
  ) {
    return null;
  }

  if (
    authLoadingStatus !== Loading.Complete ||
    facebookAuthInfo?.status !== 'connected'
  ) {
    return null;
  }
  const windowWidth = window.innerWidth || 0;

  const openNav = () => {
    setIsOpened(true);
    lockScroll();
  };
  const closeNav = () => {
    setIsOpened(false);
    unlockScroll();
  };

  return (
    <HeaderComponent>
      <HeaderComponentsContainer>
        {logo}
        <Navbar
          windowWidth={windowWidth}
          openNav={openNav}
          closeNav={closeNav}
          isOpened={isOpened}
          navItems={navItems}
          userType="business-user"
        />
      </HeaderComponentsContainer>
    </HeaderComponent>
  );
};

const MobileHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;
