import React, { useEffect } from 'react';
import {
  fetchLoggedInBusinessUser,
  useAppDispatch,
  useAppSelector,
} from 'src/store';
import { getBusinessUser } from 'src/store/selectors/business-user-selectors';
import { ManageProfile } from './ManageProfile';

export const EditProfile: React.FC = () => {
  const dispatch = useAppDispatch();
  const businessUser = useAppSelector(getBusinessUser);

  useEffect(() => {
    if (!businessUser) {
      dispatch(fetchLoggedInBusinessUser());
    }
  }, [businessUser, dispatch]);

  return <ManageProfile />;
};
