import {
  ApiData,
  EngagementType,
  PromotionDays,
  PromotionStatus,
  PromotionType,
} from 'src/api/types';

export const skeletonData: {
  businessPromotion: ApiData.BusinessPromotion;
  businessUser: ApiData.BusinessUser;
  endUserPromotionReward: ApiData.EndUserPromotionReward;
} = {
  businessPromotion: {
    _id: '653fc1d20f42a89d509c2add',
    businessUserId: {
      _id: '653fc1d20f42a89d509c2ad9',
      facebookPageId: '133612969818807',
      businessName: 'test1',
      businessLogo: 'test business logo',
      businessDescription: 'asdfd',
      businessEmail: 'business@example.com',
      instagramAccount: 'casper_bryce_test',
      instagramUserId: '12345a',
      defaultPromotionTerms: 'asdfsd',
      acceptedTermsAndConditions: '2023-10-30T15:35:10.237Z',
      createdProfile: '2023-10-30T15:35:24.517Z',
      promotions: ['653fc1d20f42a89d509c2add'],
    },
    nftId: 'nft-id-here',
    promotionName: '30% Off Kamalas Chocolate Cakes',
    promotionCaption:
      'Kamalas Chocolates small-batch, artisanal chocolates and desserts \n  aim to craft delectable treats that bring joy with every bite. For a limited time, \n  message us with #Choc30 to claim a 30% off discount for chocolate cakes!',
    promotionHashtag: '#Choc30',
    promotionImage: 'add-image-hash-here',
    pointsForEngagement: 10,
    pointsForReferral: 5,
    pointsForRedemption: 30,
    rewardQuantity: 30,
    rewardExpiry: '2023-11-29T14:46:42.266Z',
    promotionType: PromotionType.pointsPlusRewards,
    promotionScheduleRRule:
      'DTSTART:20231030T144642Z\nRRULE:FREQ=DAILY;INTERVAL=1;BYDAY=MO,WE,FR;UNTIL=20231120T144642Z',
    promotionStartDate: '2023-10-30T14:46:42.266Z',
    promotionEndDate: '2023-11-20T14:46:42.266Z',
    promotionDays: [PromotionDays.Friday],
    status: PromotionStatus.Ended,
    rewards: [
      {
        _id: '653fc1d20f42a89d509c2ae9',
        endUserId: '653fc1d20f42a89d509c2adb',
        businessPromotionId: '653fc1d20f42a89d509c2add',
        nftId: 'nft-id',
        redeemedAt: null,
        createdAt: '2023-10-30T14:46:42.560Z',
      },
    ],
    engagements: [
      {
        _id: '653fc1d20f42a89d509c2ae2',
        endUserId: '653fc1d20f42a89d509c2adb',
        businessPromotionId: '653fc1d20f42a89d509c2add',
        engagementType: EngagementType.Engagement,
        pointsCollected: 10,
      },
      {
        _id: '653fc1d20f42a89d509c2ae3',
        endUserId: '653fc1d20f42a89d509c2adb',
        businessPromotionId: '653fc1d20f42a89d509c2add',
        engagementType: EngagementType.Referral,
        pointsCollected: 5,
      },
      {
        _id: '653fc1d20f42a89d509c2ae4',
        endUserId: '653fc1d20f42a89d509c2adb',
        businessPromotionId: '653fc1d20f42a89d509c2add',
        engagementType: EngagementType.Referral,
        pointsCollected: 5,
      },
    ],
    promotionPosts: [
      {
        _id: '653fc1d20f42a89d509c2aec',
        businessPromotionId: '653fc1d20f42a89d509c2add',
        instagramPostId: '1',
        createdAt: '2023-10-30T14:46:42.562Z',
        updatedAt: '2023-10-30T14:46:42.562Z',
      },
      {
        _id: '653fc1d20f42a89d509c2aed',
        businessPromotionId: '653fc1d20f42a89d509c2add',
        instagramPostId: '2',
        createdAt: '2023-10-30T14:46:42.562Z',
        updatedAt: '2023-10-30T14:46:42.562Z',
      },
    ],
    totalPointsCollected: 20,
    totalRewardsClaimed: 1,
    totalRewardsRedeemed: 0,
  },
  businessUser: {
    _id: '653fc1d20f42a89d509c2ad9',
    facebookPageId: '133612969818807',
    businessName: 'test1',
    businessLogo: 'test business logo',
    businessDescription: 'asdfd',
    businessEmail: 'business@example.com',
    instagramAccount: 'casper_bryce_test',
    instagramUserId: '12345a',
    defaultPromotionTerms: 'asdfsd',
    acceptedTermsAndConditions: '2023-10-30T15:35:10.237Z',
    createdProfile: '2023-10-30T15:35:24.517Z',
    promotions: [
      {
        _id: '653fc1d20f42a89d509c2add',
        businessUserId: '653fc1d20f42a89d509c2ad9',
        nftId: 'nft-id-here',
        promotionName: '30% Off Kamalas Chocolate Cakes',
        promotionCaption:
          'Kamalas Chocolates small-batch, artisanal chocolates and desserts \n  aim to craft delectable treats that bring joy with every bite. For a limited time, \n  message us with #Choc30 to claim a 30% off discount for chocolate cakes!',
        promotionHashtag: '#Choc30',
        promotionImage: 'add-image-hash-here',
        pointsForEngagement: 10,
        pointsForReferral: 5,
        pointsForRedemption: 30,
        rewardQuantity: 30,
        rewardExpiry: '2023-11-29T14:46:42.266Z',
        promotionType: PromotionType.pointsPlusRewards,
        promotionScheduleRRule:
          'DTSTART:20231030T144642Z\nRRULE:FREQ=DAILY;INTERVAL=1;BYDAY=MO,WE,FR;UNTIL=20231120T144642Z',
        promotionStartDate: '2023-10-30T14:46:42.266Z',
        promotionEndDate: '2023-11-20T14:46:42.266Z',
        promotionDays: [PromotionDays.Friday],
        status: PromotionStatus.Ended,
        rewards: [
          {
            _id: '653fc1d20f42a89d509c2ae9',
            endUserId: '653fc1d20f42a89d509c2adb',
            businessPromotionId: '653fc1d20f42a89d509c2add',
            nftId: 'nft-id',
            redeemedAt: null,
            createdAt: '2023-10-30T14:46:42.560Z',
          },
        ],
        engagements: [
          '653fc1d20f42a89d509c2ae2',
          '653fc1d20f42a89d509c2ae3',
          '653fc1d20f42a89d509c2ae4',
          '65411c7e235868d119d60d3c',
        ],
        promotionPosts: [
          '653fc1d20f42a89d509c2aec',
          '653fc1d20f42a89d509c2aed',
        ],
      },
    ],
  },
  endUserPromotionReward: {
    _id: '65411c87235868d119d60d49',
    endUserId: '653fc1d20f42a89d509c2adb',
    businessPromotionId: {
      _id: '653fc1d20f42a89d509c2add',
      businessUserId: '653fc1d20f42a89d509c2ad9',
      nftId: 'nft-id-here',
      promotionName: '30% Off Kamalas Chocolate Cakes',
      promotionCaption:
        'Kamalas Chocolates small-batch, artisanal chocolates and desserts \n  aim to craft delectable treats that bring joy with every bite. For a limited time, \n  message us with #Choc30 to claim a 30% off discount for chocolate cakes!',
      promotionHashtag: '#Choc30',
      promotionImage: 'add-image-hash-here',
      pointsForEngagement: 10,
      pointsForReferral: 5,
      pointsForRedemption: 30,
      rewardQuantity: 30,
      rewardExpiry: '2023-11-29T14:46:42.266Z',
      promotionType: PromotionType.pointsPlusRewards,
      promotionScheduleRRule:
        'DTSTART:20231030T144642Z\nRRULE:FREQ=DAILY;INTERVAL=1;BYDAY=MO,WE,FR;UNTIL=20231120T144642Z',
      promotionStartDate: '2023-10-30T14:46:42.266Z',
      promotionEndDate: '2023-11-20T14:46:42.266Z',
      promotionDays: [PromotionDays.Friday],
      status: PromotionStatus.Ended,
      rewards: [
        {
          _id: '653fc1d20f42a89d509c2ae9',
          endUserId: '653fc1d20f42a89d509c2adb',
          businessPromotionId: '653fc1d20f42a89d509c2add',
          nftId: 'nft-id',
          redeemedAt: null,
          createdAt: '2023-10-30T14:46:42.560Z',
        },
      ],
      engagements: [
        '653fc1d20f42a89d509c2ae2',
        '653fc1d20f42a89d509c2ae3',
        '653fc1d20f42a89d509c2ae4',
        '65411c7e235868d119d60d3c',
      ],
      promotionPosts: ['653fc1d20f42a89d509c2aec', '653fc1d20f42a89d509c2aed'],
    },
    nftId: null,
    redeemedAt: null,
    createdAt: '2023-10-31T15:25:59.378Z',
  },
};
