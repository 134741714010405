export enum PromotionType {
  pointsOnly = 'points-only',
  rewardsOnly = 'rewards-only',
  pointsPlusRewards = 'points-plus-rewards',
}

// stackoverflow.com/questions/62215454/how-to-get-enum-key-by-value-in-typescript
export enum PromotionDays {
  Sunday = <any>'0',
  Monday = <any>'1',
  Tuesday = <any>'2',
  Wednesday = <any>'3',
  Thursday = <any>'4',
  Friday = <any>'5',
  Saturday = <any>'6',
}

export enum PromotionStatus {
  Active = 'active',
  InActive = 'inactive',
  Paused = 'paused',
  Ended = 'ended',
}

export enum EngagementType {
  Engagement = 'engagement',
  Referral = 'referral',
}

export interface AuthenticateResponse {
  access_token: string;
}

export interface CreateBusinessPromotionRequest {
  promotionName: string;
  promotionCaption: string;
  promotionImage: string;
  promotionHashtag: string;
  pointsForEngagement: number;
  pointsForRedemption: number;
  pointsForReferral: number;
  promotionType: string;
  status: string;
  promotionStartDate: string;
  promotionEndDate: string;
  rewardExpiry: string;
  rewardQuantity: number;
  promotionDays: string[];
}

export namespace ApiData {
  export interface Promotion {
    id: string;
    business: string;
    businessUserId: string;
    title: string;
    description: string;
    imageUrl: string;
    hashtag: string;
  }

  export interface Reward {
    id: string;
    userId: string;
    promotionId: string;
    redemptionCode: string;
    redemptionConditions: string[];
    redemptionDate: Date;
    dateClaimed: Date;
    expirationDate: Date;
    rewardsRemaining: number;
  }

  export interface EndUser {
    _id: string;
    name: string;
    email: string;
  }

  export interface EndUserPromotionReward {
    _id: string;
    endUserId: string;
    businessPromotionId: string | BusinessPromotion;
    nftId?: string | null;
    redeemedAt: string | null;
    createdAt: string;
    encryptedId?: string;
  }

  export interface EndUserPromotionEngagement {
    _id: string;
    endUserId: string;
    businessPromotionId: string | BusinessPromotion;
    engagementType: EngagementType;
    pointsCollected: number;
  }

  export interface BusinessPromotion {
    _id: string;
    businessUserId: string | BusinessUser;
    encryptedId?: string;
    nftId?: string;
    promotionName: string;
    promotionHashtag: string;
    promotionCaption: string;
    promotionImage: string;
    pointsForEngagement: number;
    pointsForRedemption: number;
    pointsForReferral: number;
    promotionType: PromotionType;
    rewardQuantity: number;
    rewardExpiry: string;
    promotionScheduleRRule: string;
    promotionDays: PromotionDays[];
    promotionStartDate: string;
    promotionEndDate: string;
    status: PromotionStatus;
    rewards: string[] | EndUserPromotionReward[];
    engagements: string[] | EndUserPromotionEngagement[];
    promotionPosts: string[] | PromotionPost[];
    totalPointsCollected?: number;
    totalRewardsClaimed?: number;
    totalRewardsRedeemed?: number;
  }

  export interface BusinessPromotionUnguarded {
    _id: string;
    promotionName: string;
    promotionImage: string;
    businessUserName: string;
    pointsForEngagement: number;
  }

  export interface BusinessUser {
    _id: string;
    facebookPageId: string;
    businessEmail: string;
    businessName: string;
    businessLogo: string;
    businessDescription: string;
    instagramAccount: string;
    instagramUserId: string;
    defaultPromotionTerms?: string;
    acceptedTermsAndConditions: string;
    createdProfile: string | null;
    promotions: string[] | BusinessPromotion[];
  }

  export interface PromotionPost {
    _id: string;
    businessPromotionId: string;
    instagramPostId: string;
    createdAt?: string;
    updatedAt?: string;
  }
}
