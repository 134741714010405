import { Loading } from '../loading.type';
import { RootState } from '../store';

export const getAuthLoadingStatus = (state: RootState) => {
  return state.auth.status;
};

export const getFacebookAuthInfo = (state: RootState) => {
  return state.auth.facebookAuthInfo;
};

export const isSuccessfullyLoggedIn = (state: RootState) => {
  return (
    state.auth.facebookAuthInfo?.status === 'connected' &&
    state.auth.status === Loading.Complete
  );
};
