import React, { useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { ModalProvider } from 'styled-react-modal';
import { t } from 'i18next';
import ReactSelect from 'react-select';
import { Controller } from 'react-hook-form';
import { pxToRem } from 'casper-ui-kit';
import { ManagePromotionModal } from 'src/components/modals';
import { handlePromotionStatusSelectValue } from 'src/utils/string';
import { PromotionStatus } from 'src/api/types';
import {
  StatusSelectProps,
  SelectStatusOptions,
} from '../mange-promotion.types';

export const StatusSelect: React.FC<StatusSelectProps> = ({
  promotionStatus,
  dynamicSelectDefaultValue,
  modalStatusSelectionText,
  setModalStatusSelectionText,
  control,
  reset,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const SelectStatusOptions: SelectStatusOptions[] = useMemo(() => {
    switch (promotionStatus) {
      case 'inactive':
        return [
          { value: PromotionStatus.InActive, label: t('Inactive') },
          { value: PromotionStatus.Ended, label: t('Cancel') },
        ];
      case 'active':
        return [
          { value: PromotionStatus.Active, label: t('Live') },
          { value: PromotionStatus.Paused, label: t('Pause') },
          { value: PromotionStatus.Ended, label: t('End') },
        ];
      case 'paused':
        return [
          { value: PromotionStatus.Paused, label: t('Pause') },
          { value: PromotionStatus.Active, label: t('Resume') },
          { value: PromotionStatus.Ended, label: t('End') },
        ];
      case 'ended':
        return [{ value: PromotionStatus.Ended, label: t('Ended') }];
      default:
        return [{ value: PromotionStatus.Ended, label: t('Ended') }];
    }
  }, [promotionStatus]);

  const toggleModal = () => {
    setIsModalOpen(prev => !prev);
  };

  const isDynamicSelectDefaultValueEnded =
    dynamicSelectDefaultValue === 'ended';

  return (
    <>
      <Controller
        data-testid="promotion-status-select"
        name="promotionStatusSelect"
        control={control}
        render={({ field: { onChange, name } }) => {
          const handleSelectChange = (
            selectedOption: SelectStatusOptions | null,
          ) => {
            if (selectedOption === null) return;

            const selectedOptionValue = selectedOption.value;

            onChange(selectedOptionValue);
            setModalStatusSelectionText(selectedOptionValue);
            toggleModal();
          };

          const handleOptions = SelectStatusOptions.filter(
            option => option.label !== dynamicSelectDefaultValue,
          );

          return (
            <SelectWrapper>
              <Select
                isPromotionCanceled={isDynamicSelectDefaultValueEnded}
                name={name}
                isMenuOpen={isMenuOpen}
                onMenuOpen={() => setIsMenuOpen(true)}
                onMenuClose={() => setIsMenuOpen(false)}
                isSearchable={false}
                classNamePrefix={'promotion-status'}
                value={[
                  {
                    value: dynamicSelectDefaultValue,
                    label: handlePromotionStatusSelectValue(
                      dynamicSelectDefaultValue || '',
                    ),
                  },
                ]}
                noOptionsMessage={() => null}
                options={handleOptions}
                onChange={newValue =>
                  handleSelectChange(newValue as SelectStatusOptions | null)
                }
              />
            </SelectWrapper>
          );
        }}
      />
      <ModalProvider>
        <ManagePromotionModal
          promotionStatus={promotionStatus}
          modalStatusSelectionText={modalStatusSelectionText}
          isModalOpen={isModalOpen}
          toggleModal={toggleModal}
          setModalStatusSelectionText={setModalStatusSelectionText}
          reset={reset}
        />
      </ModalProvider>
    </>
  );
};

const SelectWrapper = styled.div`
  position: relative;
  bottom: ${pxToRem(6)};
`;

const Select = styled(ReactSelect)<{
  isMenuOpen: boolean;
  isPromotionCanceled?: boolean;
}>`
  .promotion-status__control {
    background-color: transparent;
    width: ${pxToRem(96)};
    box-shadow: none;
    border: none;
    border-radius: none;
    transition: none;
  }

  .promotion-status__value-container {
    background-color: ${props => props.theme.backgrounds.primary};
    height: auto;
    padding: 0;
    position: relative;
    box-shadow: none;
    border-radius: 0;
    border: none;

    &:hover {
      cursor: ${({ isPromotionCanceled }) =>
        isPromotionCanceled ? 'text' : 'pointer'};
    }
  }

  .promotion-status__indicators {
    display: block;
    position: absolute;
    width: 0;
    top: ${pxToRem(1)};
    left: 5rem;
    padding: 0;
  }

  .promotion-status__single-value {
    color: ${props => props.theme.textColor.black};
    font-weight: 500;
    font-size: ${pxToRem(20)};
    text-align: left;
    margin: 0;
    padding: 0;
  }

  .promotion-status__menu-list {
    color: ${props => props.theme.textColor.black};
    font-size: ${pxToRem(18)};
    font-weight: 400;
    padding: 0;
    margin: 0;
  }

  .promotion-status__menu {
    text-align: center;
    background-color: ${props => props.theme.backgrounds.primary};
    width: 6rem;
    border-radius: 0;
    margin: 0;
    box-shadow: ${({ isPromotionCanceled }) =>
      isPromotionCanceled ? 'none' : 'auto'};
  }

  .promotion-status__dropdown-indicator svg {
    display: ${({ isPromotionCanceled }) =>
      isPromotionCanceled ? 'none' : 'block'};
    color: ${props => props.theme.textColor.black};
    width: ${pxToRem(25)};
    transition: 200ms ease-in;
    transform: ${({ isMenuOpen }) => (isMenuOpen ? 'rotate(180deg)' : null)};
    position: absolute;
    left: 0;

    :focus,
    :hover {
      cursor: ${({ isPromotionCanceled }) =>
        isPromotionCanceled ? 'text' : 'pointer'};
    }
  }

  .promotion-status__option:hover,
  .promotion-status__option:focus {
    color: ${props => props.theme.textColor.userSelectionAlert};
    cursor: ${({ isPromotionCanceled }) =>
      isPromotionCanceled ? 'text' : 'pointer'};
  }

  .promotion-status__option {
    background-color: transparent;
  }

  .promotion-status__option--is-selected {
    display: none;
  }
`;
