import React from 'react';
import { Loading, useAppSelector } from 'src/store';
import {
  getParticipatingBusinessLoading,
  getParticipatingBusinesses,
} from 'src/store/selectors/end-user-selectors';
import { getSkeletonLoadingData } from 'src/components/loading';
import { skeletonData } from 'src/mock-data/skeleton-loading';
import { ParticipatingBusinessCard } from './ParticipatingBusinessCard';
import { CardContainer } from '../cards.styled';

export const ParticipatingBusinessCards: React.FC = () => {
  const participatingBusinesses = useAppSelector(getParticipatingBusinesses);
  const endUserBusinessesLoadingStatus = useAppSelector(
    getParticipatingBusinessLoading,
  );

  if (
    !participatingBusinesses?.length &&
    endUserBusinessesLoadingStatus === Loading.Complete
  ) {
    return <CardContainer>No active participating businesses</CardContainer>;
  }

  const getParticipatingBusinessesWithSkeletonLoading = () => {
    return (
      participatingBusinesses ??
      getSkeletonLoadingData(skeletonData.businessUser, 3)
    );
  };

  const isEndUserBusinessesLoading =
    endUserBusinessesLoadingStatus !== Loading.Complete;

  return (
    <CardContainer>
      {getParticipatingBusinessesWithSkeletonLoading().map(business => {
        const { _id: uniqueIdHash } = business;

        return (
          <ParticipatingBusinessCard
            key={uniqueIdHash}
            business={business}
            isLoading={isEndUserBusinessesLoading}
          />
        );
      })}
    </CardContainer>
  );
};
