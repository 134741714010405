export interface MockPilotBusinesses {
  instagramHandel?: string;
  id?: number;
}

export const mockPilotBusinesses = [
  {
    instagramHandle: '@business1',
    id: 1,
  },
  {
    instagramHandle: '@business2',
    id: 2,
  },
  {
    instagramHandle: '@business3',
    id: 3,
  },
  {
    instagramHandle: '@business4',
    id: 4,
  },
];
