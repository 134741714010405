import { RootState } from '../store';

export const getBusinessPromotions = (state: RootState) => {
  return state.businessPromotion.businessPromotions;
};

export const getBusinessPromotion = (state: RootState) => {
  return state.businessPromotion.businessPromotion;
};

export const getBusinessPromotionUnguarded = (state: RootState) => {
  return state.businessPromotion.businessPromotionUnguarded;
};

export const getMyPromotionsForBusinessDashboard = (state: RootState) => {
  return state.businessPromotion.myPromotionsForBusinessDashboard;
};

export const getPromotionToManage = (state: RootState) => {
  return state.businessPromotion.promotionToManage;
};

export const getPromotionStatus = (state: RootState) => {
  return state.businessPromotion.promotionStatus;
};

export const getBusinessPromotionLoadingStatus = (state: RootState) => {
  return state.businessPromotion.status;
};

export const getCreateBusinessPromotionFormData = (state: RootState) => {
  return state.businessPromotion.createBusinessPromotionFormData;
};
