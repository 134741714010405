import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Loading,
  getBusinessPromotionLoadingStatus,
  getBusinessPromotions,
  useAppSelector,
} from 'src/store';
import { getSkeletonLoadingData } from 'src/components/loading';
import { skeletonData } from 'src/mock-data/skeleton-loading';
import { LivePromotionCard } from './LivePromotionCard';
import { CardContainer } from '../cards.styled';

interface LivePromotionsCardsProps {
  hasCheckedMyPromotionsFilter?: boolean;
}

export const LivePromotionCards: React.FC<LivePromotionsCardsProps> = ({
  hasCheckedMyPromotionsFilter,
}) => {
  const { t } = useTranslation();
  const businessPromotions = useAppSelector(getBusinessPromotions);
  const businessPromotionsLoadingStatus = useAppSelector(
    getBusinessPromotionLoadingStatus,
  );

  if (
    !businessPromotions?.length &&
    businessPromotionsLoadingStatus === Loading.Complete
  ) {
    return <CardContainer>{t('No live promotions currently')}</CardContainer>;
  }

  const getBusinessPromotionsWithSkeletonLoading = () => {
    return (
      businessPromotions ??
      getSkeletonLoadingData(skeletonData.businessPromotion, 3)
    );
  };

  const isBusinessPromotionsLoading =
    businessPromotionsLoadingStatus !== Loading.Complete;

  return (
    <CardContainer>
      {getBusinessPromotionsWithSkeletonLoading().map(businessPromotion => (
        <LivePromotionCard
          businessPromotion={businessPromotion}
          hasCheckedMyPromotionsFilter={hasCheckedMyPromotionsFilter}
          key={businessPromotion._id}
          isLoading={isBusinessPromotionsLoading}
        />
      ))}
    </CardContainer>
  );
};
