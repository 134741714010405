import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  clearParticipatingBusinesses,
  fetchBusinesses,
  resetParticipatingBusinessesLoadingStatus,
  useAppDispatch,
} from 'src/store';
import styled from '@emotion/styled';
import { useForm } from 'react-hook-form';
import { defaultTheme, pxToRem } from 'casper-ui-kit';
import { useDebounce } from 'use-debounce';
import { ParticipatingBusinessCards } from 'src/components/cards';
import {
  InputErrorMessageWrapper,
  OptionTitle,
  StyledErrorMessage,
} from '../../../pages/SharedStyles/pages.styled';

export interface ParticipatingBusinessesForm {
  businessName: string;
}

export const ParticipatingBusinesses: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const {
    register,
    formState: { errors },
    watch,
  } = useForm<ParticipatingBusinessesForm>();

  const businessNameSearch = watch('businessName') ?? '';
  const [debouncedBusinessNameSearch] = useDebounce(businessNameSearch, 500);

  useEffect(() => {
    dispatch(
      fetchBusinesses(
        debouncedBusinessNameSearch
          ? { businessName: debouncedBusinessNameSearch }
          : undefined,
      ),
    );
  }, [debouncedBusinessNameSearch, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(clearParticipatingBusinesses());
      dispatch(resetParticipatingBusinessesLoadingStatus());
    };
  }, [dispatch]);

  return (
    <div>
      <OptionTitle>{t('Participating Businesses')}</OptionTitle>
      <form>
        <FormComponentsWrapper>
          <InputErrorMessageWrapper>
            <InputWrapper>
              <StyledLabel htmlFor="business-name">
                Limit results by:
              </StyledLabel>
              <StyledInput
                id="business-name"
                placeholder="Business name"
                {...register('businessName', {
                  required: true,
                })}
              />
            </InputWrapper>
            {errors.businessName && (
              <StyledErrorMessage>
                {t('Please enter a valid business name.')}
              </StyledErrorMessage>
            )}
          </InputErrorMessageWrapper>
        </FormComponentsWrapper>
      </form>
      <ParticipatingBusinessCards />
    </div>
  );
};

const StyledInput = styled.input`
  color: ${props => props.theme.inputs.textColor};
  font-weight: initial;
  width: 100%;
  max-width: ${pxToRem(300)};
  font-size: ${pxToRem(12)};
  border-radius: ${pxToRem(6)};
  border: solid ${pxToRem(2)} ${props => props.theme.inputs.border};
  padding: 0.25rem 1rem;

  @media only screen and (min-width: ${defaultTheme.breakpoints.sm}) {
    font-size: 1rem;
    padding: 0.35rem 1rem;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  width: 100%;
  white-space: nowrap;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
`;

const StyledLabel = styled.label`
  font-weight: 400;
  font-size: ${pxToRem(12)};
  padding: 0;

  @media only screen and (min-width: ${defaultTheme.breakpoints.sm}) {
    font-size: 1rem;
  }
`;

const FormComponentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin: 2rem 0;
  width: 100%;
`;
