import React, { useEffect } from 'react';
import {
  Loading,
  clearEndUserRedeemableRewards,
  fetchRedeemableEndUserReward,
  resetEndUserRedeemableRewardsLoadingStatus,
  useAppDispatch,
  useAppSelector,
} from 'src/store';
import {
  getEndUserRedeemableRewardsLoadingStatus,
  getRedeemableEndUserRewards,
} from 'src/store/selectors/end-user-selectors';
import { getSkeletonLoadingData } from 'src/components/loading';
import { skeletonData } from 'src/mock-data/skeleton-loading';
import { CardContainer } from '../cards.styled';
import { RedeemableRewardCard } from './RedeemableRewardCard';

export const RedeemableRewardCards: React.FC = () => {
  const dispatch = useAppDispatch();

  const endUserRedeemableRewards = useAppSelector(getRedeemableEndUserRewards);
  const endUserRedeemableRewardLoadingStatus = useAppSelector(
    getEndUserRedeemableRewardsLoadingStatus,
  );

  useEffect(() => {
    dispatch(fetchRedeemableEndUserReward());

    return () => {
      dispatch(clearEndUserRedeemableRewards());
      dispatch(resetEndUserRedeemableRewardsLoadingStatus());
    };
  }, [dispatch]);

  if (
    !endUserRedeemableRewards?.length &&
    endUserRedeemableRewardLoadingStatus === Loading.Complete
  ) {
    return <CardContainer>No Redeemable Rewards</CardContainer>;
  }

  const getEndUserRedeemableRewardsWithSkeletonLoading = () => {
    return (
      endUserRedeemableRewards ??
      getSkeletonLoadingData(skeletonData.endUserPromotionReward)
    );
  };

  const isEndUserRedeemableRewardsLoading =
    endUserRedeemableRewardLoadingStatus !== Loading.Complete;

  return (
    <CardContainer>
      {getEndUserRedeemableRewardsWithSkeletonLoading().map(endUserReward => {
        const { _id: uniqueIdHash } = endUserReward;
        const { businessPromotionId, createdAt } = endUserReward;

        if (typeof businessPromotionId === 'string' || !businessPromotionId) {
          return (
            <RedeemableRewardCard
              key={uniqueIdHash}
              rewardQuantity={0}
              promotionName="Not Available"
              promotionImage=""
              promotionCaption="Not Available"
              promotionHashtag="Not Available"
              rewardClaimDate="Not Available"
              rewardExpiry="Not Available"
              isLoading={false}
            />
          );
        }

        return (
          <RedeemableRewardCard
            key={uniqueIdHash}
            rewardQuantity={businessPromotionId.rewardQuantity}
            promotionName={businessPromotionId.promotionName}
            promotionImage={businessPromotionId.promotionImage}
            promotionCaption={businessPromotionId.promotionCaption}
            promotionHashtag={businessPromotionId.promotionHashtag}
            rewardClaimDate={createdAt}
            rewardExpiry={businessPromotionId.rewardExpiry}
            isLoading={isEndUserRedeemableRewardsLoading}
            businessPromotionId={businessPromotionId._id}
            endUserPromotionRewardId={uniqueIdHash}
          />
        );
      })}
    </CardContainer>
  );
};
