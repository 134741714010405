import { AxiosResponse } from 'axios';
import qs from 'query-string';
import { ApiService } from 'src/api/base-api';
import { setJWT } from 'src/api/helper';

import { ApiData } from 'src/api/types';

export class EndUserRoutes {
  private readonly prefix: string = '/end-user';

  constructor(private readonly api: ApiService) {}

  async authenticateEndUser(facebookId: string, accessToken: string) {
    type Response = AxiosResponse<{
      jwt: { access_token: string };
      endUser: ApiData.EndUser;
    }>;

    const response = await this.api.post<Response>(
      `${this.prefix}/authenticate`,
      {
        facebookId,
        accessToken,
      },
    );

    if (response.status !== 201) throw new Error(response.statusText);

    const {
      jwt: { access_token: token },
      endUser,
    } = response.data;

    setJWT(token);

    return endUser;
  }

  async fetchLoggedInEndUser() {
    type Response = AxiosResponse<ApiData.EndUser>;

    const response = await this.api.get<Response>(
      `${this.prefix}/get-loggedin-user`,
    );

    if (response.status !== 200) throw new Error(response.statusText);

    return response.data;
  }

  async fetchEndUser(id: string) {
    type Response = AxiosResponse<ApiData.EndUser>;

    const response = await this.api.get<Response>(`${this.prefix}/${id}`);

    if (response.status !== 200) throw new Error(response.statusText);

    const { data } = response;

    return data;
  }

  async fetchEndUserByFacebookId(facebookId: string) {
    type Response = AxiosResponse<ApiData.EndUser[]>;

    const response = await this.api.get<Response>(
      `${this.prefix}?facebookId=${facebookId}`,
    );

    if (response.status !== 200) throw new Error(response.statusText);

    const {
      data: [data],
    } = response;

    return data;
  }

  async createEndUserByFacebookId(facebookId: string) {
    type Response = AxiosResponse<ApiData.EndUser>;

    const response = await this.api.post<Response>('/end-user', {
      facebookId,
    });

    if (response.status !== 200) throw new Error(response.statusText);

    const { data } = response;

    return data;
  }

  async fetchBusinesses(search?: { businessName: string | undefined }) {
    type Response = AxiosResponse<ApiData.BusinessUser[]>;

    const urlParams = {
      search: JSON.stringify(search),
    };

    const url = `/business-user${search ? '/search/?' : ''}${qs.stringify(
      urlParams,
    )}`;

    const response = await this.api.get<Response>(url);

    if (response.status !== 200) throw new Error(response.statusText);

    const { data } = response;

    return data;
  }

  async fetchRedeemedEndUserRewards() {
    type Response = AxiosResponse<ApiData.EndUserPromotionReward[]>;

    const response = await this.api.get<Response>(
      '/end-user-promotion-reward/redeemed',
    );

    if (response.status !== 200) throw new Error(response.statusText);

    const { data } = response;

    return data;
  }

  async fetchRedeemableEndUserRewards() {
    type Response = AxiosResponse<ApiData.EndUserPromotionReward[]>;

    const response = await this.api.get<Response>(
      '/end-user-promotion-reward/redeemable',
    );

    if (response.status !== 200) throw new Error(response.statusText);

    const { data } = response;

    return data;
  }
}
