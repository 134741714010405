import {
  CombinedState,
  configureStore,
  PreloadedState,
} from '@reduxjs/toolkit';
import { NoInfer } from 'react-redux';
import {
  appSlice,
  authSlice,
  businessUserSlice,
  businessPromotionSlice,
  endUserSlice,
  onboardingSlice,
  endUserPromotionEngagementSlice,
  endUserPromotionRewardSlice,
} from './slices';

export const storeWithPreloadedState = (
  preloadedState?: PreloadedState<CombinedState<NoInfer<any>>>,
) => {
  return configureStore({
    reducer: {
      app: appSlice.reducer,
      auth: authSlice.reducer,
      businessUser: businessUserSlice.reducer,
      businessPromotion: businessPromotionSlice.reducer,
      endUser: endUserSlice.reducer,
      endUserPromotionEngagement: endUserPromotionEngagementSlice.reducer,
      endUserPromotionReward: endUserPromotionRewardSlice.reducer,
      onboarding: onboardingSlice.reducer,
    },
    preloadedState,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({ serializableCheck: false }).prepend(),
  });
};

export const store = storeWithPreloadedState();

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
