import styled from '@emotion/styled';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { defaultTheme, pxToRem } from 'casper-ui-kit';
import { format } from 'date-fns';
import { withSkeletonLoading } from 'src/components/loading';
import { clRewardsTheme } from 'src/styled-theme';
import { toastStore } from 'src/store/toast';
import { useNavigate } from 'react-router-dom';
import { ButtonWithLoading } from 'src/components/buttons/ButtonWithLoading';
import {
  CardWrapper,
  PromotionDataLabel,
  PromotionDataValue,
  PromotionHeading,
  ImageWrapper,
} from '../cards.styled';

export interface RedeemableRewardCardProps {
  readonly promotionName: string;
  readonly promotionImage: string;
  readonly promotionCaption: string;
  readonly promotionHashtag: string;
  readonly rewardClaimDate: string;
  readonly rewardExpiry: string;
  readonly rewardQuantity: number;
  readonly isLoading: boolean;
  readonly businessPromotionId?: string;
  readonly endUserPromotionRewardId?: string;
}

export const RedeemableRewardCard: React.FC<RedeemableRewardCardProps> = ({
  promotionName,
  promotionImage,
  promotionCaption,
  promotionHashtag,
  rewardClaimDate,
  rewardExpiry,
  rewardQuantity,
  isLoading,
  businessPromotionId,
  endUserPromotionRewardId,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const navigateToRewardDetails = () => {
    if (businessPromotionId && endUserPromotionRewardId) {
      navigate(
        `/end-user/redeem-points?rewardId=${endUserPromotionRewardId}&promotionId=${businessPromotionId}`,
      );
    } else {
      toastStore.setErrorToast('Error viewing QR code.', undefined, true);
      throw new Error(
        'Invalid businessPromotionId or endUserPromotionRewardId.',
      );
    }
  };

  return (
    <CardWrapper>
      <PromotionHeading>
        {withSkeletonLoading(promotionName, isLoading, { height: '2.5rem' })}
      </PromotionHeading>
      <PromotionDataWrapper>
        <PromotionImageWrapper>
          {withSkeletonLoading(
            <ImageWrapper>
              <PromotionImage src={promotionImage} alt="promotion" />
            </ImageWrapper>,
            isLoading,
            { height: '6rem' },
          )}
        </PromotionImageWrapper>
        <PromotionDescriptionWrapper>
          {withSkeletonLoading(
            <>
              <PromotionDescription>{promotionCaption}</PromotionDescription>
              <PromotionHashtag>
                <p>{t('Promotion hashtag:')}</p>
                <p>{promotionHashtag}</p>
              </PromotionHashtag>
            </>,
            isLoading,
            { height: '3rem' },
          )}
        </PromotionDescriptionWrapper>
      </PromotionDataWrapper>
      <RewardDataWrapper>
        <div>
          <PromotionDataValue>
            {withSkeletonLoading(
              format(new Date(rewardClaimDate), 'dd-MM-yyyy'),
              isLoading,
            )}
          </PromotionDataValue>
          <PromotionDataLabel>
            {withSkeletonLoading(t('Date reward claimed'), isLoading)}
          </PromotionDataLabel>
        </div>
        <div>
          <PromotionDataValue>
            {withSkeletonLoading(rewardQuantity, isLoading)}
          </PromotionDataValue>
          <PromotionDataLabel>
            {withSkeletonLoading(t('Remaining Promotional Items'), isLoading)}
          </PromotionDataLabel>
        </div>
        <div>
          <PromotionDataValue>
            {withSkeletonLoading(
              format(new Date(rewardExpiry), 'dd-MM-yyyy'),
              isLoading,
            )}
          </PromotionDataValue>
          <PromotionDataLabel>
            {withSkeletonLoading(t('Reward expiration date'), isLoading)}
          </PromotionDataLabel>
        </div>
        <div>
          <ViewQRCodeButton type="button" onClick={navigateToRewardDetails}>
            {t('View QR code')}
          </ViewQRCodeButton>
        </div>
      </RewardDataWrapper>
    </CardWrapper>
  );
};

const PromotionDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const PromotionImageWrapper = styled.div``;

const PromotionImage = styled.img`
  width: ${pxToRem(120)};
  height: ${pxToRem(120)};
`;

const RewardDataWrapper = styled.div`
  display: grid;
  row-gap: 1.5rem;

  @media only screen and (min-width: ${defaultTheme.breakpoints.xxs}) {
    grid-template-columns: ${pxToRem(130)} ${pxToRem(130)};
    grid-template-rows: 4rem 4rem;
    row-gap: 1rem;
    column-gap: 2rem;
  }

  @media only screen and (min-width: ${defaultTheme.breakpoints.sm}) {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 4rem;
  }
`;

const PromotionDataWrapper = styled.div`
  display: grid;
  gap: 1.5rem;
  grid-template-columns: ${pxToRem(120)} 1fr;
  margin-bottom: 1rem;
`;

const PromotionDescription = styled.p`
  font-size: ${pxToRem(12)};
  margin-bottom: 1rem;
`;

const PromotionHashtag = styled.div`
  font-size: ${pxToRem(12)};
`;

const ViewQRCodeButton = styled(ButtonWithLoading)`
  all: unset;
  cursor: pointer;
  text-align: center;
  line-height: 1;
  padding: 0.75rem 0.75rem;
  background-color: ${() => clRewardsTheme.buttons.bgColor.casperGreen};
  color: ${() => clRewardsTheme.buttons.textColor.black};
  font-size: 0.9rem;
  font-weight: 500;
`;
