export const clRewardsTheme = {
  backgrounds: {
    primary: '#F4F4F4',
    black: '#000',
    headerBlue: '#4589F6',
  },
  buttons: {
    textColor: {
      black: '#000',
      white: '#FFF',
      disabled: '#7A7A7A',
    },
    bgColor: {
      casperGreen: '#3EDC64',
      white: '#FFF',
      facebookBlue: '#1977F2',
      disabled: '#D6D6D6',
    },
  },
  links: {
    textColor: {
      black: '#000',
      hover: {
        mobileNavItemLink: '#9F23E1',
        userAgreementTerms: '#186CF0',
      },
    },
    bgColor: {
      casperGreen: '#3EDC64',
    },
  },
  textColor: {
    black: '#000',
    inputErrorMessage: '#FF0000',
    userAgreementTermsText: '#565C65',
    userSelectionAlert: '#4589F6',
  },
  inputs: {
    imageUploadBgColor: '#FFF',
    textColor: '#000',
    bgColor: '#FAFAFA',
    border: '#DCDCDC',
  },
  gradients: {
    progressBar: 'linear-gradient(270.41deg, #f622e6 -16.4%, #9f23e1 98.22%)',
    gradientText: 'linear-gradient(270.41deg, #f622e6 -16.4%, #9f23e1 98.22%)',
  },
};
