import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Loading } from '../loading.type';

export interface AuthState {
  status: Loading;
  facebookAuthInfo?: fb.StatusResponse;
}

const initialState: AuthState = {
  status: Loading.Pending,
  facebookAuthInfo: undefined,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setFacebookAuthResponse: (
      state,
      action: PayloadAction<fb.StatusResponse>,
    ) => {
      state.facebookAuthInfo = action.payload;
      state.status = Loading.Complete;
    },
  },
});

export const { setFacebookAuthResponse } = authSlice.actions;
