export const stepperStyleConfig: StepStyleDTO = {
  size: 20,
  circleFontSize: 0,
  activeBgColor: '#3EDC64',
  activeTextColor: '',
  completedBgColor: '#DCDCDC',
  completedTextColor: '',
  inactiveBgColor: '#DCDCDC',
  inactiveTextColor: '',
  labelFontSize: '',
  borderRadius: '50%',
  fontWeight: '',
};

export const stepperConnectorStyleConfig: ConnectorStyleProps = {
  disabledColor: '#DCDCDC',
  activeColor: '#DCDCDC',
  completedColor: '#DCDCDC',
  size: 2,
  stepSize: '',
  style: 'solid',
};

// these types come directly from react-form-stepper libary and cannot be imported
interface StepStyleDTO {
  activeBgColor: string;
  activeTextColor: string;
  completedBgColor: string;
  completedTextColor: string;
  inactiveBgColor: string;
  inactiveTextColor: string;
  size: number;
  circleFontSize: number;
  labelFontSize: string;
  borderRadius: string;
  fontWeight: string;
}

interface ConnectorStyleProps {
  disabledColor: string;
  activeColor: string;
  completedColor: string;
  size: number;
  stepSize?: number | string;
  style: string;
}
