import React from 'react';
import styled from '@emotion/styled';
import { pxToRem } from 'casper-ui-kit';
import { DefaultHeaderLogo } from 'src/components/layout';
import { PageWrapper } from 'src/components/layout/PageWrapper';
import { CreatePromotionButton } from 'src/components/buttons/CreatePromotionButton';
import { t } from 'i18next';
import { SubmitHandler, useForm } from 'react-hook-form';
import { setBusinessEmail, setBusinessName, useAppDispatch } from 'src/store';
import { clRewardsTheme } from 'src/styled-theme';
import {
  InputErrorMessageWrapper,
  StyledErrorMessage,
} from './SharedStyles/pages.styled';

interface SignUpFailurePageValues {
  businessName: string;
  businessEmail: string;
}

const logo = <DefaultHeaderLogo aria-label="home" />;

export const SignUpFailurePage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SignUpFailurePageValues>();
  const dispatch = useAppDispatch();

  const onSubmit: SubmitHandler<SignUpFailurePageValues> = data => {
    dispatch(setBusinessName(data.businessName));
    dispatch(setBusinessEmail(data.businessEmail));
  };

  return (
    <PageWrapper>
      <WelcomePageComponents>
        <LogoWrapper>{logo}</LogoWrapper>
        <SignUpFailureH1>
          {t('Looks like something went wrong.')}
        </SignUpFailureH1>
        <UserMessageWrapper>
          <SignUpFailureSubheading>
            {t(
              'Based on the credentials you provided, you are not eligible for the beta. The beta program is',
            )}
          </SignUpFailureSubheading>
          <WelcomeText>
            [Lorem ipsum dolor, sit amet consectetur adipisicing elit.
            Blanditiis, iste quidem. Perferendis ipsa, repudiandae delectus,
            vitae!]
          </WelcomeText>
        </UserMessageWrapper>
        <SignUpFailureForm onSubmit={handleSubmit(onSubmit)}>
          <InputErrorMessageWrapper>
            <BusinessInputLabel>
              {t('Business Name')}
              <BusinessInput
                placeholder="Enter business name"
                {...register('businessName', {
                  required: true,
                })}
              />
            </BusinessInputLabel>
            {errors.businessName && (
              <StyledErrorMessage>
                {t('Please enter your business name.')}
              </StyledErrorMessage>
            )}
          </InputErrorMessageWrapper>
          <InputErrorMessageWrapper>
            <BusinessInputLabel>
              {t('Business Email')}
              <BusinessInput
                placeholder="Enter business email address"
                {...register('businessEmail', {
                  required: true,
                  pattern: /\S+@\S+\.\S+/,
                })}
              />
            </BusinessInputLabel>
            {errors.businessEmail && (
              <StyledErrorMessage>
                {t('Please enter a valid email address')}
              </StyledErrorMessage>
            )}
          </InputErrorMessageWrapper>
          <SignUpFailurePageButtonWrapper>
            <CreatePromotionButton
              type="submit"
              bgColor={clRewardsTheme.buttons.bgColor.casperGreen}>
              {t('Enroll In Beta')}
            </CreatePromotionButton>
          </SignUpFailurePageButtonWrapper>
        </SignUpFailureForm>
      </WelcomePageComponents>
    </PageWrapper>
  );
};

const WelcomePageComponents = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: ${pxToRem(700)};
  margin: 0 auto;
`;

const LogoWrapper = styled.div`
  margin-top: ${pxToRem(40)};
`;

const SignUpFailureH1 = styled.h1`
  font-size: ${pxToRem(40)};
  font-weight: 400;
  text-align: center;
  line-height: 1;
  margin-top: ${pxToRem(105)};
`;

const UserMessageWrapper = styled.div`
  margin: ${pxToRem(40)} 0 ${pxToRem(73)} 0;
`;

const SignUpFailureSubheading = styled.h2`
  font-size: ${pxToRem(18)};
  font-weight: 400;
  text-align: center;
`;

const WelcomeText = styled.p`
  color: ${props => props.theme.textColor.userAgreementTermsText};
  font-size: ${pxToRem(18)};
  text-align: center;
`;

const SignUpFailureForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: ${pxToRem(43)};
  margin-bottom: ${pxToRem(85)};
`;

const BusinessInputLabel = styled.label`
  display: flex;
  flex-direction: column;
  font-weight: 500;
  font-size: ${pxToRem(20)};
`;

export const BusinessInput = styled.input`
  color: ${props => props.theme.inputs.textColor};
  font-size: ${pxToRem(18)};
  font-weight: initial;
  width: 100%;
  max-width: ${pxToRem(447)};
  height: ${pxToRem(50)};
  border-radius: ${pxToRem(6)};
  border: solid ${pxToRem(2)} ${props => props.theme.inputs.border};
  padding-left: 1.2rem;
  margin-top: 0.4rem;
`;

const SignUpFailurePageButtonWrapper = styled.div`
  margin-top: ${pxToRem(30)};
`;
