import React, { useState } from 'react';
import styled from '@emotion/styled';
import Modal from 'styled-react-modal';
import { t } from 'i18next';
import { pxToRem } from 'casper-ui-kit';
import { clRewardsTheme } from 'src/styled-theme';
import { CreatePromotionButton } from '../buttons/CreatePromotionButton';
import { CreatePromotionButtonWrapper } from './modals.styled';

export const UserAgreementModal = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <UserAgreementButton type="button" onClick={toggleModal}>
        {t('End User License Agreement')}
      </UserAgreementButton>
      <Modal
        isOpen={isOpen}
        onBackgroundClick={toggleModal}
        onEscapeKeydown={toggleModal}>
        <UserAgreementModalContainer>
          <StyledUserAgreementHeading>
            {t('End User License Agreement')}
          </StyledUserAgreementHeading>
          <UserAgreementText>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Explicabo
            perspiciatis recusandae quaerat maiores odit ex totam aperiam
            voluptatum, commodi nulla omnis nemo eos consequuntur earum
            inventore adipisci pariatur, dolores voluptate. Lorem ipsum dolor
            sit amet, consectetur adipisicing elit. Iusto, reprehenderit
            placeat! Qui esse laborum modi neque, similique facilis saepe culpa
            repellat.
          </UserAgreementText>
          <UserAgreementText>
            Lorem ipsum dolor sit amet consectetur adipisicing elit.
            Perspiciatis amet ducimus repellendus maiores sunt laudantium. Omnis
            ut debitis, delectus hic aliquid, magnam illo explicabo
            exercitationem suscipit, expedita vel. Officiis, tempora. Lorem
            ipsum dolor sit amet consectetur adipisicing elit. Excepturi eaque
            cumque.
          </UserAgreementText>
          <UserAgreementText>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum
            debitis aliquam quidem optio nam adipisci rerum enim. Voluptatem
            architecto commodi, sapiente id earum itaque aspernatur possimus,
            voluptates perspiciatis, fugit ullam? Lorem ipsum dolor sit amet
            adipisci consectetur adipisicing elit.
          </UserAgreementText>
          <UserAgreementText>
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Non quidem
            dolores eaque consectetur nemo, omnis aut quae facilis veritatis
            recusandae! Rem nostrum repellat, est ullam dolor blanditiis
            molestias, expedita ratione at ipsam minus, placeat saepe doloremque
            qui nulla dolorum. Sapiente! Lorem ipsum dolor sit amet consectetur
            adipisicing elit. Ullam hic distinctio adipisci, inventore saepe
            sint et, laborum veritatis architecto laboriosam, quidem rerum
            eligendi. Suscipit officiis veritatis rerum fuga debitis ipsa!
          </UserAgreementText>
          <CreatePromotionButtonWrapper>
            <CreatePromotionButton
              type="button"
              bgColor={clRewardsTheme.buttons.bgColor.casperGreen}
              onClick={() => setIsOpen(!isOpen)}>
              {t('Close')}
            </CreatePromotionButton>
          </CreatePromotionButtonWrapper>
        </UserAgreementModalContainer>
      </Modal>
    </div>
  );
};

const UserAgreementModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.backgrounds.primary};
  width: 100%;
  max-width: ${pxToRem(1200)};
  max-height: calc(100vh - 100px);
  padding: 4.5rem;
  overflow-y: auto;
`;

const StyledUserAgreementHeading = styled.h2`
  font-family: Casper Sans;
  font-size: ${pxToRem(40)};
  font-weight: 400;
  text-align: left;
  margin-bottom: ${pxToRem(47)};
`;

const UserAgreementText = styled.p`
  color: ${props => props.theme.textColor.userAgreementTermsText};
  font-size: ${pxToRem(18)};
  text-align: left;
  margin-top: 1rem;
  margin-bottom: ${pxToRem(10)};
`;

const UserAgreementButton = styled.button`
  background-color: transparent;
  border: none;
  text-decoration: underline;
  padding: 0;
  margin-left: ${pxToRem(4)};
  cursor: pointer;

  &:hover {
    color: ${props => props.theme.links.textColor.hover.userAgreementTerms};
  }
`;
