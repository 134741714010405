import { RootState } from '../store';

export const getEndUserPromotionEngagement = (state: RootState) => {
  return state.endUserPromotionEngagement.endUserPromotionEngagement;
};

export const getEndUserPromotionEngagementLoadingStatus = (
  state: RootState,
) => {
  return state.endUserPromotionEngagement.status;
};
